import React, { ComponentState, useEffect, useRef, useState } from "react";
import { Checkbox, Switch } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DatePickerKendoRct from "../../../../control-components/date-picker/date-picker";
import ClientHeader from "../client-header/client-header";
import ErrorHelper from "../../../../helper/error-helper";
import { NotificationManager } from "react-notifications";
import DropDownKendoRct from "../../../../control-components/drop-down/drop-down";
import Loader from "../../../../control-components/loader/loader";
import { useSelector } from "react-redux";
import { ClientService } from "../../../../services/clientService";
import TextAreaKendoRct from "../../../../control-components/text-area/text-area";
import MultiSelectDropDown from "../../../../control-components/multi-select-drop-down/multi-select-drop-down";
import ApiHelper from "../../../../helper/api-helper";
import { useNavigate, useLocation } from "react-router";
import ValidationHelper from "../../../../helper/validation-helper";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { renderErrors } from "src/helper/error-message-helper";
import InputKendoRctV1 from "src/control-components/input/inputV1";
import { AddAuthReq, Clients, DropDownObjectFullName, ReqClients, ReqServices, Services } from "src/dataModels/clientResponse";
import { DropDownObjectWithNumber, DropDownObjectWithString } from "src/dataModels/docListModel";
import { clientServices } from "src/services/clientServices";
import moment from "moment";
import { useDispatch } from "react-redux";
import { GET_CLIENT_DETAILS, SELECTED_CLIENT_ID } from "src/redux/actions/types";
import APP_ROUTES from "src/helper/app-routes";
import { AuthSets, AuthorizationSetInfo } from "src/dataModels/authSetModels";
import { getAuthSetList } from "src/redux/actions";
import AddEditAuthSet from "src/app-modules/clinic/auth_sets/add-edit-auth-sets";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import trashIcon from "../../../../assets/images/trash2.png";
import calenderIcon from "../../../../assets/images/calendar-03.png"

const MultiClientAuthForm = () => {
  const dispatch = useDispatch()
  const frequencyData = [
    { key: "Day", value: "Day" },
    { key: "Week", value: "Week" },
    { key: "Month", value: "Month" },
    { key: "Year", value: "Year" },
  ];
  const vHelper = ValidationHelper();
  const staffLoginInfo = useSelector((state: ComponentState) => state.getStaffReducer);

  const submittedByd = {
    name: `${staffLoginInfo?.lastName}, ${staffLoginInfo?.firstName}`,
    id: staffLoginInfo?.id,
  };
  const clientDetail = useSelector((state: any) => state.clientDetails);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const selectedClientId = useSelector((state: ComponentState) => state.selectedClientId);
  const [settingError, setSettingError] = useState(false);
  const [authStatus, setAuthStatus] = useState([]);
  const [services, setServices] = useState<DropDownObjectWithString[]>([]);
  const [staffData, setStaffData] = useState([]);
  const staffId = useSelector((state: ComponentState) => state.loggedIn?.staffId);
  const clinicId = useSelector((state: ComponentState) => state.loggedIn.clinicId);
  const [clientList, setClientList] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isOpenAddNewSet, setIsOpenAddNewSet] = useState(false)
  const [dateSubmitted, setSubmitDate] = useState<Date>()
  const [submittedByObj, setSubmittedBy] = useState<DropDownObjectWithNumber>({ name: `${staffLoginInfo?.lastName}, ${staffLoginInfo?.firstName}`, id: staffLoginInfo?.id })
  const [authStatusIdObj, setAuthStatusId] = useState<DropDownObjectWithNumber>()
  const [comment, setComment] = useState<string>('')
  const [authClients, setAuthClients] = useState<Clients[]>([{ clientId: clientDetail?.id, clientName: clientDetail?.clientNameWithSite, custAuthId: '', itemId: Date.now() }])
  const [authServices, setAuthServices] = useState<Services[]>([{ isEnforceValidation: false, numUnits: 0, freqNumUnits: 0, itemId: Date.now(), frequency: frequencyData[0].key }])
  const [assignServicesToClient, setAssignServicesToClient] = useState(false);


  const stateComponent: ComponentState = useSelector(states => {
    return states;

});
  const [isSetFetchedn, setIsSetFetched] = useState(false)
  const [authSetList, setAuthSetList] = useState<AuthSets[]>([])
  const [selectedAuthLItem, setSelectedAuthListItem] = useState<AuthSets>()
  const { listAuthSets } = stateComponent['ClinicsReducer'];

  useEffect(() => {
    dispatch({
      type: GET_CLIENT_DETAILS,
      payload: null,
    });
    getAuthorizationStatus();
    getStaffList();
    fetchClientList();
    fetchServiceList();
    if (state) {
      getAuthorizationtById();
    }
  }, []);




  function fetchClientList() {
    setLoading(true);
    ApiHelper.getRequest(API_ENDPOINTS.GET_CLIENT_DDL_BY_CLINIC_ID+true)
      .then((result) => {
        const list = result.resultData.map((r) => {
          return { id: r.clientId, name: r.clientName };
        });
        setClientList(list);
      })
      .catch((err) => {
        setErrors("Patient List");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function fetchServiceList() {
    setLoading(true);
    const modifier = await clientServices.getServicesWoModifiers();
    setServices(modifier?.resultData)
    setLoading(false);
  }


  const getAuthorizationtById = async () => {
    setLoading(true);
    await ClientService.getAuthById(state.selectedAuthorization)
      .then((result) => {
        setLoading(false);
        let authInfo = result.resultData;
        let services = result.resultData.serviceIds;
        let newData: any[] = [];
        if (services.length > 0) {
          for (var i = 0; i < services.length; i++) {
            const element = {
              id: services[i]?.id,
              fullName: services[i]?.name,
            };
            newData.push(element);
          }
        }
        const clientId = {
          id: authInfo?.clientId,
          name: authInfo?.clientName,
        };
        const authStatus = {
          id: authInfo?.authStatusId,
          name: authInfo?.authStatus,
        };
        const authSubmitted = {
          id: authInfo?.staffId,
          name: authInfo?.submittedByStaffName,
        };
        const frequencyVal = {
          key: authInfo?.frequency,
          value: authInfo?.frequency,
        };
      })
      .catch((error) => {
        setLoading(false);
        renderErrors(error.message);
      });
  };

  const getAuthorizationStatus = async () => {
    await ClientService.getAuthorizationStatus()
      .then((result) => {
        let list = result.resultData;

        setAuthStatus(list);
      })
      .catch((error) => {
        renderErrors(error);
      });
  };

  const getStaffList = async () => {
    await ClientService.getStaffDDLByClinicId()
      .then((result) => {
        let list = result.resultData;
        setStaffData(list);
      })
      .catch((error) => {
        setLoading(false);
        renderErrors(error);
      });
  };

  interface ServiceResponse {
    status: boolean;
    message: string;
    resultData: Array<{
      id: number;
      fullName: string;
    }>;
    statusCode: number;
    metaData: null;
  }

  const getServicesList = async (selectedService: string[], indexOfSelectedService: number) => {
    if (selectedService.length < 1) {
      setAuthServices(list => list.map((item, i) =>
        i === indexOfSelectedService
          ? {
            ...item,
            serviceDropOptions: [],
            selectedServices: [],
            serviceId: []
          }
          : item
      ));
    } else {
      await clientServices.getClientServicebyServiceCodes(selectedService)
        .then((result: any) => {
          const serviceResponse: ServiceResponse = result;
          const serviceIds = serviceResponse.resultData.map(option => option.id);
          setAuthServices(list => list.map((item, i) =>
            i === indexOfSelectedService
              ? {
                ...item,
                serviceDropOptions: serviceResponse.resultData,
                selectedServices: serviceResponse.resultData,
                serviceId: serviceIds
              }
              : item
          ));
        })
        .catch((error) => {
          setLoading(false);
          renderErrors(error.message);
        });
    }
  };

  const handleValidation = async () => {
    let formIsValid = true;

    if (!dateSubmitted) {
      formIsValid = false;
      errors["submittedDate"] = ErrorHelper.FIELD_BLANK;
    }

    if (!authStatusIdObj) {
      formIsValid = false;
      errors["authStatus"] = ErrorHelper.FIELD_BLANK;
    }

    if (!submittedByObj) {
      formIsValid = false;
      errors["submittedBy"] = ErrorHelper.FIELD_BLANK;
    }


    authClients.map((clientItem) => {
      if (!clientItem.clientId || !clientItem.effectiveDate || !clientItem.endDate) {
        formIsValid = false;
        errors["clientError"] = ErrorHelper.FIELD_BLANK;
      }
    })

    authServices.map((serviceItem) => {
      if (serviceItem.isEnforceValidation) {
        if (!serviceItem.freqNumUnits || serviceItem.freqNumUnits < 1) {
          formIsValid = false;
          errors["serviceErrorUnits"] = ErrorHelper.GREATER_THEN_ZERO;
        }
      }
      if (!serviceItem.selectedServices || !(serviceItem.numUnits)
        || !serviceItem.frequency || serviceItem.selectedServices.length < 1) {
        formIsValid = false;
        errors["serviceError"] = ErrorHelper.FIELD_BLANK;
      }
    })


    return formIsValid;
  };



  const saveAuthorization = async () => {
    let newClientArray: ReqClients[] = [];
    let newServiceArrayArray: ReqServices[] = [];
    authClients.map((item) => {
      newClientArray.push({
        clientId: item.clientId, custAuthId: item.custAuthId,
        effectiveDate: moment(item.effectiveDate).format("YYYY-MM-DD"), endDate: moment(item.endDate).format("YYYY-MM-DD")
      })
    })


    authServices.map((item) => {
      const serviceIds = item?.selectedServices?.map(option => option.id);
      newServiceArrayArray.push({
        freqNumUnits: item.freqNumUnits ?? 0,
        frequency: item.frequency,
        isEnforceValidation: item.isEnforceValidation,
        numUnits: item.numUnits,
        serviceId: serviceIds
      })
    })

    let requestObject: AddAuthReq = {
      dateSubmitted: moment(dateSubmitted).format("YYYY-MM-DD"),
      clients: newClientArray,
      services: newServiceArrayArray,
      authStatusId: authStatusIdObj?.id ?? 0,
      comments: comment,
      submittedBy: submittedByObj?.id ?? 0,
      assignServicesToClient: assignServicesToClient
    }

    setLoading(true);
    clientServices.saveMultipleAuth(requestObject).then((result) => {
      setLoading(false);
      if (result.statusCode === 200) {
        NotificationManager.success("Authorization added successfully");
      }

      // dispatch({
      //   type: SELECTED_CLIENT_ID,
      //   payload: newClientArray && newClientArray.length > 0 ? newClientArray[0].clientId : null,
      // });
      navigate(APP_ROUTES.MULTIPLE_CLIENT_SERVICE_AUTH);
    })
      .catch((error) => {
        setLoading(false);
        renderErrors(error);
      });
  };



  const handleSubmit = async () => {
    setSettingError(true);
    if (await handleValidation()) {
      saveAuthorization()
    }
  };


  const handleCancel = () => {
    navigate(APP_ROUTES.MULTIPLE_CLIENT_SERVICE_AUTH);
  };


  function renderToItem(li, itemProps) {
    const itemChildren = (
      <span>
        <input
          type="checkbox"
          name={itemProps.dataItem}
          checked={itemProps.selected}
          onChange={(e) => itemProps.onClick(itemProps.index, e)}
        />
        &nbsp;{li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }




  useEffect(() => {
    if (!isSetFetchedn) {
        dispatch(getAuthSetList())
    }
}, [isSetFetchedn])


useEffect(() => {
    if (listAuthSets) {
        setAuthSetList(listAuthSets)
    }
}, [listAuthSets])



useEffect(() => {
  if (selectedAuthLItem) {
    setAuthServices([])
    setComment(selectedAuthLItem?.comment)
    selectedAuthLItem.authorizationSetInfos?.map((dataItem: AuthorizationSetInfo) => {
      let dropDownOption: DropDownObjectFullName[] = []
      const dropOption = dataItem.authorizationSetServices.map((Item) => {
          dropDownOption.push({ id: Item.serviceId, fullName: Item.service })
      })

      setAuthServices(oldData => [{
          serviceDropOptions: dropDownOption,
          selectedServices: dropDownOption,
          isEnforceValidation: dataItem.isRestrictive,
          freqNumUnits: dataItem.freqNumUnits, frequency: dataItem.freq,
          numUnits: dataItem.numUnits,
          selectedCodes:dataItem.serviceCodesList

      },

      ...oldData])
  })
  }
}, [selectedAuthLItem])



  return (
    <>
      <div className="notenetic-container ">
        <div className="staff-profile-page blue_theme">
          {/* {selectedClientId ? <ClientHeader /> : ""} */}
          <div className="upload-sign-file pt_30 ">
            <div className="d-flex justify-content-between ">
              <h4 className="address-title text-grey ">
                <span className="f-24">Authorization</span>
              </h4>
            </div>
          </div>

          <div className="hrBorder">

            <div className="row mt-3 ">
              <div className="mb-2 col-lg-4 col-md-6 col-12 position-relative">
                <DatePickerKendoRct
                  onChange={(dateEvent) => {
                    if (dateEvent.value && dateEvent.value != null) {
                      setSubmitDate(dateEvent.value)
                    }
                  }
                  }
                  placeholder=""
                  value={dateSubmitted}
                  name={"submittedDate"}
                  title={"Date of Request Submission"}
                  label={"Date of Request Submission"}
                  required={true}
                  validityStyles={settingError}
                  error={(!dateSubmitted && errors && errors.submittedDate) && errors.submittedDate}
                />
 <img src={calenderIcon} className="blueThemeIcons" alt="calender"/>
              </div>
              <div className="mb-2 col-lg-4 col-md-6 col-12">
                <DropDownKendoRct
                  label="Status"
                  onChange={(item) => { setAuthStatusId(item.value) }}
                  value={authStatusIdObj}
                  textField="name"
                  name="authStatus"
                  data={authStatus}
                  required={true}
                  dataItemKey="id"
                  validityStyles={settingError}
                  error={(!authStatusIdObj && errors && errors.authStatus) && errors.authStatus}
                  placeholder="Auth Status"
                />
              </div>
              <div className="mb-2 col-lg-4 col-md-6 col-12">
                <DropDownKendoRct
                  label="Submitted By"
                  onChange={(item) => { setSubmittedBy(item.value) }}
                  value={submittedByObj}
                  textField="name"
                  name="submittedBy"
                  data={staffData}
                  required={true}
                  dataItemKey="id"
                  validityStyles={settingError}
                  error={(!submittedByObj && errors && errors.submittedBy) && errors.submittedBy}
                  placeholder="Submitted By"
                />
              </div>

            </div>
          </div>





          <div className="my-4 hrBorder ">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="address-title text-grey ">
                <span className="f-24">Add Clients</span>
              </h4>

            </div>
            {

              authClients.map((selectedClient, index) =>

                <div className="row mt-3 borderTop">


                    <div className="mb-2 col-lg-3 col-md-3 col-12">

                      <DropDownKendoRct
                        label="*Select Client"
                        onChange={(selectedClientDrop) => {
                          if (selectedClientDrop.value && selectedClientDrop.value != null) {
                            setAuthClients(list => list.map((item, i) =>
                              i === index
                                ? {
                                  ...item,
                                  clientId: selectedClientDrop.value.id,
                                  clientName: selectedClientDrop.value.name
                                }
                                : item
                            ));
                          } else {
                            setAuthClients(list => list.map((item, i) =>
                              i === index
                                ? {
                                  ...item,
                                  clientId: '',
                                  clientName: ''
                                }
                                : item
                            ));
                          }

                        }
                        }
                        textField="name"
                        name="clientId"
                        data={clientList}
                        required={true}
                        // value={selectedClient.clientId && { id: selectedClient.clientId, name: selectedClient.clientName }}
                        dataItemKey="id"
                        validityStyles={settingError}
                        placeholder="Client"
                        disabled={state?.selectedAuthorization ? true : false}
                        error={(!selectedClient.clientId && errors && errors.clientError) && errors.clientError}
                      />
                    </div>
                  <div className="mb-2 col-lg-3 col-md-3 col-12 position-relative">
                    <DatePickerKendoRct
                      name={"effectiveDate"}
                      placeholder=""
                      title={"Effective Date"}
                      onChange={(selectedClientDate) => {
                        setAuthClients(list => list.map((item, i) =>
                          i === index
                            ? {
                              ...item,
                              effectiveDate: selectedClientDate.value!
                            }
                            : item
                        ));
                      }
                      }
                      label={"Effective Date"}
                      required={true}
                      value={selectedClient.effectiveDate && selectedClient.effectiveDate}
                      validityStyles={settingError}
                      error={(!selectedClient.effectiveDate && errors && errors.clientError) && errors.clientError}
                    />
                     <img src={calenderIcon} className="blueThemeIcons" alt="calender"/>
                  </div>
                  <div className="mb-2 col-lg-3 col-md-3 col-12 position-relative">
                    <DatePickerKendoRct
                      placeholder=""
                      name={"endDate"}
                      onChange={(selectedClientDate) => {
                        setAuthClients(list => list.map((item, i) =>
                          i === index
                            ? {
                              ...item,
                              endDate: selectedClientDate.value!
                            }
                            : item
                        ));
                      }
                      }
                      value={selectedClient.endDate && selectedClient.endDate}
                      title={"End Date"}
                      label={"End Date"}
                      required={true}
                      validityStyles={settingError}
                      error={(!selectedClient.endDate && errors && errors.clientError) && errors.clientError}
                    /> <img src={calenderIcon} className="blueThemeIcons" alt="calender"/>
                  </div>
                  <div className="mb-2 col-lg-2 col-md-2 col-12">
                    <InputKendoRctV1
                      value={selectedClient.custAuthId}
                      onChange={(handleTextChange) => {
                        setAuthClients(list => list.map((item, i) =>
                          i === index
                            ? {
                              ...item,
                              custAuthId: handleTextChange.value!
                            }
                            : item
                        ));
                      }
                      }

                      name="custAuthId"
                      label="Auth ID"
                    />
                  </div>
                  <div className="col-lg-1 col-md-1 col-12 cross-height d-flex align-items-center">

                    {authClients.length > 1 && <i
                      onClick={() => {
                        setAuthClients(authClients.filter(item => item.itemId !== selectedClient.itemId));
                      }}
                      className="fa-solid fa-xmark cross-red"></i>}
                  </div>
                </div>)
            }


            <button
              onClick={() => {
                setAuthClients(oldArray => [...oldArray, { itemId: Date.now() }]);
              }}
              className="AddMore"><i className="fa-solid fa-plus"></i> Add Another Client</button>
          </div>









          <div className="mt-3 hrBorder">
            <div className="d-flex align-items-center justify-content-between">

              <h4 className="address-title text-grey ">
                <span className="f-24">Add Services </span>
               
              </h4>

            
            </div>
            {
            
              (authSetList && authSetList.length>0)?
              <DropDownList
                            defaultValue={selectedAuthLItem && selectedAuthLItem}
                            data={authSetList}
                            style={{ height: "40px" }}
                            textField="setName"
                            // dataItemKey="key"
                            onChange={(handleChange) => {
                              setSelectedAuthListItem(handleChange.value)
                            }}
                            value={selectedAuthLItem &&selectedAuthLItem}
                            name="frequency"
                            label="Select Authorization Template"
                            key={'id'}
                            className="frequency-drop"
                            // required={true}
                          />
                          : 
                         <div className="alert alert-info d-flex justify-content-between"> No Authorization Template Available <button
                          onClick={() => {
                            setIsOpenAddNewSet(true)
                          }}
                          className="AddMore ml-sm-2"><i className="fa-solid fa-plus"></i> Create New Authorization Template</button></div>
             }

            {
              authServices.map((selectedService, index) =>

                <div className="row mt-3 align-items-center borderTop" >
                  <div className="mb-2 col-lg-4 col-md-3 col-12">
                    <MultiSelectDropDown
                      data={services}
                      textField="name"
                      label="*Select Service Codes"
                      autoClose={false}
                      onChange={(handleChange) => {
                        const serviceIds = handleChange.value.map(option => option.id);

                        if (serviceIds.length === selectedService.selectedCodes?.length) {

                          setAuthServices(list => list.map((item, i) =>
                            i === index
                              ? {
                                ...item,
                                service: handleChange.value.name,
                                selectedCodes: [],
                                serviceDropOptions: [],
                                selectedServices: []
                              }
                              : item
                          ));
                          return;
                        }
                        setAuthServices(list => list.map((item, i) =>
                          i === index
                            ? {
                              ...item,
                              service: handleChange.value.name,
                              selectedCodes: serviceIds,
                              serviceDropOptions: []
                            }
                            : item
                        ));
                        getServicesList(serviceIds, index);
                      }}
                      value={
                        selectedService.selectedCodes?.map((option) =>({id:option,name:option}))
                      }
                      name="service"
                      required={true}
                      dataItemKey={"id"}
                      itemRender={renderToItem}
                      tags={
                        selectedService.selectedCodes && selectedService.selectedCodes?.length > 0
                          ? [{
                            text: `${selectedService.selectedCodes.length} items selected`,
                            data: [...selectedService.selectedCodes]
                          }]
                          : []
                      }
                    />
                  </div>


                  <div className="mb-2 col-lg-4 col-md-3 col-12">
                    <MultiSelectDropDown
                      label="*Select Services"
                      placeholder="*Select Service Codes First*"
                      onChange={(handleChange) => {
                        const serviceIds = handleChange.value.map(option => option.id);
                        setAuthServices(list => list.map((item, i) =>
                          i === index
                            ? {
                              ...item,
                              serviceId: serviceIds,
                              selectedServices: handleChange.value
                            }
                            : item
                        ));
                      }}
                      data={selectedService.serviceDropOptions ?? []}
                      textField="fullName"
                      name="authServices"
                      value={selectedService?.selectedServices}
                      autoClose={false}
                      dataItemKey="id"
                      validityStyles={settingError}
                      required={true}
                      itemRender={renderToItem}
                      error={((!selectedService.selectedServices || selectedService.selectedServices.length < 1) && errors && errors.serviceError) && errors.serviceError}
                      tags={
                        selectedService.selectedServices && selectedService.selectedServices?.length > 0
                          ? [{
                            text: `${selectedService.selectedServices.length} items selected`,
                            data: [...selectedService.selectedServices]
                          }]
                          : []
                      }
                    />
                  </div>

                  <div className="mb-2 col-lg-3 col-md-3 col-12">
                    <span style={{ fontSize: "14px", color: (true) ? "#d51923" : "black" }}></span>
                    <NumericTextBox
                      onChange={(handleChange) => {
                        setAuthServices(list => list.map((item, i) =>
                          i === index
                            ? {
                              ...item,
                              numUnits: handleChange.value!
                            }
                            : item
                        ));
                      }}
                      name="numUnits"
                      label="Total Units"
                      validityStyles={settingError}
                      required={true}
                      value={selectedService.numUnits}
                      spinners={false}

                    />
                    {(!selectedService.numUnits && errors && errors.serviceError) && <Error>{errors.serviceError}</Error>}
                  </div>
                  <div className=" col-lg-1 col-md-1 col-12">
                    {authServices.length > 1 && <i
                      onClick={() => {
                        setAuthServices(authServices.filter(item => item !== selectedService));
                      }}
                      className="fa-solid fa-xmark cross-red"></i>}
                  </div>

                  <div className=" align-items-center row ml-2" >
                    <div className="">

                      <Switch
                        onChange={(handleChange) => {
                          setAuthServices(list => list.map((item, i) =>
                            i === index
                              ? {
                                ...item,
                                isEnforceValidation: handleChange.value!
                              }
                              : item
                          ));
                        }}
                        checked={selectedService.isEnforceValidation}
                        onLabel={""}
                        offLabel={""}
                        name="isEnforceValidation"
                      />
                      <span className="ml-2">Restrictive?</span>
                    </div>
                    <div className="frequency">
                      <div className="col-md-12 d-flex align-items-center dropIcon drop_icns">
                        <p className="f-12">If yes, Client is limited to</p>

                        <NumericTextBox
                          style={{ width: "70px", minHeight: "30px" }}
                          onChange={(handleChange) => {
                            setAuthServices(list => list.map((item, i) =>
                              i === index
                                ? {
                                  ...item,
                                  freqNumUnits: handleChange.value!
                                }
                                : item
                            ));
                          }}
                          name="freqNumUnits"
                          validityStyles={settingError}
                          required={true}
                          value={selectedService.freqNumUnits}
                          spinners={false}
                        />
                        {
                          (selectedService.isEnforceValidation &&
                            (!selectedService?.freqNumUnits || selectedService?.freqNumUnits < 1)) ?
                            (errors && errors.serviceErrorUnits) ? <Error>{errors.serviceErrorUnits}</Error> : null : null

                        }

                        <p className="ml-2 ">units per</p>
                        <div className="auth-drop-error mr-2 ">
                          <DropDownList
                            defaultValue={frequencyData[0].value}
                            data={frequencyData}
                            style={{ width: "70px", height: "30px" }}
                            textField="value"
                            dataItemKey="key"
                            onChange={(handleChange) => {
                              setAuthServices(list => list.map((item, i) =>
                                i === index
                                  ? {
                                    ...item,
                                    frequency: handleChange.value.key,
                                  }
                                  : item
                              ));
                            }}
                            value={selectedService.frequency && {
                              key: selectedService.frequency,
                              value: selectedService.frequency
                            }}
                            name="frequency"
                            className="frequency-drop"
                            required={true}
                          />
                          {(!selectedService.frequency && errors && errors.serviceError) && <Error>{errors.serviceError}</Error>}
                        </div>
                      </div>
                    </div>
                  </div>


                </div>)}

            <button
              onClick={() => {
                setAuthServices(oldArray => [...oldArray, { isEnforceValidation: false, numUnits: 0, freqNumUnits: 0, itemId: Date.now(), frequency: frequencyData[0].key  }]);
              }}
              className="AddMore "><i className="fa-solid fa-plus"></i> Add Another Service</button>
          </div>




          <div className="row"><div className="mb-2 col-lg-12 col-md-12 col-12">
            <TextAreaKendoRct
              txtValue={comment}
              onChange={(value) => {
                setComment(value.value)
              }}
              name="comments"
              label="Comments"
              rows={1}
            />
          </div></div>
          <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
          <Checkbox
          label = {"Add selected services to clients assigned services"}
          onChange = {(e: any) => {
            setAssignServicesToClient(e.target.value);
          }}
          name = "assignServicesToClient"
          />
          </div>
          </div>
        </div>

        {loading === true && <Loader />}

        <div className="row mt-4 ml-1 ">
          <div className="d-flex">
            <div>
              <button
                onClick={handleSubmit}
                className="submitButon "
              >
                Submit
              </button>
            </div>
            <div>
              <button
                className="cancelBtn "
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
              {
                        isOpenAddNewSet && <AddEditAuthSet
                            onClose={() => { setIsOpenAddNewSet(false)
                             }}
                            />
                    }
      </div>
    </>
  );
};

export default MultiClientAuthForm;
