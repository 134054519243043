import React, { Suspense } from 'react'
import ReactDOM from "react-dom/client";
import MainApp from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import "./lib/reactifyCss";
import "./custom-css/responsive.css";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import SmoothScroll from "./control-components/scroll-to-top/scroll-to-top";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
// import GridLoader from "./control-components/loader/loader"
import { NotificationContainer } from 'react-notifications';
import { store ,persistor} from './redux/store/store';


const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  // <React.StrictMode>
  <>
    {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
    <NotificationContainer/>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <CssBaseline />
          <BrowserRouter>
            <Suspense fallback={<></>}>
              <SmoothScroll>
                <MainApp />
              </SmoothScroll>
            </Suspense>
          </BrowserRouter>
        </PersistGate>
        <CssBaseline />
      </ThemeProvider>
    </Provider>
    {/* </ErrorBoundary> */}
  </>
  // </React.StrictMode>
);

reportWebVitals();
