import { takeLatest, put } from 'redux-saga/effects';
import {
  loaderAction,
  saveClientList,
  saveDeleteDocResponse,
  saveDocumentList,
  saveDraftCounts,
  saveStaffMostDocument,
  saveClientMostDocument,
  saveBillingMostDocument,
  saveServiceList,
  saveSiteList,
  saveStaffList,
  saveDocumentById,
  saveDocBillingHistory,
  saveBillingAction,
  saveDocLoadingState,
  saveDocumentServiceList,
  savePOS,
  successCall,
  saveLatestDocumentBytemplateId,
  saveRiskDDl,
  saveRiskfactorDDl,
  saveRiskProtectiveDDl,
  saveBillingByPayerMetrics,
  saveApproveDisapproveDocuments,
  saveDocMetricsByTemplate,
  saveDocMetricsByStatus,
  saveDocMetricsByServiceCode,
  saveSuperBill,
  saveDocumentTemplateTypes,
  saveDocAttachment,
  docUnitsChangedAction
} from '../actions';
import * as TYPES from '../actions/types';
import { AppSuccessResponse } from 'src/dataModels/apiSuccessResponse';
import { DocumentResponseObj, ChartResponse, DocumentByIdResponse, DocumentBillingHistoryResponse, BillingStatusResponse, DocumentServiceListResponse, UpdateDocumentServiceResponse, UpdateDocumentServiceDateResponse, UpdateDocumentDiagnosisResponse, UpdateDocumentSiteOfServiceResponse } from 'src/dataModels/docListModel';
import { ClientResponse, ServiceResponse, SiteResponse, StaffResponse } from 'src/dataModels/clientResponse';
import { docs } from 'src/services/documentService';
import { NotificationManager } from "react-notifications";
import NOTIFICATION_MESSAGE from 'src/helper/notification-messages';
import { LOCK_DOCUMENTS_OPERATION, UNLOCK_DOCUMENTS_OPERATION, APPROVE_DOCUMENTS_OPERATION } from 'src/app-modules/documents/list-document';



export function* darftCountSaga() {
  yield takeLatest(TYPES.GET_DRAFT_COUNT, draftWorker);
}

function* draftWorker(param: any) {
  try {
    const response: AppSuccessResponse = yield docs.draftCount(param['payload']);
    yield put(saveDraftCounts(response.resultData));
  } catch (err) {
  }
}


export function* trashDocSaga() {
  yield takeLatest(TYPES.DELETE_DOCUMENT, trashDocWorker);
}

function* trashDocWorker(param: any) {
  try {
    yield put(loaderAction(true));
    yield put(saveDeleteDocResponse());
    const response: AppSuccessResponse = yield docs.deleteDocument(param['payload']);
    yield put(saveDeleteDocResponse({
      ...response,
      type: 'trash',
    }));
    yield put(loaderAction(false));
  } catch (err) {
  }
}


export function* unTrashDocSaga() {
  yield takeLatest(TYPES.REVERT_DOCUMENT, untrashDocWorker);
}

function* untrashDocWorker(param: any) {
  try {
    yield put(loaderAction(true));
    yield put(saveDeleteDocResponse());
    const response: AppSuccessResponse = yield docs.revertDocument(param['payload']);
    yield put(saveDeleteDocResponse({
      ...response,
      type: 'untrash',
    }));
    yield put(loaderAction(false));
  } catch (err) {
  }
}

export function* docListSaga() {
  yield takeLatest(TYPES.GET_DOC_LIST, docListWorker);
}



function* docListWorker(param: any) {
  try {
    yield put(saveDocLoadingState(true));
    const response: DocumentResponseObj = yield docs.docList(param['payload']);
    yield put(saveDocumentList(response));
    yield put(saveDeleteDocResponse());
  } catch (err) {
  }
}

export function* staffDocumentChartSaga() {
  yield takeLatest(TYPES.GET_STAFF_MOST_DOCUMENTS, StaffDocumentChartWorker);
}

function* StaffDocumentChartWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: ChartResponse = yield docs.staffDocumentChartList(param['payload']);
    yield put(loaderAction(false));

    yield put(saveStaffMostDocument(response));
  } catch (err) {
  }
}

export function* clientDocumentChartSaga() {
  yield takeLatest(TYPES.GET_CLIENT_MOST_DOCUMENTS, ClientDocumentChartWorker);
}

function* ClientDocumentChartWorker(param: any) {
  try {
    const response: ChartResponse = yield docs.clientDocumentChartList(param['payload']);

    yield put(saveClientMostDocument(response));
  } catch (err) {
  }
}

export function* BillingDocumentChartSaga() {
  yield takeLatest(TYPES.GET_BILLING_STATUS_METRICS, BillingDocumentChartWorker);
}

function* BillingDocumentChartWorker(param: any) {
  try {
    const response: ChartResponse = yield docs.billingDocumentChartList(param['payload']);
    yield put(saveBillingMostDocument(response));
  } catch (err) {
  }
}


export function* getClientListSaga() {
  yield takeLatest(TYPES.GET_CLIENT_LIST, clientListWorker);
}

function* clientListWorker(param: any) {
  try {
    // yield put(loaderAction(true));
    const response: ClientResponse = yield docs.clientList(param['payload']);
    // yield put(loaderAction(false));
    const list = response?.resultData?.map((r) => {
      return { id: r.clientId, name: r.clientName, idInt: r.id };
    });
    yield put(saveClientList(list));
  } catch (err) {
    yield put(loaderAction(false));
  }
}


export function* getStaffListSaga() {
  yield takeLatest(TYPES.GET_STAFF_LIST, staffListWorker);
}

function* staffListWorker(param?: any) {
  try {
    // yield put(loaderAction(true));
    const response: StaffResponse = yield docs.staffList(param['payload']);
    // yield put(loaderAction(false));
    yield put(saveStaffList(response.resultData));
  } catch (err) {
  }
}
export function* getStaffListActiveInactiveSaga() {
  yield takeLatest(TYPES.GET_STAFF_LIST_ACTIVE_INACTIVE, staffListActiveInActiveWorker);
}

function* staffListActiveInActiveWorker(param?: any) {
  try {
    // yield put(loaderAction(true));
    const response: StaffResponse = yield docs.staffListActiveInactive(param['payload']);
    // yield put(loaderAction(false));
    yield put(saveStaffList(response.resultData));
  } catch (err) {
  }
}


export function* getSiteListSaga() {
  yield takeLatest(TYPES.GET_SITE_LIST, siteListWorker);
}

function* siteListWorker() {
  try {
    const response: SiteResponse = yield docs.siteList();
    const list = response?.resultData?.map((r) => {
      return { id: r.siteId, name: r.siteName };
    });
    yield put(saveSiteList(list));
  } catch (err) {
  }
}

export function* getServiceListSaga() {
  yield takeLatest(TYPES.GET_SERVICE_LIST, serviceListWorker);
}

function* serviceListWorker(param: any) {
  try {
    const response: ServiceResponse = yield docs.serviceList(param['payload']);
    const list = response?.resultData?.map((x) => {
      return { id: x.serviceId, name: x.fullName };
    });
    yield put(saveServiceList(list));
  } catch (err) {
  }
}


export function* getDocumenByIdSaga() {
  yield takeLatest(TYPES.GET_DOCUMENT_BY_ID, documentbyIdWorker);
}

function* documentbyIdWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: DocumentByIdResponse = yield docs.documentByID(param['payload']);
    yield put(loaderAction(false));
    yield put(saveDocumentById(response.resultData));
  } catch (err) {
  }
}



export function* docBillingHistorySaga() {
  yield takeLatest(TYPES.GET_DOC_BIILING_HISTORY, billingHistoryWorker);
}

function* billingHistoryWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: DocumentBillingHistoryResponse = yield docs.documentBillingHistory(param['payload']);
    yield put(loaderAction(false));
    yield put(saveDocBillingHistory(response.resultData));
  } catch (err) {
  }
}

export function* checkComplianceSaga() {
  yield takeLatest(TYPES.CHECK_COMPLIANCE, checkComplianceWorker);
}

function* checkComplianceWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield docs.checkCompliance(param['payload'])
    NotificationManager.success(NOTIFICATION_MESSAGE.CHECK_COMPLIANCE);
    yield put(loaderAction(false));
  } catch (err) {
    NotificationManager.success(err);
  }
}


export function* getDocumenBillingActionSaga() {
  yield takeLatest(TYPES.GET_BILLING_ACTION, documentBillingActionWorker);
}

function* documentBillingActionWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: BillingStatusResponse = yield docs.getBillingAction();
    yield put(loaderAction(false));
    yield put(saveBillingAction(response.resultData));
  } catch (err) {
  }
}

export function* getDocumentServiceListSaga() {
  yield takeLatest(TYPES.GET_DOCUMENT_SERVICES, getDocumentServiceListSagaWorkder);
}

function* getDocumentServiceListSagaWorkder(param: any) {
  try {
    yield put(loaderAction(true));
    const response: DocumentServiceListResponse = yield docs.getDocumentServiceList(param['payload']);
    yield put(loaderAction(false));
    yield put(saveDocumentServiceList(response.resultData));
  } catch (err) {
  }
}


export function* updateDocumentServiceSaga() {
  yield takeLatest(TYPES.UPDATE_DOCUMENT_SERVICE, updateDocumentServiceWorkder);
}

function* updateDocumentServiceWorkder(param: any) {
  try {
    yield put(loaderAction(true));
    const response: UpdateDocumentServiceResponse = yield docs.updateDocumentServiceList(param['payload']);
    if (response?.statusCode === 200) {
      NotificationManager.success(response?.message);
      yield put(successCall(response?.statusCode))
    }
    yield put(loaderAction(false));
  } catch (err: any) {
    NotificationManager.success(err?.message);

  }
}

export function* updateDocumentServiceDateSaga() {
  yield takeLatest(TYPES.UPDATE_DOCUMENT_SERVICE_DATE, updateDocumentServiceDateWorkder);
}

function* updateDocumentServiceDateWorkder(param: any) {
  try {
    yield put(loaderAction(true));
    const response: UpdateDocumentServiceDateResponse = yield docs.updateDocumentServiceDate(param['payload']);
    if (response?.statusCode === 200) {
      NotificationManager.success(response?.message);
      yield put(successCall(response?.statusCode))
    }
    yield put(loaderAction(false));
  } catch (err: any) {
    NotificationManager.success(err?.message);

  }
}

export function* updateDocumentDiagnosisSaga() {
  yield takeLatest(TYPES.UPDATE_DOCUMENT_DIAGNOSIS, updateDocumentDiagnosisWorkder);
}

function* updateDocumentDiagnosisWorkder(param: any) {
  try {
    yield put(loaderAction(true));
    const response: UpdateDocumentDiagnosisResponse = yield docs.updateDocumentDiagnosis(param['payload']);
    if (response?.statusCode === 200) {
      NotificationManager.success(response?.message);
      yield put(successCall(response?.statusCode))
    }
    yield put(loaderAction(false));
  } catch (err: any) {
    NotificationManager.success(err?.message);

  }
}

export function* updateDocumentSiteOfServiceSaga() {
  yield takeLatest(TYPES.UPDATE_DOCUMENT_SITE_OF_SERVICE, updateDocumentSiteOfServiceWorkder);
}

function* updateDocumentSiteOfServiceWorkder(param: any) {
  try {
    yield put(loaderAction(true));
    const response: UpdateDocumentSiteOfServiceResponse = yield docs.updateDocumentSiteOfService(param['payload']);
    if (response?.statusCode === 200) {
      NotificationManager.success(response?.message);
      yield put(successCall(response?.statusCode))
    }
    yield put(loaderAction(false));
  } catch (err: any) {
    NotificationManager.success(err?.message);

  }
}

export function* getPlaceOfServiceSaga() {
  yield takeLatest(TYPES.GET_PLACE_OF_SERVICE, getPlaceOfServiceWorkder);
}

function* getPlaceOfServiceWorkder(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield docs.getPlaceOfService(param['payload']);
    yield put(loaderAction(false));
    yield put(savePOS(response.resultData));
  } catch (err) {
  }
}

export function* updatePlaceOfServicePayloadSaga() {
  yield takeLatest(TYPES.UPDATE_PLACE_OF_SERVICE_PAYLOAD, updatePlaceOfServicePayloadWorker);
}

function* updatePlaceOfServicePayloadWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield docs.updatePOSPayload(param['payload']);
    if (response?.statusCode === 200) {
      NotificationManager.success(response?.message);
      yield put(successCall(response?.statusCode))
    }
    yield put(loaderAction(false));
  } catch (err) {
  }
}
export function* getLatestDocumentByTemplateIdSaga() {
  yield takeLatest(TYPES.GET_LATEST_DOCUMENT_BY_TEMPLATE_ID, getLatestDocumentByTemplateIdWorker);
}

function* getLatestDocumentByTemplateIdWorker(param: any) {
  try {
    // yield put(loaderAction(true));
    const response: any = yield docs.getLatestDocumentByTemplate(param['payload']);
    // yield put(loaderAction(false));
    yield put(saveLatestDocumentBytemplateId(response.resultData));
  }
  catch (err) {
  }
}

export function* getRiskDDLSaga() {
  yield takeLatest(TYPES.GET_RISK_DDL, getRiskDDLWorkder);
}

function* getRiskDDLWorkder(param: any) {
  try {
    // yield put(loaderAction(true));
    const response: any = yield docs.getRiskDDL(param['payload']);
    // yield put(loaderAction(false));
    yield put(saveRiskDDl(response.resultData));
  } catch (err) {
  }
}
export function* getRiskFactorDDLSaga() {
  yield takeLatest(TYPES.GET_RISK_FACTOR_DDL, getRiskFactorDDLWorkder);
}

function* getRiskFactorDDLWorkder(param: any) {
  try {
    // yield put(loaderAction(true));
    const response: any = yield docs.getRiskDDL(param['payload']);
    // yield put(loaderAction(false));
    yield put(saveRiskfactorDDl(response?.resultData));
  } catch (err) {
  }
}
export function* getRiskProtectiveDDLSaga() {
  yield takeLatest(TYPES.GET_RISK_PROTECTIVE_DDL, getRiskProtectiveDDLWorkder);
}

function* getRiskProtectiveDDLWorkder(param: any) {
  try {
    // yield put(loaderAction(true));
    const response: any = yield docs.getRiskDDL(param['payload']);
    // yield put(loaderAction(false));
    yield put(saveRiskProtectiveDDl(response.resultData));
  } catch (err) {
  }
}

export function* BillingByPayerSaga() {
  yield takeLatest(TYPES.GET_BILLING_BY_PAYERS_METRICS, billingByPayerWorker);
}

function* billingByPayerWorker(param: any) {
  try {
    const response: ChartResponse = yield docs.billingDocumentChartListByPayer(param['payload']);
    yield put(saveBillingByPayerMetrics(response));
  } catch (err) {
  }
}

export function* LockUnlockDocumentsSaga() {
  yield takeLatest(TYPES.LOCK_UNLOCK_DOCUMENTS, lockUnlockDocumentsWorker);
}

function* lockUnlockDocumentsWorker(param: any) {
  try {
    const payload = (param['payload'])

    const response = yield docs.lockUnlockDocuments(payload);
    if(response.statusCode === 200 && payload.isLock){
      yield put(saveApproveDisapproveDocuments(LOCK_DOCUMENTS_OPERATION))
    }
    else if (response.statusCode === 200 && (!payload.isLock)){
      yield put(saveApproveDisapproveDocuments(UNLOCK_DOCUMENTS_OPERATION))
    }
  }
  catch (err) {
  }
}

export function* ApproveOrDisapproveDocumentsSaga() {
  yield takeLatest(TYPES.APPROVE_DISAPPROVE_DOCUMENTS, ApproveOrDisapproveDocumentsWorker);
}

function* ApproveOrDisapproveDocumentsWorker(param: any) {
  try {
    const response = yield docs.approveOrDisapproveDocument(param['payload']);
    if (response.statusCode === 200) {
      yield put(saveApproveDisapproveDocuments(APPROVE_DOCUMENTS_OPERATION))
    }
  }
  catch (err) {
  }
}

export function* metricsByTemplateSaga() {
  yield takeLatest(TYPES.GET_METRICS_BY_TEMPLATE, metricsByTempWorker);
}

function* metricsByTempWorker(param: any) {
  try {
    const response: ChartResponse = yield docs.billingDocumentChartListByTemplate(param['payload']);
    yield put(saveDocMetricsByTemplate(response));
  } catch (err) {
  }
}

export function* metricsByStatusSaga() {
  yield takeLatest(TYPES.GET_METRICS_BY_DOC_STATUS, metricsByStatusWorker);
}

function* metricsByStatusWorker(param: any) {
  try {
    const response: ChartResponse = yield docs.billingDocumentChartListByStatus(param['payload']);
    yield put(saveDocMetricsByStatus(response));
  } catch (err) {
  }
}

export function* metricsByServiceCodeSaga() {
  yield takeLatest(TYPES.GET_DOC_METRICS_BY_SERVICE_CODE, metricsByServiceCodeWorker);
}

function* metricsByServiceCodeWorker(param: any) {
  try {
    const response: ChartResponse = yield docs.docMetricsByServiceCode(param['payload']);
    yield put(saveDocMetricsByServiceCode(response));
  } catch (err) {
  }
}

export function* getDocSuperBillSaga() {
  yield takeLatest(TYPES.GET_CLIENT_DOC_SUPER_BILL, superBillWorker);
}

function* superBillWorker(param: any) {
  try {
    const response: any = yield docs.superBill(param['payload']);
    yield put(saveSuperBill(response.resultData));
  } catch (err) {
  }
}

export function* getDocTemplateTypeSaga() {
  yield takeLatest(TYPES.GET_DOCUMENT_TEMPLATE_TYPE, tempTypeWorker);
}

function* tempTypeWorker(param: any) {
  try {
    const response: any = yield docs.getDocTempType();
    if(response && response.resultData && response.resultData.length>0){
      const sortedData=response.resultData.sort((a, b) => a.name.localeCompare(b.name))
      yield put(saveDocumentTemplateTypes(sortedData));
    }
   
  } catch (err) {
  }
}

export function* getDocAttachmentSaga() {
  yield takeLatest(TYPES.GET_DOCUMENT_ATTACHMENT, getDocAttachmentWorker);
}

function* getDocAttachmentWorker(param: any) {
  try {
    yield put(loaderAction(true))
    const response: any = yield docs.getDocAttachment(param['payload']);
    if(response && response.resultData){
      yield put(saveDocAttachment(response.resultData))
    }
    yield put(loaderAction(false))
  } catch (err) {
  }
}


export function* removeDocUnitsSaga() {
  yield takeLatest(TYPES.REMOVE_DOC_UNITS, removeDocUnitsWorker);
}

function* removeDocUnitsWorker(param: any) {
  try {
    yield put(loaderAction(true))
    const response: any = yield docs.removeDocUnits(param['payload']);
    if(response.statusCode === 200) {
      yield put(docUnitsChangedAction(true))
    }
    yield put(loaderAction(false))
  } catch (err) {
  }
}

export function* recalculateDocUnitsSaga() {
  yield takeLatest(TYPES.RECALCULATE_DOC_UNITS, recalculateDocUnitsWorker);
}

function* recalculateDocUnitsWorker(param: any) {
  try {
    yield put(loaderAction(true))
    const response: any = yield docs.recalculateDocUnits(param['payload']);
    if(response.statusCode === 200) {
      yield put(docUnitsChangedAction(true))
    }
    yield put(loaderAction(false))
  } catch (err) {
  }
}