import React, { useState, useEffect } from "react";
import { customAxios } from "./services/useExPressApi";
import { API_ENDPOINTS } from "./services/api-endpoints";
import DEVELOPMENT_CONFIG from   "./helper/config";


function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [appVersionStartChecking, setAppVersionChecking] = useState(false);
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      if(!appVersionStartChecking){
        setAppVersionChecking(true)
        CheckAppVersion()
      }
    
    }, [appVersionStartChecking]);


    async function CheckAppVersion(){
      const storageAppVersion= localStorage.getItem(DEVELOPMENT_CONFIG.APP_VERSION);

      var appVersionResp=await customAxios.post<any,any>(`${API_ENDPOINTS.GET_APP_VERSION}`);
      var currentAppVersion=appVersionResp?.resultData;
     

      if(currentAppVersion && storageAppVersion!==currentAppVersion){
        setIsLatestBuildDate(false);
        localStorage.setItem(DEVELOPMENT_CONFIG.APP_VERSION,currentAppVersion)
        refreshCacheAndReload();
      }else{

        setIsLatestBuildDate(true);
      }
    }

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload();
    };

    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;