import { useEffect, useState } from "react";

const useModelScroll = () => {
  const [scrollValue, setScrollValue] = useState<any>('');

  useEffect(() => {
    window.onpopstate = () => {
      setScrollValue(document.body.style.overflow = 'unset')
    }
  })

  function setScroll(value: any) {
    if (value === true || value === 'true') {
      setScrollValue(document.body.style.overflow = 'hidden')
      setScrollValue(document.body.style.height = '100vh')

    } else {
      setScrollValue(document.body.style.overflow = 'unset')
      setScrollValue(document.body.style.height = '100%')

    }
    setScrollValue(scrollValue);
  }
  return [setScrollValue, setScroll];
};

export default useModelScroll;


