import React, { ComponentState, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Loading from '../../control-components/loader/loader';
import apiHelper from "../../helper/api-helper";

import { showError } from "../../util/utility";
import { convertTimeToLocally, timeRecordingTypeEnum } from "./document-utility";
import { mapDocumentTemplate } from "./template/document-template-utility";
import { PreviewDocumentTemplate } from './template/preview-document-template';
import moment from 'moment';
import { DocumentViewHistoryModel } from 'src/dataModels/docListModel';
import { Dialog } from '@progress/kendo-react-dialogs';
import { API_ENDPOINTS } from 'src/services/api-endpoints';

const FIELD_NAME = {
    service: "serviceId",
    clientProgress: "clientProgressId",
    documentTimeRecording: "documentTimeRecording",
    placeOfService: "placeOfServiceId",
    serviceSite: "serviceSiteId",
    isFaceToFace: "isFaceToFace",
    isTelehealth: "isTelehealth",
    isIndirectVisit: "isIndirectVisit",
    treatmentPlans: "documentTreatmentPlans",
    documentTemplate: "documentFieldsMappings",
    serviceDate: "serviceDate",
    clientDiagnosis: "clientDiagnosis",
    isTelephone: "isTelephone",
    ebpCodeId:"ebpCodeId"
}
   
const EditedHistory = ({ onClose, stateData }) => {
    const [loading, setLoading] = useState(true)
    const [newService, setNewService] = useState<any>()
    const [oldService, setOldService] = useState<any>()
    const [newServiceDate, setNewServiceDate] = useState()
    const [oldServiceDate, setOldServiceDate] = useState()
    const [newClientProgress, setNewClientProgress] = useState<any>()
    const [oldClientProgress, setOldClientProgress] = useState<any>()
    const [newClientDiagnosis, setNewClientDiagnosis] = useState()
    const [oldClientDiagnosis, setOldClientDiagnosis] = useState()
    const [newTimeRecording, setNewTimeRecording] = useState<any>()
    const [oldTimeRecording, setOldTimeRecording] = useState<any>()
    const [newPlaceOfServiceList, setNewPlaceOfServiceList] = useState()
    const [oldPlaceOfServiceList, setOldPlaceOfServiceList] = useState()
    const [newSite, setNewSite] = useState<any>()
    const [oldSite, setOldSite] = useState<any>()
    const [newFaceToFace, setNewFaceToFace] = useState()
    const [oldFaceToFace, setOldFaceToFace] = useState()
    const [newTelehealth, setNewTelehealth] = useState()
    const [oldTelehealth, setOldTelehealth] = useState()
    const [newIndirect, setNewIndirect] = useState()
    const [oldIndirect, setOldIndirect] = useState()
    const [newTelephone, setNewTelephone] = useState()
    const [oldTelephone, setOldTelephone] = useState()
    const [newTreatmentPlans, setNewTreatmentPlans] = useState()
    const [oldTreatmentPlans, setOldTreatmentPlans] = useState()
    const [newDocumentFieldsMappings, setNewDocumentFieldsMappings] = useState()
    const [oldDocumentFieldsMappings, setOldDocumentFieldsMappings] = useState()
    const [changedDocumentFieldKeyNames, setChangedDocumentFieldKeyNames] = useState<string[]>()
    const [template, setTemplate] = useState<any>()
    const clinicId = useSelector((state: ComponentState) => state.loggedIn.clinicId)
    const navigate = useNavigate()
    const location = useLocation()
    const [documentId, setDocumentId] = useState(0)
    const [documentName, setDocumentName] = useState("")
    const [oldVersion, setOldVersion] = useState("")
    const [latestVersion, setLatestVersion] = useState("")
    const [documentTemplateId, setDocumentTemplateId] = useState(0)
    const [timeShiftList, setTimeShiftList] = useState<DocumentViewHistoryModel[]>([]);
    const [newEbpCode, setNewEbpCode] = useState()
    const [oldEbpCode, setOldEbpCode] = useState()
    /* ============================= useEffect functions ============================= */

    useEffect(() => {
        if (stateData) {
            setDocumentId(stateData?.documentId);
            setDocumentName(stateData?.documentName);
            setOldVersion(stateData?.oldVersion);
            setLatestVersion(stateData?.latestVersion);
            setDocumentTemplateId(stateData?.documentTemplateId);
        }
        initData()
    }, [oldVersion, latestVersion])

    useEffect(() => {
        fetchTimeShift()
    }, [])

    /* ============================= private functions ============================= */

    function onBack() {
        // navigate(APP_ROUTES.DOCUMENT_HISTORY, {
        //     state: {
        //         id: documentId,
        //         documentName: documentName
        //     },
        // });
        navigate(-1)
    }

    function fetchTimeShift() {
        setLoading(true);
        apiHelper
            .getRequest(API_ENDPOINTS.GET_SHIFTS)
            .then((result) => {
                setLoading(false);
                if (result.resultData) {
                    const list = result.resultData;
                    setTimeShiftList(list);
                }
            })
            .catch((err) => {
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    async function initData() {
        for (const key of Object.keys(oldVersion)) {
            // return Object.keys(oldVersion).forEach(async (key) => {

            switch (key) {
                case FIELD_NAME.isFaceToFace:
                    setNewFaceToFace(latestVersion[key])
                    setOldFaceToFace(oldVersion[key])
                    break;
                case FIELD_NAME.isTelehealth:
                    setNewTelehealth(latestVersion[key])
                    setOldTelehealth(oldVersion[key])
                    break;
                case FIELD_NAME.isIndirectVisit:
                    setNewIndirect(latestVersion[key])
                    setOldIndirect(oldVersion[key])
                    break;
                case FIELD_NAME.isTelephone:
                    setNewTelephone(latestVersion[key])
                    setOldTelephone(oldVersion[key])
                    break;

                case FIELD_NAME.ebpCodeId:
                    setNewEbpCode(latestVersion[key])
                    setOldEbpCode(oldVersion[key])
                    break;
            }

            if (JSON.stringify(oldVersion[key]) !== JSON.stringify(latestVersion[key])) {

                const obj = {
                    name: key,
                    oldValue: JSON.stringify(oldVersion[key]),
                    newValue: JSON.stringify(latestVersion[key]),
                }

                switch (obj.name) {

                    case FIELD_NAME.service:
                        await setService(obj)
                        break;
                    case FIELD_NAME.serviceDate:
                        await setServiceDate(obj)
                        break;

                    case FIELD_NAME.clientProgress:
                        await setClientProgress(obj)
                        break;

                    case FIELD_NAME.clientDiagnosis:
                        await setClientDiagnosis(obj)
                        break;

                    case FIELD_NAME.documentTimeRecording:
                        await setTimeRecording(obj)
                        break;

                    case FIELD_NAME.placeOfService:
                        await setPlaceOfService(obj)
                        break;

                    case FIELD_NAME.isFaceToFace:
                        break;

                    case FIELD_NAME.isTelephone:
                        break;

                    case FIELD_NAME.isTelehealth:
                        break;

                    case FIELD_NAME.treatmentPlans:
                        await setTreatmentPlans(obj)
                        break;

                    case FIELD_NAME.documentTemplate:
                        await setDocumentTemplate(obj)
                        break;
                }
            }
            // });
        }
        setLoading(false)
    }

    async function setService(obj) {
        try {
            let result = await apiHelper.getRequest(API_ENDPOINTS.GET_Services_BY_ID + obj.newValue)
            setNewService(result.resultData)
            result = await apiHelper.getRequest(API_ENDPOINTS.GET_Services_BY_ID + obj.oldValue)
            setOldService(result.resultData)
        } catch (err) {
            showError(err, "Service")
        }
    }

    async function setClientProgress(obj) {
        try {
            let result = await apiHelper.getRequest(API_ENDPOINTS.GET_CLIENT_PROGRESS)
            setNewClientProgress(result.resultData.find(x => x.id == obj.newValue))
            result = await apiHelper.getRequest(API_ENDPOINTS.GET_CLIENT_PROGRESS)
            setOldClientProgress(result.resultData.find(x => x.id == obj.oldValue))
        } catch (err) {
            showError(err, "Client Progress")
        }
    }

    async function setClientDiagnosis(obj) {
        try {
            if (JSON.parse(obj.newValue)?.length > 0) {
                if(!JSON.parse(obj.newValue) || !JSON.parse(obj.newValue)[0]?.dxId){
                    return
                }
                apiHelper.getRequest(API_ENDPOINTS.GET_CLIENT_DIAGNOSIS_BY_ID + "?clientID=" + JSON.parse(obj.newValue)[0]?.clientId + "&diagnosisId=" + JSON.parse(obj.newValue)[0]?.dxId
                ).then((result) => {
                    setNewClientDiagnosis(result.resultData?.icd10);
                }).catch((error) => { });
            }
            if (JSON.parse(obj.oldValue)?.length > 0) {
                if(!JSON.parse(obj.oldValue) || !JSON.parse(obj.oldValue)[0]?.dxId){
                    return
                }
                apiHelper.getRequest(API_ENDPOINTS.GET_CLIENT_DIAGNOSIS_BY_ID + "?clientID=" + JSON.parse(obj.oldValue)[0].clientId + "&diagnosisId=" + JSON.parse(obj.oldValue)[0]?.dxId
                ).then((result) => {
                    setOldClientDiagnosis(result.resultData?.icd10);
                }).catch((error) => { });
            }
        } catch (err) {
            showError(err, "Client Dx")
        }
    }

    async function setTimeRecording(obj) {
        let newTime = JSON.parse(obj.newValue)
        let oldTime = JSON.parse(obj.oldValue)
        if (newTime?.recordingMethodId == timeRecordingTypeEnum.shift ||
            oldTime?.recordingMethodId == timeRecordingTypeEnum.shift) {
            try {
                let result = await apiHelper.getRequest(API_ENDPOINTS.GET_SHIFTS)
                if (newTime?.recordingMethodId == timeRecordingTypeEnum.shift) {
                    newTime.shift = result.resultData.find(x => x.id == obj.newValue)
                }
                if (oldTime?.recordingMethodId == timeRecordingTypeEnum.shift) {
                    oldTime.shift = result.resultData.find(x => x.id == obj.newValue)
                }
            } catch (err) {
                showError(err, "Time Recording")
            }
        }
        setNewTimeRecording(newTime)
        setOldTimeRecording(oldTime)
    }

    async function setPlaceOfService(obj) {
        const newPlaceOfServiceList = JSON.parse(obj.newValue)
        const oldPlaceOfServiceList = JSON.parse(obj.oldValue)
        try {
            let result = await apiHelper.queryGetRequestWithEncryption(API_ENDPOINTS.GET_PLACE_OF_SERVICES_DDL_BY_CLINIC_ID, clinicId)
            setNewPlaceOfServiceList(result.resultData.filter(x => newPlaceOfServiceList.includes(x.id)))
            result = await apiHelper.queryGetRequestWithEncryption(API_ENDPOINTS.GET_PLACE_OF_SERVICES_DDL_BY_CLINIC_ID, clinicId)
            setOldPlaceOfServiceList(result.resultData.filter(x => oldPlaceOfServiceList.includes(x.id)))
        } catch (err) {
            showError(err, "Place of Service")
        }
    }

    async function setServiceDate(obj) {
        const newDos = JSON.parse(obj.newValue)
        const oldDos = JSON.parse(obj.oldValue)
        try {
            setNewServiceDate(newDos);
            setOldServiceDate(oldDos);
        } catch (err) {
            showError(err, "Service date")
        }
    }

    async function setTreatmentPlans(obj) {
        let newPlanList = JSON.parse(obj.newValue)
        let oldPlanList = JSON.parse(obj.oldValue)

        let treatmentPlanIds: string[] = []
        let objectiveIds: string[] = []
        let interventionIds: string[] = []
        for (const plan of [...newPlanList, ...oldPlanList]) {
            if (plan.treatmentPlanId && !treatmentPlanIds.includes(plan.treatmentPlanId)) {
                treatmentPlanIds.push(plan.treatmentPlanId)
            }
            if (plan.objectiveId && !objectiveIds.includes(plan.objectiveId)) {
                objectiveIds.push(plan.objectiveId)
            }
            if (plan.interventionId && !interventionIds.includes(plan.interventionId)) {
                interventionIds.push(plan.interventionId)
            }
        }
        try {
            let treatmentPlanList: any = []
            for (const treatmentPlanId of treatmentPlanIds) {
                const result: any = await apiHelper.queryGetRequestWithEncryption(API_ENDPOINTS.GET_CLIENT_PLANS_BY_ID, treatmentPlanId)
                treatmentPlanList.push(result.resultData)
            }

            let objectiveList: any = []
            for (const objectiveId of objectiveIds) {
                const result: any = await apiHelper.queryGetRequestWithEncryption(API_ENDPOINTS.GET_OBJECTIVE_BY_ID, objectiveId)
                objectiveList.push({ id: objectiveId, ...result.resultData })
            }

            let interventionList: any = []
            for (const interventionId of interventionIds) {
                const result = await apiHelper.queryGetRequestWithEncryption(API_ENDPOINTS.GET_INTERVENTION_BY_ID, interventionId)
                interventionList.push({ id: interventionId, ...result.resultData })
            }

            const mapper = (x) => {

                let newObj = x

                const plan = treatmentPlanList.find(obj => obj.id == x.treatmentPlanId)
                if (plan) {
                    newObj = {
                        ...newObj,
                        ...plan
                    }
                }

                const objective = objectiveList.find(obj => obj.id == x.objectiveId)
                if (objective) {
                    newObj = {
                        ...newObj,
                        ...objective
                    }
                }

                const intervention = interventionList.find(obj => obj.id == x.interventionId)
                if (intervention) {
                    newObj = {
                        ...newObj,
                        ...intervention
                    }
                }
                return newObj
            }
            newPlanList = newPlanList.map(mapper)
            oldPlanList = oldPlanList.map(mapper)

            setNewTreatmentPlans(newPlanList)
            setOldTreatmentPlans(oldPlanList)
        } catch (err) {
            showError(err, "Treatement Plans")
        }
    }

    async function setDocumentTemplate(obj) {
        try {
            const result: any = await apiHelper.queryGetRequestWithEncryption(API_ENDPOINTS.GET_DOCUMENT_TEMPLATE_BY_ID, documentTemplateId)
            if (result.resultData) {
                setTemplate(mapDocumentTemplate(result.resultData));
                const newMappings: any = JSON.parse(obj.newValue)
                const oldMappings: any = JSON.parse(obj.oldValue)
                setNewDocumentFieldsMappings(newMappings)
                setOldDocumentFieldsMappings(oldMappings)

                let changedKeyNameList: string[] = []
                for (const newMapping of newMappings) {
                    for (const oldMapping of oldMappings) {
                        if (newMapping.keyName == oldMapping.keyName) {
                            if (newMapping.keyValue != oldMapping.keyValue) {
                                changedKeyNameList.push(newMapping.keyName)
                            }
                        }
                    }
                }
                setChangedDocumentFieldKeyNames(changedKeyNameList)
            }
        } catch (err) {
            showError(err, "Document Template")
        }
    }

    /* ============================= render functions ============================= */

    function renderNewValue(value: string) {
        return <label className="mb-2" style={{ color: "grey" }}>{!value ? "NA" : value}</label>
    }

    function renderOldValue(value: string) {
        return <label className="mb-2" style={{ color: "grey" }}>{!value ? "NA" : value}</label>
    }

    function renderValue(title, newValue, oldValue) {
        if (!oldValue && !newValue)
            return ""
        return <div className="form-group mb-2 pl-0 row border-bottom">
            <div className='col-4'><h6 className="mb-2">{title}</h6></div>
            <div className='col-4'> <strong>New:  </strong>{renderNewValue(newValue)}</div>
            <div className='col-4'><strong>Old:  </strong>  {renderOldValue(oldValue)}</div>
        </div>

    }

    function renderTimeRecordingFor(documentTimeRecording, renderValueFunc) {
        return <>
            {
                documentTimeRecording?.recordingMethodId == timeRecordingTypeEnum.actual
                    ? <label className="mb-2">
                        {renderValueFunc(convertTimeToLocally(documentTimeRecording))}
                    </label>
                    : documentTimeRecording?.shiftName
                        ? <>
                            <label className="mb-2"></label>
                            {renderValueFunc(documentTimeRecording?.shiftName)}
                        </>
                        : <label className="mb-2" style={{ color: "grey" }}>N/A</label>
            }
        </>
    }

    function renderTimeRecording() {
        if (!oldTimeRecording && !newTimeRecording) return ""

        const newStartTime = new Date(newTimeRecording?.startTime);
        const newEndTime = new Date(newTimeRecording?.endTime);

        const oldStartTime = new Date(oldTimeRecording?.startTime);
        const oldEndTime = new Date(oldTimeRecording?.endTime);

        const newStartHours = newStartTime?.getHours();
        const newStartMintues = newStartTime?.getMinutes();
        const newStartSeconds = newStartTime?.getSeconds();
        const newStartTimeValue = `${newStartHours}:${newStartMintues}:${newStartSeconds}`;

        const newEndHours = newEndTime?.getHours();
        const newEndMintues = newEndTime?.getMinutes();
        const newEndSeconds = newEndTime?.getSeconds();
        const newEndTimeValue = `${newEndHours}:${newEndMintues}:${newEndSeconds}`;


        const oldStartHours = oldStartTime?.getHours();
        const oldStartMintues = oldStartTime?.getMinutes();
        const oldStartSeconds = oldStartTime?.getSeconds();
        const oldStartTimeValue = `${oldStartHours}:${oldStartMintues}:${oldStartSeconds}`;

        const oldEndHours = oldEndTime?.getHours();
        const oldEndMintues = oldEndTime?.getMinutes();
        const oldEndSeconds = oldEndTime?.getSeconds();
        const oldEndTimeValue = `${oldEndHours}:${oldEndMintues}:${oldEndSeconds}`;

        const areTimeRecordingsEqual =
            newStartTimeValue === oldStartTimeValue &&
            newEndTimeValue === oldEndTimeValue;
        if (areTimeRecordingsEqual) {
            return "";
        }
        return <div className="form-group mb-2 pl-0 row border-bottom">
            <div className='col-4'>
                <h6 className="mb-2">Time/Duration/Shift</h6>
            </div>
            <div className='col-4'> <strong>New:  </strong>  {renderTimeRecordingFor(newTimeRecording, renderNewValue)}</div>
            <div className='col-4'><strong>Old:  </strong>  {renderTimeRecordingFor(oldTimeRecording, renderOldValue)}</div>
        </div>
    }

    function renderShiftRecording() {
        if (!oldTimeRecording?.shiftId && !newTimeRecording?.shiftId) {
            return ""
        }
        if (oldTimeRecording?.shiftId === !newTimeRecording?.shiftId) {
            return ""
        }
        return <div className="form-group mb-2 pl-0 row border-bottom">
            <div className='col-4'>
                <h6 className="mb-2">Time/Duration/Shift</h6>
            </div>
            <div className='col-4'> <strong>New:  </strong>  {timeShiftList.find((shiftId) => shiftId.id === newTimeRecording?.shiftId)?.shiftName}</div>
            <div className='col-4'> <strong>Old:  </strong>  {timeShiftList.find((shiftId) => shiftId.id === oldTimeRecording?.shiftId)?.shiftName}</div>
        </div>
    }

    function renderPlaceOfServiceList(list, renderValueFunc) {
        return !list || list?.length == 0
            ? (<p>---</p>)
            : (
                list.map((obj, index) => {
                    if (index < list?.length - 1)
                        return <>{renderValueFunc(obj.name, index)}<br /></>
                    else
                        return renderValueFunc(obj.name, index)
                })
            )
    }

    function renderPlaceOfService() {
        if (!newPlaceOfServiceList && !oldPlaceOfServiceList) return ""

        return <div className="form-group mb-2 pl-0 row border-bottom">
            <div className='col-4'>
                <h6 className="mb-2">Place of Service</h6>
            </div>
            <div className='col-4'> <strong>New:  </strong>  {renderPlaceOfServiceList(newPlaceOfServiceList, renderNewValue)}</div>
            <div className='col-4'><strong>Old:  </strong>   {renderPlaceOfServiceList(oldPlaceOfServiceList, renderOldValue)}</div>

        </div>

    }

    function renderVisitType() {
        if (newFaceToFace === oldFaceToFace && newTelehealth === oldTelehealth && newIndirect == oldIndirect && newTelephone == oldTelephone)
            return

        return <div className="form-group mb-2 pl-0 row border-bottom">
            <div className='col-4'><h6 className="mb-2">Visit Type</h6></div>
            <div className='col-4'> <strong>New:  </strong>   {newFaceToFace && renderNewValue("Face to Face")} {newTelehealth && renderNewValue("Telehealth/Teleconference")} {newIndirect && renderNewValue("Indirect")} {newTelephone && renderNewValue("Phone")}</div>
            <div className='col-4'><strong>Old:  </strong>   {oldFaceToFace && renderOldValue("Face to Face")} {oldTelehealth && renderOldValue("Telehealth")} {oldIndirect && renderNewValue("Indirect")} {oldTelephone && renderOldValue("Phone")}</div>

        </div>
    }

    function renderServiceDate() {
        if (newServiceDate === oldServiceDate)
            return

        return <div className="form-group mb-2 pl-0 row border-bottom">
            <div className='col-4'>
                <h6 className="mb-2">Service Date</h6>
            </div>
            <div className='col-4'> <strong>New:  </strong>{newServiceDate && renderNewValue(moment(newServiceDate).format("MM/DD/yyyy"))}</div>
            <div className='col-4'><strong>Old:  </strong> {oldServiceDate && renderOldValue(moment(oldServiceDate).format("MM/DD/yyyy"))}</div>

        </div>
    }

    function renderNewPlanValue(value) {
        return <p className="f-14 mb-2">{!value ? "NA" : value}</p>
    }

    function renderOldPlanValue(value) {
        return <p className="f-14 mb-2" style={{ color: "grey" }}>{!value ? "NA" : value}</p>
    }

    function renderTreatmentPlan(plan, index, renderValueFunc) {
        return < div key={index} className="treament-text mb-4">
            <div className="tex-show">
                {plan.treatmentPlanName && renderValueFunc(plan.treatmentPlanName)}
                {plan.objective && renderValueFunc(plan.objective)}
                {plan.intervention && renderValueFunc(plan.intervention)}
            </div>
        </div>
    }

    function renderTreatmentPlanListSelective(treatmentPlans, renderValueFunc) {
        return <div className="row">
            {treatmentPlans && (
                <div className="col-lg-12 col-12 mb-3">
                    <p className="mb-4 f-14"></p>
                    <div>
                        {treatmentPlans?.length == 0 ? (
                            <p>{renderValueFunc("No Treatment Plans")}</p>
                        ) : (
                            treatmentPlans.map((plan, index) => {
                                return renderTreatmentPlan(plan, index, renderValueFunc)
                            })
                        )}
                    </div>
                </div>
            )}
        </div>
    }

    function renderTreatmentPlanList() {
        if (!newTreatmentPlans && !oldTreatmentPlans) return ""
        return (
            <div className="">
                <h6 >Treatment Plan</h6>
                {renderTreatmentPlanListSelective(newTreatmentPlans, renderNewPlanValue)}
                {renderTreatmentPlanListSelective(oldTreatmentPlans, renderOldPlanValue)}
            </div>
        );
    }

    function renderDocumentTemplate() {
        if (!newDocumentFieldsMappings && !oldDocumentFieldsMappings) return ""
        return <PreviewDocumentTemplate
            controlList={template.controlList}
            documentFieldsMappings={newDocumentFieldsMappings}
            oldDocumentFieldsMappings={oldDocumentFieldsMappings}
            changedDocumentFieldKeyNames={changedDocumentFieldKeyNames}
            isViewMode={true}
            isPrintMode={false}
        />
    }

    return (
        <Dialog
            onClose={onClose}
            title={"Document Changes"}
            className="pagination-row-cus"
            width={"80%"}
            height={"75%"}
        >
            <div className="container-fluid ">
                {loading && <Loading />}
                <div className="row ">
                    <div className="col-md-12">
                        <h5>Document Changes:</h5>
                        <br></br><br></br>
                        <div className="col-md-12">
                            {renderServiceDate()}
                            {renderValue("Service", newService?.service, oldService?.service)}
                            {renderValue("Client Progress", newClientProgress?.name, oldClientProgress?.name)}
                            {renderValue("Diagnosis", newClientDiagnosis, oldClientDiagnosis)}
                            {renderTimeRecording()}
                            {renderShiftRecording()}
                            {renderPlaceOfService()}
                            {renderValue("Location/Site of Service", newSite?.siteName, oldSite?.siteName)}
                            {renderVisitType()}
                            {renderTreatmentPlanList()}
                            {renderDocumentTemplate()}
                            {renderValue("EBP Code", newEbpCode, oldEbpCode)}
                        </div>

                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default EditedHistory