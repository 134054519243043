import { takeLatest, put } from 'redux-saga/effects';
import { saveClinicLogoBytes, saveClinicLogoUrl } from '../actions';
import * as TYPES from '../actions/types';
import { getSettings } from 'src/services/settingsService';
import { UserAuthResponse } from 'src/dataModels/authDataModels';



export function* clinicLogoSaga() {
  yield takeLatest(TYPES.GET_CLINIC_LOGO, clinicLogoWorker);
}

function* clinicLogoWorker(param: any) {
  try {
    const response: UserAuthResponse = yield getSettings.getClinicLogo(param['payload']['clinicId'], param['payload']['isBase64']);
    if (param['payload']['isBase64']) {
      yield put(saveClinicLogoBytes(response.resultData.clinicLogo));
    } else {
      yield put(saveClinicLogoUrl(response.resultData.clinicLogo));
    }
  } catch (err) {
  }
}


