import * as React from 'react';
import { Pager, PageChangeEvent } from '@progress/kendo-react-data-tools';
const initialType: 'numeric' | 'input' = 'numeric'
interface PageInterface {
    skip: number,
    take: number,
    buttonCount: number,
    type: 'numeric' | 'input',
    info: boolean,
    pageSizes: boolean,
    previousNext: boolean,
    responsive: boolean
}
const initialPageState: PageInterface = {
    skip: 0,
    take: 25,
    buttonCount: 5,
    type: initialType,
    info: true,
    pageSizes: true,
    previousNext: true,
    responsive: true
};
const CustomPager = (prop) => {
    const [pageState, setPageState] = React.useState<PageInterface>(initialPageState);
    let { skip, take } = pageState;
    const handlePageChange = (event: PageChangeEvent) => {
        const { skip, take } = event;
        prop.setSkip(skip);
        setPageState({ ...pageState, skip: skip, take: take })
        let pageNo = ((skip / take) + 1);
        prop.fetchClockTimeList(prop.fields?.staffId?.id, pageNo, take, prop.fields.startDate, prop.fields.endDate)
    };
    return (
        <Pager
            skip={prop.skip}
            take={take}
            onPageChange={handlePageChange}
            total={prop.total}
            style={{ width: "100%" }}
        />
    );
}
export default CustomPager;
