import React from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { forwardRef, useRef, useImperativeHandle } from "react"

const DeleteDialogModal = (props: DeleteDialogProps) => {
  const {
    title,
    message,
    activeType,
    onClose,
    handleDelete,
    handleReactive,
    ...rest
  } = props;


  return (
    <Dialog
      className='xs-modal blue_theme'
      onClose={onClose} title={activeType === true ? `Reactivate ${title}` : `Delete ${title}`}>
      <p
        style={{
          margin: "25px",
        }}
      >
        {activeType === true ? `Are you sure you want to reactivate ${message}?` : ` Are you sure you want to delete ${message}?`}
      </p>
      <DialogActionsBar>
        {activeType ? (
          <button
            className="submitButon px-4 "
            onClick={handleReactive}
          >
            Yes
          </button>
        ) : (
          <button
            className="submitButon px-4 "
            onClick={handleDelete}
          >
            Yes
          </button>
        )}
        <button
          className=" cancelBtn px-4 mr-2"
          onClick={onClose}
        >
          No
        </button>

      </DialogActionsBar>
    </Dialog>
  )
}

export default DeleteDialogModal

export interface DeleteDialogProps {
  title: string
  message: String
  activeType?: boolean
  onClose?: () => void | number;
  handleDelete?: () => void;
  handleReactive?: () => void;
}