import * as React from "react";
import * as ReactDOM from "react-dom";
import { Hint } from "@progress/kendo-react-labels";
import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { Error } from '@progress/kendo-react-labels';

export interface TextAreaKendoRctProps {
  name?:string,
  txtValue?:string,
  onChange?: (newValue: TextAreaChangeEvent) => void
  label?:string,
  maxLength?:number,
  rows?:number,
  column?:number,
  required?:boolean,
  error?:any,
  disabled?:boolean,
  resizable?:boolean
}


function TextAreaKendoRct(props:TextAreaKendoRctProps) {
  const {
    name,
    txtValue,
    onChange,
    label,
    maxLength,
    rows,
    column,
    required,
    error,
    disabled,
    resizable
  }=props;
  return (
    <div

    >
      <label className="k-label">{label}</label>
      <TextArea
        name={name}
        maxLength={maxLength}
        value={txtValue}
        onChange={onChange}
        rows={rows}
        cols={column}
        required={required}
        disabled={disabled}
        autoSize={resizable}
      />
      {
        error &&
        <Error >
          {error}
        </Error>
      }
    </div>
  );
}

export default TextAreaKendoRct;
