import React, { ComponentState, useEffect, useState } from "react";
import { NichqComponent2 } from "./Nichq-set2";
import { useLocation, useNavigate } from "react-router";
import { ClientService } from "src/services/clientService";
import { useSelector } from "react-redux";
import APP_ROUTES from "src/helper/app-routes";
import { renderErrors } from "src/helper/error-message-helper";
import NotificationManager from "react-notifications/lib/NotificationManager";

import { Gad7Ad } from "./Gad7Ad";
import { NichqHeader } from "./Nichq-heads";





export const Gad7 = ({ Gad7Ads, Gad7Ad_HEADERS, response, interfered, isPrint, questionId }) => {

  const [gad7QuestionSet, setGad7QuestionSet] = useState<any[]>([]);
  const { state } = useLocation();
  const selectedClientId = useSelector((state: ComponentState) => state.selectedClientId);
  const navigate = useNavigate();


  const [gad7HeaderInfo, setGad7HeaderInfo] = useState<any[]>([]);


  useEffect(() => {
    const listGad7 = Gad7Ads.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    const h2 = Gad7Ad_HEADERS.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    setGad7QuestionSet(listGad7)
    setGad7HeaderInfo(h2)
  }, [response])

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId, 0,
      [
        ...gad7QuestionSet,
        ...gad7HeaderInfo
      ],
      questionId
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        // setLoading(false);
        renderErrors(error.message);
      });
  };
  return (
    <div className="ml-3">
      <div>
        <br/>
        <h4>
          Severity Measure for Generalized Anxiety Disorder—Child Age 11-17 
        </h4>
      </div>
      {/* <p>
        The APA is offering a number of "emerging measures" for further research and clinical evaluation. These patient assessment measures were developed to be administered at the initial patient interview and to monitor treatment progress. They should be used in research and evaluation as potentially useful tools to enhance clinical decision-making and not as the sole basis for making a clinical diagnosis. Instructions, scoring information, and interpretation guidelines are provided; further background information can be found in DSM-5. The APA requests that clinicians and researchers provide further data on the instruments' usefulness in characterizing patient status and improving patient care at

        <a href="http://www.dsm5.org/Pages/Feedback-Form.aspx">http://www.dsm5.org/Pages/Feedback-Form.aspx</a> .

      </p>
      <p>
        <b>Measure:</b> Severity Measure for Generalized Anxiety Disorder—Child Age 11-17 <br />
        <b>Rights granted:</b> This measure can be reproduced without permission by researchers and by clinicians for use with their patients.<br />
        <b>Rights holder:</b> American Psychiatric Association
        To request permission for any other use beyond what is stipulated above, contact:<br />
        <a href="http://www.appi.org/CustomerService/Pages/Permissions.aspx">http://www.appi.org/CustomerService/Pages/Permissions.aspx</a>

      </p> */}
      {
        gad7HeaderInfo.length > 0 &&
        <div className="row mt-4">
          < NichqHeader

          title={""}
          disabled={response && response.length > 0}
          subTitle={''}
          listItems={gad7HeaderInfo}
          setListItems={setGad7HeaderInfo}
        />
        </div>

      }

      { 
        gad7QuestionSet.length > 0 &&
        < Gad7Ad

          subTitle={''}
          listItems={gad7QuestionSet}
          setListItems={setGad7QuestionSet}
        />
      }
      

      {state == null && !isPrint ? (
        <div className="text-start mt-3 col-12">
          <button onClick={handleSubmit} className="submit_phq ">
            Submit
          </button>
        </div>
      ) : (
        ""
      )}

      <p className="f-12 mt-3">
        Craske M, Wittchen U, Bogels S, Stein M, Andrews G, Lebeu R. Copyright © 2013 American Psychiatric Association. All rights reserved.
        <br />
        This material can be reproduced without permission by researchers and by clinicians for use with their patients.
      </p>
      <p> 
        <div className="head-3">Instructions to Clinicians </div> 
        <br />
        The Severity Measure for Generalized Anxiety Disorder—Child Age 11-17 is a 10-item measure that assesses the severity of generalized anxiety disorder in children and adolescents. The measure was designed to be completed by the child upon receiving a diagnosis of generalized anxiety disorder (or clinically significant generalized anxiety disorder symptoms) and thereafter, prior to follow-up visits with the clinician. Each item asks the child to rate the severity of his or her generalized anxiety disorder <u>during the past 7 days</u> .
      </p>
      <p>
      <div className="head-3">Scoring and Interpretation </div>  
        <br />

        Each item on the measure is rated on a 5-point scale (0=Never; 1=Occasionally; 2=Half of the time; 3=Most of the time, and 4=All of the time). The total score can range from 0 to 40, with higher scores indicating greater severity of generalized anxiety disorder. The clinician is asked to review the score of each item on the measure during the clinical interview and indicate the raw score for each item in the section provided for “Clinician Use.” The raw scores on the 10 items should be summed to obtain a total raw score. In addition, the clinician is asked to calculate and use the <u>average total score</u>. The average total score reduces the overall score to a 5-point scale, which allows the clinician to think of the child's generalized anxiety disorder in terms of none (0), mild (1), moderate (2), severe (3), or extreme (4). The use of the average total score was found to be reliable, easy to use, and clinically useful to the clinicians in the DSM-5 Field Trials. The <u>average total score</u>  is calculated by dividing the raw total score by number of items in the measure (i.e., 10).


      </p>
      <p>
      <div className="head-3">Note :</div> 
          If 3 or more items are left unanswered, the total score on the measure should not be calculated. Therefore, the child should be encouraged to complete all of the items on the measure. If 1 or 2 items are left unanswered, you are asked to calculate a prorated score. The prorated score is calculated by summing the scores of items that were answered to get a partial raw score. Multiply the partial raw score by the total number of items on the Severity Measure for Generalized Anxiety Disorder (i.e., 10) and divide the value by the number of items that were actually answered (i.e., 8 or 9). The formula to prorate the partial raw score to Total Raw Score is:
        <br />
        <br />
        <center>
          &nbsp;&nbsp; (Raw sum x 10)&nbsp;&nbsp;
          <br />
          _________________________________________
          <br />
          Number of items that were actually answered</center>
        <br />
        If the result is a fraction, round to the nearest whole number.

      </p>
      <p>
      <div className="head-3">Frequency of Use </div>  
        <br />

        To track changes in the severity of the child's generalized anxiety disorder over time, the measure may be completed at regular intervals as clinically indicated, depending on the stability of the child's symptoms and treatment status. Consistently high scores on a particular domain may indicate significant and problematic areas for the child that might warrant further assessment, treatment, and follow-up. Your clinical judgment should guide your decision.

      </p>

      <p className="f-12 mt-3">
        Copyright © 2013 American Psychiatric Association. All Rights Reserved.
        <br />
        This material can be reproduced without permission by researchers and by clinicians for use with their patients.
      </p>



    </div>
  );

}



