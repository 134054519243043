import { useState } from "react";
import apiHelper from "src/helper/api-helper";
import DocumentStaffPdf from "./document-staff-pdf";
import Loading from "../../control-components/loader/loader";
import { showError } from "src/util/utility";
import { API_ENDPOINTS } from "src/services/api-endpoints";

const StaffServiceReport = ({ postBodyObject }) => {
    const [reportData, setReportData] = useState([]);
    const [isPrintPDF, setIsPrintPDF] = useState(false);
    const [loading, setLoading] = useState(false);
    const { startDate, endDate } = postBodyObject;
    function handleStaffServiceReport() {
        setLoading(true);
        apiHelper
            .postRequest(API_ENDPOINTS.GET_STAFF_SERVICE_REPORT, postBodyObject)
            .then((result) => {
                setReportData(result?.resultData);
                setIsPrintPDF(true);
            })
            .catch((error) => {
                showError("Could not complete the request!", "Staff Service Report");
                console.log(error);
            })
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 0);
            });
    }

    return (
        <>
            {loading && (
                <div>
                    <Loading />
                </div>
            )}
            <button
                className="cancelBtn  "
                onClick={handleStaffServiceReport}
            >
                <i className="fa-solid fa-file-pdf mr-1"></i>
                Staff Service Report
            </button>
            {isPrintPDF && (
                <DocumentStaffPdf
                    reportData={reportData}
                    setIsPrintPDF={setIsPrintPDF}
                    isPrintPDF={isPrintPDF}
                    startDate={startDate}
                    endDate={endDate}
                />
            )}
        </>
    );
};

export default StaffServiceReport;
