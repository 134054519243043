import React, { useEffect, useState } from "react";
import TextAreaKendoRct from "src/control-components/text-area/text-area";

export const NichqComments = ({
    listItems,
    setListItems,
    title,
   
    readOnly
}) => {

    const [opList, setOpList] = useState(listItems)
    useEffect(() => {
        if (opList && opList.length > 0) {
            setListItems(opList);
        }
    }, [opList])



    const handleRadioChange = (itemId, value) => {
        // Find the item in the items array

        setOpList(list => list.map((item, i) =>
            item.id === itemId
                ? {
                    ...item,
                    value: value
                }
                : item
        ));
    }



    return (
        <div className="ACE_main_table mt-4">
            <div>
                <div className="head-6">{title}</div>
            </div>

            {opList?.map((item) => (
                <div>
                    {readOnly ? <div>
                        <div key={item.id} className="content  valueColor fw-600 mb-0 font-12 labelFonts">
                                            {item.name}
                                        </div>
                                        <div key={item.id} className="client-name">
                                            {item.value}
                                        </div>
                    </div> :


                        <TextAreaKendoRct
                            label=""
                            disabled={readOnly}
                            txtValue={item.value}
                            onChange={(value) => {
                                handleRadioChange(item.id, value.value)
                            }}
                            rows={1}
                        />}

                </div>

            ))}


        </div>
    );
};


