import React from "react";
import StaffDocumentsChart from "./staff-document-chart";
import ClientDocumentsChart from "./client-most-document-chart";
import BillingDocumentsPieChart from "./billing-status-metrics-chart";
import DocumentByPayer from "./documents-by-payer";
import DocumentByStatus from "./documents-by-status";
import DocumentByTemplate from "./documents-by-template";
import DocumentsByServiceCode from "./documents-by-service-Code";
const DocumentListChart = () => {
  return (
    <>
      {/* <div className='col-md-12 my-3 p-0'> */}
      <div className="d-flex flex-wrap gap2">
        <div className="col-md-4">
          <StaffDocumentsChart />
        </div>

        <div className="col-md-4">
          <BillingDocumentsPieChart />
        </div>
        <div className="col-md-4">
          <ClientDocumentsChart />
        </div>

        <div className="col-md-4">
          <DocumentByPayer />
        </div>

        <div className="col-md-4">
          <DocumentByStatus />
        </div>

        <div className="col-md-4">
          <DocumentByTemplate />
        </div>

        <div className="col-md-4">
          <DocumentsByServiceCode />
        </div>
      </div>
    </>
  );
};

export default DocumentListChart;
