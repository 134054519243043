import React, { Component, useEffect, useState } from "react";
import accessDenied from "../../assets/images/accessDenied.png";
import APP_ROUTES from "../../helper/app-routes";
import { useNavigate } from "react-router-dom";

function AccessDenied() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(APP_ROUTES.DASHBOARD)
    window.scrollTo(0, 0);
    window.location.reload(false);
  };
  return (
    <div className="dt-background ">
      <img src={accessDenied} className="accesImg" />
      <div className="my-3 text-center accessText">
        <p className="m-0">You don't have permissions to access this page.</p>
        <p>Contact an administrator to get permissions or go to the Dashboard page
          and browse other pages</p>
      </div>
      <button onClick={() => handleClick()}>Go to Dashboard</button>
    </div>
  );
 
}

export default AccessDenied;
