import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { customAxios } from 'src/services/useExPressApi';
import { AppSuccessResponse } from 'src/dataModels/apiSuccessResponse';
import { API_ENDPOINTS } from 'src/services/api-endpoints';
import { getStaffDocAttachments, getStaffDocDetailById, getStaffDocSignature, saveSkillSet, saveStaffDoc, saveStaffDocAttachments, saveStaffDocCreateLoading, saveStaffDocDetails, saveStaffDocListing, saveStaffDocLoading, saveStaffDocSignature, saveStaffDocStaff ,saveStaffDocTemplates, saveStaffGoals, saveTemplateSettings} from '../actions';
import { GenericResponse } from 'src/dataModels/authSetModels';
import { NotificationManager } from "react-notifications";



export function* getStaffTemplatesSaga() {
  yield takeLatest(TYPES.GET_STAFF_DOC_TEMPLATES, staffTemplateWorker);
}

function* staffTemplateWorker(param: any) {
  try {
    const staffTemplateResponse: GenericResponse = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_STAFF_PLAN_TEMPLATES}`, );
    if (staffTemplateResponse) {
      yield put(saveStaffDocTemplates(staffTemplateResponse.resultData));
    }
  } catch (err) {
  }
}


export function* getStaffDocListSaga() {
  yield takeLatest(TYPES.GET_STAFF_DOC_STAFF_LIST, staffListWorker);
}

function* staffListWorker(param: any) {
  try {
    const staffTemplateResponse: GenericResponse = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_STAFF_DDL_BY_CLINIC_ID}true`, );
    if (staffTemplateResponse) {
      yield put(saveStaffDocStaff(staffTemplateResponse.resultData));
    }
  } catch (err) {
  }
}


export function* getDocPrefsSettingSaga() {
  yield takeLatest(TYPES.GET_STAFF_DOC_STAFF_LIST, docPrefWorker);
}

function* docPrefWorker(param: any) {
  try {
    const staffTemplateResponse: GenericResponse = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_DOCUMENT_PREFS_BY_CLINIC_ID}true`, );
    if (staffTemplateResponse) {
      yield put(saveStaffDocStaff(staffTemplateResponse.resultData));
    }
  } catch (err) {
  }
}

// export function* getDocTemplateDetailSaga() {
//   yield takeLatest(TYPES.GET_STAFF_DOC_TEMPLATE_DETAIL, docTempDetailWorker);
// }

// function* docTempDetailWorker(param: any) {
//   try {
//     const staffTemplateResponse: GenericResponse = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_STAFF_PLAN_TEMPLATES_DETAIL}${param['payload']}`, );
//     if (staffTemplateResponse) {
//       yield put(saveStaffDocTemplates(staffTemplateResponse.resultData));
//     }
//   } catch (err) {
//   }
// }

export function* getDocTemplatePrefsSaga() {
  yield takeLatest(TYPES.GET_STAFF_DOC_TEMPLATE_SETTING, docTempPrefWorker);
}

function* docTempPrefWorker(param: any) {
  try {
    const staffTemplateResponse: GenericResponse = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_STAFF_PLAN_TEMPLATE_PREF_BY_TEMPLATE_ID}${param['payload']}`, );
    if (staffTemplateResponse) {
      yield put(saveTemplateSettings(staffTemplateResponse.resultData));
    }
  } catch (err) {
  }
}


export function* getStaffGoalsSaga() {
  yield takeLatest(TYPES.GET_STAFF_GOALS, staffGoalWorker);
}

function* staffGoalWorker(param: any) {
  try {
    const staffTemplateResponse: GenericResponse = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_STAFF_PLAN_GET_STAFF_GOALS}${param['payload']}`, );
    if (staffTemplateResponse) {
      yield put(saveStaffGoals(staffTemplateResponse.resultData));
    }
  } catch (err) {
  }
}


export function* getAllSkillsSaga() {
  yield takeLatest(TYPES.GET_SKILL_SET, skillWorker);
}

function* skillWorker(param: any) {
  try {
    const staffTemplateResponse: GenericResponse = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_CLINIC_SKILLS}`, );
    if (staffTemplateResponse) {
      yield put(saveSkillSet(staffTemplateResponse.resultData));
    }
  } catch (err) {
  }
}

export function* insertDocSaga() {
  yield takeLatest(TYPES.INSERT_STAFF_DOC, insertDocWorker);
}

function* insertDocWorker(param: any) {
  try {
    yield put(saveStaffDocCreateLoading(true))
    const staffTemplateResponse: GenericResponse = yield customAxios.post<any, AppSuccessResponse>(`${API_ENDPOINTS.INSERT_STAFF_DOCUMENT}`, param['payload']);
    if (staffTemplateResponse) {
      yield put(saveStaffDoc(staffTemplateResponse.resultData));
    }
    yield put(saveStaffDocCreateLoading(false))
  } catch (err) {
  }
}

export function* updateDocSaga() {
  yield takeLatest(TYPES.UPDATE_STAFF_DOC, updateWorker);
}

function* updateWorker(param: any) {
  try {
    yield put(saveStaffDocCreateLoading(true))
    const staffTemplateResponse: GenericResponse = yield customAxios.put<any, AppSuccessResponse>(`${API_ENDPOINTS.UPDATE_STAFF_DOCUMENT}`, param['payload']);
    if (staffTemplateResponse) {
      yield put(saveStaffDocAttachments())
      yield put(saveStaffDocSignature())
      yield put(saveStaffDocDetails())
      yield put(saveStaffDoc({ids:[param['payload']['id']]}));
      yield put(getStaffDocDetailById(param['payload']['id']))
    }   
    yield put(saveStaffDocCreateLoading(false))
  } catch (err) {
  }
}

export function* getStaffDocSaga() {
  yield takeLatest(TYPES.GET_STAFF_DOC_LISTING, getDocListWorker);
}

function* getDocListWorker(param: any) {
  try {
    yield put(saveStaffDocLoading(true));
    const staffTemplateResponse: GenericResponse = yield customAxios.post<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_DOCUMENT_LISTING}`, param['payload']);
    if (staffTemplateResponse) {
      yield put(saveStaffDocListing(staffTemplateResponse));
    }
    yield put(saveStaffDocLoading(false));
  } catch (err) {
  }
}


export function* getStaffDocByIdSaga() {
  yield takeLatest(TYPES.GET_STAFF_DOC_BY_ID, getDocByIdWorker);
}

function* getDocByIdWorker(param: any) {
  try {
    yield put(saveStaffDocLoading(true));
    const staffTemplateResponse: GenericResponse = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_STAFF_DOC_BY_ID}${param['payload']}`,);
    if (staffTemplateResponse) {
      yield put(saveStaffDocDetails(staffTemplateResponse.resultData));
    }
    yield put(saveStaffDocLoading(false));
  } catch (err) {
  }
}


export function* getStaffDocSignatureSaga() {
  yield takeLatest(TYPES.GET_STAFF_DOC_SIGNATURE, signatureWorker);
}

function* signatureWorker(param: any) {
  try {
    const staffTemplateResponse: GenericResponse = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_STAFF_PLAN_DOCUMENT_SIGNATURE}${param['payload']}`,);
    if (staffTemplateResponse) {
      yield put(saveStaffDocSignature(staffTemplateResponse.resultData));
    }
  } catch (err) {
  }
}

export function* getStaffDocAttachmentSaga() {
  yield takeLatest(TYPES.GET_STAFF_DOC_ATTACHMENTS, attachmentWorker);
}

function* attachmentWorker(param: any) {
  try {
    const staffTemplateResponse: GenericResponse = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_STAFF_DOC_ATTACHMENT}${param['payload']}`,);
    if (staffTemplateResponse) {
      yield put(saveStaffDocAttachments(staffTemplateResponse.resultData));
    }
  } catch (err) {
  }
}


export function* applyDocSignatureSaga() {
  yield takeLatest(TYPES.APPLY_STAFF_DOC_SIGNATURE, applySignWorker);
}

function* applySignWorker(param: any) {
  try {
    const staffTemplateResponse: GenericResponse = yield customAxios.post<any, AppSuccessResponse>(`${API_ENDPOINTS.INSERT_STAFF_TREATMENT_PLAN_SIGN}`,param['payload']);
    if (staffTemplateResponse) {
      NotificationManager.success("Signature applied successfully");
      yield put(getStaffDocSignature(param['payload']['documentId'][0]));
    }
  } catch (err) {
  }
}

export function* deleteAttachmentSaga() {
  yield takeLatest(TYPES.DELETE_STAFF_PLAN_DOC_ATTACHMENT, deleteAttachmentWorker);
}

function* deleteAttachmentWorker(param: any) {
  try {
    const staffTemplateResponse: GenericResponse = yield customAxios.delete<any, AppSuccessResponse>
    (`${API_ENDPOINTS.DELETE_STAFF_DOC_ATTACHMENT}${param['payload']['attachmentId']}`);
    if (staffTemplateResponse) {
      NotificationManager.success("Attachment is deleted successfully.");
      yield put(getStaffDocAttachments(param['payload']['docId']));
    }
  } catch (err) {
  }
}

