import { ServiceSetDDLResponse } from "src/dataModels/staffResponsesModels";
import { API_ENDPOINTS } from "./api-endpoints";
import { customAxios } from "./useExPressApi";
import { AssignSetToStaffModel, ClientComplianceData, ClinicDocumentS3SignedUrlModel, ClinicStoredDocumentByclinicIdModel, ClinicTpServiceOrStaffTypeResponse, CommonDeleteResponseModel, ComplianceData, ComplianceResponse, DefaultGoalsTemplateListResponse, DocumentTemplateResponse, SaveDefaultGoalsTemplatResponseModel, ServiceOrStaffPayload, UpdateDefaultGoalsTemplatResponseModel,insertUpdateCalendarSettingModal } from "src/dataModels/clinicResponseModels";
import { ComplianceReportResponse } from "src/dataModels/complianceModel";
import { AuthSetsPostModel, GenericResponse } from "src/dataModels/authSetModels";
import { AppSuccessResponse } from "src/dataModels/apiSuccessResponse";

export const clinicServices = {
    complianceList: () => {
        return customAxios.get<any, ComplianceData>(`${API_ENDPOINTS.GET_CLIENT_COMPLIANCE_FOR_CLINIC}`);
    },
    complianceReport: (query: string) => {
        return customAxios.post<any, ComplianceReportResponse[]>(`${API_ENDPOINTS.GET_COMPLIANCE_REPORT}`, query);
    },
    clinicServiceList: (id: number) => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_SERVICE_CODES_WOMODIFIERS}`);
    },
    documentTemplate: () => {
        return customAxios.get<any, DocumentTemplateResponse>(`${API_ENDPOINTS.GET_DOCUMENT_TEMPLATE_BY_CLINIC_ID}`);
    },
    clientCompliance: (id: any) => {
        return customAxios.get<any, ClientComplianceData>(`${API_ENDPOINTS.GET_CLIENT_COMPLIANCE_FOR_CLINIC_DDL}${id}`);
    },
    clientComplianceById: (clientComplianceId: number) => {
        return customAxios.get<any, ComplianceResponse>(`${API_ENDPOINTS.GET_CLIENT_COMPLIANCE_BY_ID}${clientComplianceId}`);
    },
    clinicDefaultGoalList: () => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_CLINIC_DEFAULT_GOALS}`);
    },
    deleteDefaultGoal: (id: number) => {
        return customAxios.delete<any>(`${API_ENDPOINTS.DELETE_CLINIC_DEFAULT_GOALS}${id}`);
    },

    assignDefaultGoalsToClients: (data: any) => {
        return customAxios.post<any>(`${API_ENDPOINTS.ASSIGN_DEFAULT_GOALS_TO_CLIENTS}`, data);
    },

    deleteServiceSet: (id: number) => {
        return customAxios.delete<any>(`${API_ENDPOINTS.DELETE_SERVICE_SET}${id}`);
    },

    insertServiceSet: (data: any) => {
        return customAxios.post<any>(`${API_ENDPOINTS.INSERT_SERVICE_SET}`, data);
    },
    updateServiceSet: (data: any) => {
        return customAxios.put<any>(`${API_ENDPOINTS.UPDATE_SERVICE_SET}`, data);
    },
    assignSetToStaff: (data: any) => {
        return customAxios.put<any>(`${API_ENDPOINTS.ASSIGN_SET_TO_STAFF}`, data);
    },
    assignSetToClient: (data: AssignSetToStaffModel) => {
        return customAxios.put<any>(`${API_ENDPOINTS.ASSIGN_SET_TO_CLIENT}`, data);
    },
    availableServiceList: (id: number) => {
        return customAxios.get<any>(`${API_ENDPOINTS.AVAILABLE_SERVICE_LIST}${id}&isActive=true`);
    },
    getServiceDDLByClinicId: () => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_SERVICE_DDL_BY_CLINIC_ID}`);
    },
    serviceSetDDL: () => {
        return customAxios.get<ServiceSetDDLResponse>(`${API_ENDPOINTS.GET_SERVICE_SET_DDL}`);
    },
    getAuthSetList: () => {
        return customAxios.get<GenericResponse>(`${API_ENDPOINTS.GET_AUTH_SET_LIST}`);
    },
    deleteAuthSet: (id: number) => {
        return customAxios.delete<GenericResponse>(`${API_ENDPOINTS.DELETE_AUTH_SET_LIST}?id=${id}`);
    },
    createNewAuthSet: (object: AuthSetsPostModel) => {
        return customAxios.post<GenericResponse>(`${API_ENDPOINTS.CREATE_AUTH_SET}`, object);
    },
    updateAuthSet: (object: AuthSetsPostModel) => {
        return customAxios.put<GenericResponse>(`${API_ENDPOINTS.UPDATE_AUTH_SET}`, object);
    },
    insertDefaultGoalTemplate: (data: any) => {
        return customAxios.post<SaveDefaultGoalsTemplatResponseModel>(`${API_ENDPOINTS.INSERT_DEFAULT_GOALS_TEMPLATE}`, data);
    },
    getClinicDefaultGoalsTemplates: () => {
        return customAxios.get<DefaultGoalsTemplateListResponse>(`${API_ENDPOINTS.GET_CLINIC_DEFAULT_GOALS_TEMPLATES}`);
    },
    deleteDefaultGoaltemplate: (id: number) => {
        return customAxios.delete<CommonDeleteResponseModel>(`${API_ENDPOINTS.DELETE_DEFAULT_GOALS_TEMPLATE}${id}`);
    },
    updateDefaultGoalTemplate: (data: any) => {
        return customAxios.put<UpdateDefaultGoalsTemplatResponseModel>(`${API_ENDPOINTS.UPDATE_DEFAULT_GOALS_TEMPLATES}`, data);
    },
    getClinicDocumentS3SignedUrl: (data: ClinicDocumentS3SignedUrlModel) => {
        return customAxios.post<any>(`${API_ENDPOINTS.GET_CLINIC_DOCUMENT_S3_SIGNED_URL}`, data);
    },
    getClinicStoreddocumentByClinicId: () => {
        return customAxios.get<ClinicStoredDocumentByclinicIdModel>(`${API_ENDPOINTS.GET_CLINIC_STORED_DOCUMENT_BY_CLINIC_ID}`);
    },
    deleteClinicDocument: (item: any) => {
        return customAxios.delete<any>(`${API_ENDPOINTS.DELETE_CLINIC_DOCUMENT}${item?.id}&isActive=${item?.createdby}`);
    },
    deleteClinicDocumentTag: (id: number) => {
        return customAxios.delete<any>(`${API_ENDPOINTS.DELETE_CLINIC_DOCUMENT_TAG}${id}`);
    },
    getClinicDocumentById: (id: number) => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_CLINIC_DOCUMENT_BYID}${id}`);
    },
    getClinicTags: (id: number) => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_CLINIC_CLIENT_TAGS}${id}&isActive=${true}`);
    },
    assignClinicTags: (data: any) => {
        let tagIds: number[] = [];
        data?.tags.tagName.map((objType: any) => tagIds?.push(objType.id))
        var item = {
            "docId": data.docId,
            "tagIds": tagIds,
        };
        return customAxios.put<any>(`${API_ENDPOINTS.ASSIGN_CLINIC_TAGS}`, item);
    },
    getPrescriptionRoleList: () => {
        return customAxios.get<GenericResponse, GenericResponse>(`${API_ENDPOINTS.GET_PRESCRIPTION_LIST}`);
    },
    insertClinician: (data: any) => {
        return customAxios.post<GenericResponse, GenericResponse>(`${API_ENDPOINTS.SAVE_CLINICIAN}`, data);
    },
    getClinicianList: () => {
        return customAxios.get<GenericResponse, GenericResponse>(`${API_ENDPOINTS.GET_CLINICIAN_LIST}`);
    },
    getClinicDosespotId: () => {
        return customAxios.get<GenericResponse, GenericResponse>(`${API_ENDPOINTS.GET_CLINIC_DOSESPOT_ID}`);
    },
    subscribeEPrescription: () => {
        return customAxios.post<GenericResponse, GenericResponse>(`${API_ENDPOINTS.ADD_ERX_CLINIC}`);
    },
    getDosespotSSOUrl: (clientId: number) => {
        return customAxios.get<GenericResponse, GenericResponse>(`${API_ENDPOINTS.GET_DOSESPOT_SSO_URL}${clientId}`);
    },
    getDosespotStaffList: () => {
        return customAxios.get<GenericResponse, GenericResponse>(`${API_ENDPOINTS.GET_DOSESPOT_STAFF_LIST}`);
    },
    getStaffClinicianId: () => {
        return customAxios.get<GenericResponse, GenericResponse>(`${API_ENDPOINTS.GET_STAFF_DOSESPOT_ID}`);
    },
    removeClinician: (clinicianId) => {
        return customAxios.delete<GenericResponse, GenericResponse>(`${API_ENDPOINTS.REMOVE_CLINICIAN}${clinicianId}`);
    },
    getClinicNotificationSetting: (data: any) => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_CLINIC_NOTIFICATION_SETTING}${data?.alertType}`);
    },
    saveClinicNotificationSettingPayload: (data: any) => {
        return customAxios.post<any>(`${API_ENDPOINTS.INSERT_CLINIC_NOTIFICATION_SETTING}`, data)
    },
    assignNotificationSettingPayload: (data: any) => {
        return customAxios.post<any>(`${API_ENDPOINTS.ASSIGN_NOTIFICATION_SETTING_TO_STAFF_OR_ROLE}`, data)
    },
    getRole: () => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_ROLE}`);
    },
    getAssignedRolesForNotificationSettings: (alertType: any) => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_ASSIGNED_ROLES_FOR_NOTIFICATION_SETTINGS}${alertType}`);
    },
    getClinicTpServiceTypes: () => {
        return customAxios.get<ClinicTpServiceOrStaffTypeResponse>(`${API_ENDPOINTS.GET_CLINIC_TP_SERVICE_TYPE}`);
    },
    getClinicTpStaffTypes: () => {
        return customAxios.get<ClinicTpServiceOrStaffTypeResponse>(`${API_ENDPOINTS.GET_CLINIC_TP_STAFF_TYPE}`);
    },
    insertOrUpdateClinicTpServiceTypesSaga: (data: ServiceOrStaffPayload) => {
        return customAxios.post<any>(`${API_ENDPOINTS.INSERT_OR_UPDATE_CLINIC_TP_SERVICE_TYPE}`, data)
    },
    deleteClinicTpServiceTypesSaga: (id: Number) => {
        return customAxios.delete<any>(`${API_ENDPOINTS.DELETE_CLINIC_TP_SERVICE_TYPES}?id=${id}`)
    },
    insertOrUpdateClinicTpStaffTypesSaga: (data: ServiceOrStaffPayload) => {
        return customAxios.post<any>(`${API_ENDPOINTS.INSERT_OR_UPDATE_CLINIC_TP_STAFF_TYPES}`, data)
    },
    deleteClinicTpStaffTypesSaga: (id: number) => {
        return customAxios.delete<any>(`${API_ENDPOINTS.DELETE_CLINIC_TP_STAFF_TYPES}?id=${id}`)
    },
    getClinicSkills: () => {
        return customAxios.get<GenericResponse, GenericResponse>(`${API_ENDPOINTS.GET_CLINIC_SKILLS}`);
    },
    deleteClinicSkill: (id: number) => {
        return customAxios.delete<any>(`${API_ENDPOINTS.DELETE_CLINIC_SKILL}${id}`);
    },
    addClinicSkill: (data: any) => {
        return customAxios.post<any>(`${API_ENDPOINTS.INSERT_CLINIC_SKILL}`, data)
    },
    UpdateClinicSkill: (data: any) => {
        return customAxios.post<any>(`${API_ENDPOINTS.INSERT_CLINIC_SKILL}`, data);
    },
    getClinicSiteList: (data: any) => {
        return customAxios.get<GenericResponse, GenericResponse>(`${API_ENDPOINTS.GET_CLINIC_SITES_DDL}`);
    },
    getAuthorizationStatusList: (data: any) => {
        return customAxios.get<GenericResponse, GenericResponse>(`${API_ENDPOINTS.GET_AUTHORIZATION_STATUS}`);
    },
    
    exportComplianceReport: (query: any) => {
        return customAxios.post<any, AppSuccessResponse>(`${API_ENDPOINTS.EXPORT_COMPLIANCE_REPORT}`, query);
    },
    AssignCertificateToStaffs: (data: any) => {
        return customAxios.post<any>(`${API_ENDPOINTS.ASSIGN_CERTIFICATE_TO_STAFFS}`, data);
    },
    getCalenderCategory: () => {
        return customAxios.get<GenericResponse, GenericResponse>(`${API_ENDPOINTS.GET_CALENDAR_CATEGORY}`);
    },
    deleteCalendarCategory: (id: any) => {
        return customAxios.delete<any>(`${API_ENDPOINTS.DELETE_CALENDAR_CATEGORY}${id}`);
    },
    insertCalendarCategory: (data: insertUpdateCalendarSettingModal) => {
        return customAxios.post<any>(`${API_ENDPOINTS.INSERT_CALENDAR_CATEGORY}`, data);
    },
    updateCalendarCategory: (data: insertUpdateCalendarSettingModal) => {
        return customAxios.post<any>(`${API_ENDPOINTS.UPDATE_CALENDAR_CATEGORY}`, data);
    },
    getDefaultCalendarCategories: () => {
        return customAxios.get<GenericResponse, GenericResponse>(`${API_ENDPOINTS.GET_DEFAULT_CALENDAR_CATEGORY}`);
    },
    updateDefaultCalendarCategories: (data:any) => {
        return customAxios.post<any>(`${API_ENDPOINTS.UPDATE_DEFAULT_CALENDAR_CATEGORY}`, data);
    },
    getClinicSites: () => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_CLINIC_SITES}`);
    },
}                       