import React, { ComponentState, useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
    ListView,
    ListViewHeader,
} from "@progress/kendo-react-listview";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { getBillingTransItemByClaimId, saveSelectedDocId } from "src/redux/actions";


const ServiceLineModel = ({ onClose, label, claimId }) => {
    const dispatch = useDispatch();
    const [billingTransClaimList, setBillingTransClaimList] = useState()

    const state: ComponentState = useSelector(states => {
        return states;
    });

    const { billingTransClaim } = state['BillingReducer'];

    useEffect(() => {
        dispatch(getBillingTransItemByClaimId(claimId))

    }, [])

    useEffect(() => {
        if (billingTransClaim && billingTransClaim?.resultData) {
            const list = billingTransClaim?.resultData
            setBillingTransClaimList(list)
        }

    }, [billingTransClaim])

    const handleDocumentId = (id) => {
        dispatch(saveSelectedDocId(id));
        onClose()
    }

    const MyItemRender = (props: any) => {
        let item = props.dataItem;
        return (
            <div className="row py-2 naming-list border-bottom align-middle m-0 mt-20">
                <div className={"col-1 text-left cursor-pointer text-theme"} onClick={() => handleDocumentId(item.documentId)}>{item.documentId}</div>
                <div className={"col-2 text-left"}>{item.clientName}</div>
                <div className={"col-2 text-left"}>{moment(item.serviceDate).format("M/D/yyyy")}</div>
                <div className={"col-1 text-left"}>{item.billingStatusName}</div>
                <div className={"col-2 text-left"}>${item.totServiceAmtBilled}</div>
                <div className={"col-2 text-left "}>{item.staff_lname + ", " + item.staff_fname}</div>
                <div className={"col-2 text-left "}>{item.payerControlNum}</div>
            </div>
        );
    };

    const MyHeader = () => {
        return (
            <ListViewHeader
                style={{
                    color: "#000000",
                    fontSize: 20,
                }}
                className="pb-2 pt-2"
            >
                <div
                    className="row py-2 border-bottom align-middle m-0 mt-20"

                >
                    <div className="col-1 text-left">
                        <h2
                            style={{
                                fontSize: 15,
                                color: "#000000",
                                marginBottom: 0,
                            }}
                            className=""
                        >
                            Doc ID
                        </h2>
                    </div>
                    <div className="col-2 text-left">
                        <h2
                            style={{
                                fontSize: 15,
                                color: "#000000",
                                marginBottom: 0,
                            }}
                            className=""
                        >
                            Client

                        </h2>
                    </div>
                    <div className="col-2 text-left">
                        <h2
                            style={{
                                fontSize: 15,
                                color: "#000000",
                                marginBottom: 0,
                            }}
                            className=""
                        >
                            DOS

                        </h2>
                    </div>
                    <div className="col-1 text-left">
                        <h2
                            style={{
                                fontSize: 15,
                                color: "#000000",
                                marginBottom: 0,
                            }}
                            className=""
                        >
                            Bill Status

                        </h2>
                    </div>
                    <div className="col-2 text-left">
                        <h2
                            style={{
                                fontSize: 15,
                                color: "#000000",
                                marginBottom: 0,
                            }}
                            className=""
                        >
                            Total Bill

                        </h2>
                    </div>
                    <div className="col-2 text-left">
                        <h2
                            style={{
                                fontSize: 15,
                                color: "#000000",
                                marginBottom: 0,
                            }}
                            className=""
                        >
                            Staff

                        </h2>
                    </div>
                    <div className="col-2 text-left">
                        <h2
                            style={{
                                fontSize: 15,
                                color: "#000000",
                                marginBottom: 0,
                            }}
                            className=""
                        >
                            Payer Claim #

                        </h2>
                    </div>

                </div>
            </ListViewHeader>
        );
    };

    return (
        <Dialog
            onClose={onClose}
            title={label}
            className="k-dialog-wrapper dialog-modal clients-modal-dash lg_modal paddingPlayer" width={"75%"}
            height={"60%"}
        >
            <ListView
                data={billingTransClaimList}
                item={MyItemRender}
                style={{
                    width: "100%",
                }}
                header={MyHeader}
            />
        </Dialog>
    );
}

export default ServiceLineModel;
