
import './loader.css';
function Loading() {
    return (
        <div className="loader">
        <div className="custom-loading-color" style={{position:"absolute",top:"350px", left:"700px" , color:"#5951e5"}}>
        <span className="k-loading-text" >Loading</span>
        <div className="k-loading-image" ></div>
        <div className="k-loading-color"  ></div>
      </div>
      </div>
    )
}
export default Loading;



