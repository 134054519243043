import {
  DYNAMIC_CHANGE_APP_ROUTE,
  LOGIN_USER,
  LOGOUT_USER,
  SAVE_REDIRECTED_DOC_ID,
  SET_APP_IS_IMPERSONATE_FLAG
} from "../actions/types";

const initialState = {
  userResponse: {},
  redirectedDocID: 0,
  appIsImpersonate: false,
  newAppRoute:null
};

export const logInReducer = (state = false, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return action.payload;
    default:
      return state;
  }
};
export const logOutReducer = (state = false, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return action.payload;
    default:
      return state;
  }
};

export const LoggedInReducer = (state = initialState, action) => {
  const oldState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case LOGIN_USER:
      return {
        ...oldState,
        userResponse: payload,
      };
    case SAVE_REDIRECTED_DOC_ID:
      return {
        ...oldState,
        redirectedDocID: payload,
      };

    case SET_APP_IS_IMPERSONATE_FLAG:
      return {
        ...oldState,
        appIsImpersonate: payload,
      };

      case DYNAMIC_CHANGE_APP_ROUTE:
        return {
          ...oldState,
          newAppRoute: payload,
        };
  }
  return oldState;
};
