/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useRef, useState, ComponentState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MultiSelectDropDown from "../../control-components/multi-select-drop-down/multi-select-drop-down";
import SideFilter from "./sideFilters";
import DatePickerKendoRct from "../../control-components/date-picker/date-picker";
import ErrorHelper from "../../helper/error-helper";
import ValidationHelper from "../../helper/validation-helper";
import { RadioGroup, RadioGroupChangeEvent, Switch } from "@progress/kendo-react-inputs";
import { userIsSuperAdmin } from "../../helper/permission-helper";
import { AdvanceSearchObject, DropDownClientArray, DropDownClientObject, DropDownObjectWithNumber, DropDownStaffArray, DropDownStaffObject } from "src/dataModels/docListModel";
import { getServiceList, saveDocumentSearchFilter, saveSwitchStaffValue, saveSwitchValue } from "src/redux/actions";
import { SiteDropDownComponent } from "../global-components/site-dropdown";
import { MultiSelectStaffDropDownComponent } from "../global-components/staff-dropdown/multi-select-staff-dropdown"
import { getClientListData, getStaffListData } from "../global-components/globalDataCheckerService";
import { ClientDropDownComponent } from "../global-components/client-dropdown";
import calender from "../../assets/images/calendar-03.png";
export interface DocumentSearchProps {
  advSearchFields: AdvanceSearchObject,
  setAdvSearchFields(data: AdvanceSearchObject): void,
  setDisplaySearchResult(data: boolean): void,
  setPage(data: number): void,
  setPageSize(data: number): void,
  docFilter(data: AdvanceSearchObject): void,
  showFilter: boolean,
  setShowFilter(data: boolean): void,
  setIsActiveCheck(data: boolean): void,
  isActiveCheck: boolean,
  pastDate: Date,
  currentDate: Date,
  openDateFilter: boolean,
  setOpenDateFilter(data: boolean): void,
  setTempInitialDate(value: Date): void,
  tempInitalDate: Date,
  setTempPrevDate(value: Date): void,
  tempPrevDate: Date,
  radioDateFilter: string,
  radioLabelFilter: string
  clearSearchObject: AdvanceSearchObject,
  handleChangeDateFilterTypes(event: RadioGroupChangeEvent): void,
  canSeeOtherStaffDoc: boolean,
}

const DocumentSearchView = (props: DocumentSearchProps) => {
  const state: ComponentState = useSelector(states => {
    return states;
  });
  const isHumanResourcesManager = state.getRolePermission.isHumanResourcesManager
  const vHelper = ValidationHelper();
  const {
    advSearchFields,
    setAdvSearchFields,
    setDisplaySearchResult,
    setPage,
    setPageSize,
    docFilter,
    showFilter,
    setShowFilter,
    setIsActiveCheck,
    isActiveCheck,
    pastDate,
    currentDate,
    openDateFilter,
    setOpenDateFilter,
    setTempInitialDate,
    tempInitalDate,
    setTempPrevDate,
    tempPrevDate,
    radioDateFilter,
    radioLabelFilter,
    handleChangeDateFilterTypes,
    clearSearchObject,
    canSeeOtherStaffDoc: canSeeOtherStaffDoc,
  } = props;
  const dispatch = useDispatch();
  const { clientList, servicesList, staffsList, switchValue, switchStaffValue } = state['DocumentReducer'];
  const staffId = useSelector((state: ComponentState) => state.loggedIn?.staffId);
  const staffLoginInfo = useSelector((state: ComponentState) => state.getStaffReducer);
  const isBillingManager = state.getRolePermission.isBillingManager
  const dateFilterTypes = [
    {
      label: "DOS",
      value: "DOS",
      condition: true
    },
    {
      label: "Date Submitted",
      value: "Submitted",
      condition: true
    },
    {
      label: "Payroll Date",
      value: "payroll",
      condition: isHumanResourcesManager || userIsSuperAdmin(staffLoginInfo?.roleId)
    },
    {
      label: "Date Last Billed",
      value: "lastbilled",
      condition: isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)
    },
  ];
  const filteredDateFilterTypes = dateFilterTypes.filter(option => option.condition);
  const outsideRef = useRef(null);
  const [serviceList, setServiceList] = useState([]);
  const [filter, setFilter] = useState<any>();
  const [loading, setLoading] = useState({ siteList: false, serviceList: false, staffList: false, patientList: false });
  const defaultPageSettings = { page: 0, pageSize: 25 };
  const [errors, setErrors] = useState({ serviceStartDate: '', serviceEndDate: '' });
  const [applyButtonClicked, setApplyButtonClicked] = useState(false)
  const [openClientFilter, setOpenClientFilter] = useState(false);
  const [openStaffFilter, setOpenStaffFilter] = useState(false);
  const [openServiceFilter, setOpenServiceFilter] = useState(false);
  const [openSiteFilter, setOpenSiteFilter] = useState(false)
  const [isCheckActiveClient, setIsCheckActiveClient] = useState<boolean>(true)
  const [isCheckActiveStaff, setIsCheckActiveStaff] = useState<boolean>(true)
  const [isValid, setIsValid] = useState(false);
  const [loadingClientList, setLoadingClientList] = useState(false);
  const [loadingStaffList, setLoadingStaffList] = useState(false);
  const refClient = useRef<HTMLInputElement>(null);
  const refStaff = useRef<HTMLInputElement>(null);
  const refSite = useRef<HTMLInputElement>(null);
  const refService = useRef<HTMLInputElement>(null);
  const refClientClose = useRef<HTMLInputElement>(null);
  const refStaffClose = useRef<HTMLInputElement>(null);
  const refSiteClose = useRef<HTMLInputElement>(null);
  const refServiceClose = useRef<HTMLInputElement>(null);
  const dropDownRef = useRef<HTMLInputElement>(null);
  const [changedServiceList, setChangedServiceList] = useState<DropDownObjectWithNumber[]>([]);
  const [changedClientList, setChangedClientList] = useState<DropDownClientObject[]>([]);
  const [changedStaffList, setChangedStaffList] = useState<DropDownStaffObject[]>([]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickListener);
    return () => {
      document.removeEventListener('mousedown', handleClickListener);
    };
  }, []);

  const handleClickListener = async (event) => {
    if (refClient.current && !refClient.current?.contains(event.target)) {
      if (event.defaultPrevented) {
        return;
      }
      setTimeout(() => {
        setOpenClientFilter(false)
      }, 200);
    }
    if (refStaff.current && !refStaff.current?.contains(event.target)) {
      if (event.defaultPrevented) {
        return;
      }
      setTimeout(() => {
        setOpenStaffFilter(false)
      }, 200);
    }
    if (refSite.current && !refSite.current?.contains(event.target)) {
      setTimeout(() => {
        setOpenSiteFilter(false)
      }, 200);
    }
    if (refService.current && !refService.current?.contains(event.target)) {
      if (event.defaultPrevented) {
        return;
      }
      setTimeout(() => {
        setOpenServiceFilter(false)
      }, 200);
    }
  }

  /* ============================= useEffects ============================= */

  useEffect(() => {
    setLoading({ ...loading, patientList: true, serviceList: true });
    dispatch(getServiceList(staffId));
  }, []);

  useEffect(() => {
    setAdvSearchFields({
      ...advSearchFields,
      serviceStartDate: tempInitalDate,
      serviceEndDate: tempPrevDate,
    });
    setIsValid(handleValidation());
  }, [tempInitalDate, tempPrevDate]);

  useEffect(() => {
    if (clientList != null) {
      setLoadingClientList(false)
      setLoading({ ...loading, patientList: false })
      if (advSearchFields.client) {
        const convertedClientList: DropDownClientArray = advSearchFields.client.map((item) => ({
          id: item.id,
          name: item.name,
          idInt: parseInt(item.id, 10) // Assuming idInt is supposed to be a number
        }));
        setChangedClientList(convertedClientList)
      }
    }
    if (staffsList != null) {
      setLoadingStaffList(false)
      setLoading({ ...loading, staffList: false })
      if (advSearchFields.staff) {
        const convertedStaffList: DropDownStaffArray = advSearchFields.staff.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setChangedStaffList(convertedStaffList)
      }
    }

    if (servicesList != null) {
      setLoading({ ...loading, serviceList: false })
      setServiceList(servicesList);
      if (advSearchFields.service) {
        setChangedServiceList(advSearchFields.service)
      }
    }
  }, [clientList, servicesList, staffsList]);

  /* ============================= Private functions ============================= */


  function closeAllFilters() {
    setOpenSiteFilter(false);
    setOpenClientFilter(false);
    setOpenStaffFilter(false);
    setOpenServiceFilter(false);
  }
  /* ============================= Event functions ============================= */

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };
  const handleChange = (e) => {
    const name: string = e.target.name;
    let value: DropDownObjectWithNumber | DropDownObjectWithNumber[] | string | null = e.target.value;
    if (value === "" && (name === "serviceStartDate" || name === "serviceEndDate")) {
      value = null;
    }
    const newAdvSearchFileds = {
      ...advSearchFields,
      [name]: value,
    };
    setAdvSearchFields(newAdvSearchFileds);
    if (name !== "serviceStartDate") {
      apply(newAdvSearchFileds);
      closeAllFilters();
    }
  };

  const handleChangeService = (name: string, value: DropDownObjectWithNumber[]) => {
    const newAdvSearchFileds = {
      ...advSearchFields,
      [name]: value,
    };
    setAdvSearchFields(newAdvSearchFileds);
    apply(newAdvSearchFileds);
    closeAllFilters();
  };

  const handleChangeMultiSelect = (name: string, value: any) => {
    const newAdvSearchFileds = {
      ...advSearchFields,
      [name]: value,
    };
    setAdvSearchFields(newAdvSearchFileds);
    apply(newAdvSearchFileds);
    closeAllFilters();
  };

  const handleHoldDate = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "serviceStartDate") {
      setTempInitialDate(value);
      setAdvSearchFields({
        ...advSearchFields,
        [name]: value,
        serviceEndDate: tempPrevDate,
      });
    }
    else {
      setTempPrevDate(value);
      setAdvSearchFields({
        ...advSearchFields,
        serviceStartDate: tempInitalDate,
        [name]: value,
      });
    }
  }

  const handleValidation = () => {
    let errorsObj = {};
    let formIsValid = true;
    if (!advSearchFields?.serviceStartDate) {
      formIsValid = false;
      errorsObj["serviceStartDate"] = ErrorHelper.POSITION_EFFECTIVE;
    } else if (advSearchFields?.serviceStartDate && advSearchFields?.serviceEndDate) {
      let error = vHelper.startDateGreaterThanValidator(
        advSearchFields?.serviceStartDate,
        advSearchFields?.serviceEndDate,
        "serviceStartDate",
        "serviceEndDate"
      );
      if (error && error.length > 0) {
        errorsObj["serviceStartDate"] = error;
        formIsValid = false;
      }
    }
    setErrors({ ...errors, serviceStartDate: errorsObj["serviceStartDate"] });
    return formIsValid;
  };

  const apply = (advSearchFields: AdvanceSearchObject) => {
    setDisplaySearchResult(true);
    setPage(defaultPageSettings.page);
    setPageSize(defaultPageSettings.pageSize);
    setIsValid(handleValidation());
    if (isValid) {
      dispatch(saveDocumentSearchFilter(advSearchFields))
    }
  };

  const handleApplyFilter = () => {
    setAdvSearchFields({
      ...advSearchFields,
      serviceStartDate: tempInitalDate,
      serviceEndDate: tempPrevDate,
      radioDateFilter: radioDateFilter,
      radioLabelFilter: radioLabelFilter
    })
    apply({
      ...advSearchFields,
      serviceStartDate: tempInitalDate,
      serviceEndDate: tempPrevDate,
      radioDateFilter: radioDateFilter,
      radioLabelFilter: radioLabelFilter
    });
  };

  const handleClearSite = () => {
    const newAdvSearchFileds: AdvanceSearchObject = {
      ...advSearchFields,
      site: null
    };
    setAdvSearchFields(newAdvSearchFileds);
    setPage(defaultPageSettings.page);
    setPageSize(defaultPageSettings.pageSize);
    dispatch(saveDocumentSearchFilter(newAdvSearchFileds))
    setFilter({ ...filter, siteList: [] })
  };

  const handleClearService = () => {
    setChangedServiceList([])
    const newAdvSearchFileds: AdvanceSearchObject = {
      ...advSearchFields,
      service: null,
    };
    setAdvSearchFields(newAdvSearchFileds);
    setPage(defaultPageSettings.page);
    setPageSize(defaultPageSettings.pageSize);
    dispatch(saveDocumentSearchFilter(newAdvSearchFileds))
  };

  const handleClearClient = () => {
    setChangedClientList([])

    const newAdvSearchFileds: AdvanceSearchObject = {
      ...advSearchFields,
      client: null,
    };
    setAdvSearchFields(newAdvSearchFileds);
    setPage(defaultPageSettings.page);
    setPageSize(defaultPageSettings.pageSize);
    dispatch(saveDocumentSearchFilter(newAdvSearchFileds))
  };

  const handleClearStaff = () => {
    setChangedStaffList([])
    const newAdvSearchFileds: AdvanceSearchObject = {
      ...advSearchFields,
      staff: null,
    };
    setAdvSearchFields(newAdvSearchFileds);
    setPage(defaultPageSettings.page);
    setPageSize(defaultPageSettings.pageSize);
    dispatch(saveDocumentSearchFilter(newAdvSearchFileds))
  };

  const clearAllFilter = () => {
    setChangedServiceList([])
    setChangedClientList([])
    setChangedStaffList([])
    setAdvSearchFields(clearSearchObject);
    setTempInitialDate(pastDate)
    setTempPrevDate(currentDate)
    setFilter({})
    setIsActiveCheck(false);
    dispatch(saveDocumentSearchFilter(null))
  };

  function renderToItem(li, itemProps) {
    const itemChildren = (
      <span>
        <input
          type="checkbox"
          name={itemProps.dataItem}
          checked={itemProps.selected}
          onChange={(e) => itemProps.onClick(itemProps.index, e)}
        />
        &nbsp;{li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }

  let clientCounts = advSearchFields?.client?.length ?? 0 - 1;
  let staffCounts = advSearchFields?.staff?.length ?? 0 - 1;
  let serviceCounts = advSearchFields?.service?.length ?? 0 - 1;
  const handleSwitch = (e: any) => {
    var changeSwitchValue = e.target.value;
    setLoadingClientList(true)
    getClientListData(isCheckActiveClient, (changeSwitchValue === true ? false : true))
    dispatch(saveSwitchValue(changeSwitchValue === true ? false : true))
    setIsCheckActiveClient(changeSwitchValue);
  };
  const handleSwitchStaff = (e: any) => {
    var changeSwitchStaffValue = e.target.value;
    setLoadingStaffList(true)
    getStaffListData(isCheckActiveStaff, (changeSwitchStaffValue === true ? false : true))
    dispatch(saveSwitchStaffValue(changeSwitchStaffValue === true ? false : true))
    setIsCheckActiveStaff(changeSwitchStaffValue);
  };

  useEffect(() => {
    setIsCheckActiveClient(switchValue !== undefined ? switchValue : isCheckActiveClient)


  }, [switchValue])

  useEffect(() => {
    setIsCheckActiveStaff(switchStaffValue !== undefined ? switchStaffValue : isCheckActiveStaff)

  }, [switchStaffValue])

  /* ============================= Render View ============================= */

  return (
    <>
      <div className="col-lg-9 col-sm-12 pl-0 blueTheme" >
        <div
          className="content-search-filter  px-0 filter-drop-down"
          ref={outsideRef}
        >
          {/*start click major filter */}
          <div className="major-filter gapresponsive">
            <div
              onClick={() => setOpenDateFilter(true)}
              onBlur={() => {
                if (!applyButtonClicked) {
                  setOpenDateFilter(false);
                }
              }}

              className="service-main dropdown email-filter border-dashed-cus position-relative staffButton"
            >
              <button className="btn  btn-size-cus " type="button">
                Date ({radioLabelFilter})
                <span className="border-spann">
                  {moment(advSearchFields?.serviceStartDate).format("M/D/yyyy") +
                    " - " +
                    moment(advSearchFields?.serviceEndDate).format("M/D/yyyy")}
                </span>
              </button>

              {openDateFilter && (
                <div className="dropdown-service   " onFocus={() => setOpenDateFilter(true)}>
                  <div className="row staffButton">
                    <div className="col-md-6 position-relative"  >
                      <DatePickerKendoRct
                        validityStyles={false}
                        value={new Date(tempInitalDate)}
                        onChange={handleHoldDate}
                        name="serviceStartDate"
                        label="From"
                      />
                       <img src={calender} alt="" className="leftIcon " style={{ top: "30px", left: "19px" }} />
                    </div>
                    <div className="col-md-6 position-relative">
                      <DatePickerKendoRct
                        validityStyles={false}
                        value={new Date(tempPrevDate)}
                        onChange={handleHoldDate}
                        max={new Date()}
                        name="serviceEndDate"
                        label="To"
                      />
                       <img src={calender} alt="" className="leftIcon " style={{ top: "30px", left: "19px" }} />
                    </div>
                    {errors?.serviceStartDate && (
                      <div className="col-md-12 align-items-center justify-content-center">
                        <div className="col-12 text-center">
                          <p style={{ color: "#d61923", fontSize: "12px", fontStyle: "italic" }}>{errors?.serviceStartDate}</p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row mt-2 blue_theme"
                    onFocus={() => setOpenDateFilter(true)}
                    onMouseDown={() => setApplyButtonClicked(true)}
                    onMouseUp={() => setApplyButtonClicked(false)}
                  >
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <RadioGroup
                        data={filteredDateFilterTypes}
                        value={radioDateFilter}
                        onChange={handleChangeDateFilterTypes}
                        layout={"horizontal"}
                      />
                    </div>
                    <div className="col-lg-4 col-md-8 col-sm-12">
                      <div className="text-center ">
                        <button onClick={handleApplyFilter} type="button" className="submitButon">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              onClick={(event: any) => {
                if (!refClientClose.current?.contains(event.target)) {
                  setOpenClientFilter(true)
                }
              }}
              ref={refClient}
              className="service-main dropdown email-filter border-dashed-cus position-relative staffButon"
            >
              <button className="btn  btn-size-cus pl-0 " type="button">
                {advSearchFields?.client?.length && advSearchFields?.client?.length > 0 ?
                  <i
                    ref={refClientClose}
                    onClick={handleClearClient}
                    className={"fa fa-times cross-icon mr-2 "}
                  ></i>
                  :
                  <i
                    className={"fa fa-plus cross-icon "}

                  ></i>}
                Client {advSearchFields?.client?.length !== 0 && (
                  <span
                    className={advSearchFields?.client?.length && advSearchFields?.client?.length > 0 ? 'border-spann' : ''}
                  >
                    {advSearchFields?.client?.at(0)?.name}{(advSearchFields?.client?.length && advSearchFields?.client?.length > 1) ? '(+' + clientCounts + ')' : ''}
                  </span>
                )}
              </button>

              {openClientFilter && (
                <div className="dropdown-service dropDates document-dropdown  ">
                  <div className="row justify-content-end">
                    <div ref={refClientClose} className="col-lg-4 col-md-8 col-sm-12">
                      <div className="text-right ">
                        <button onClick={(event: any) => {
                          setOpenClientFilter(false)
                          handleChangeMultiSelect('client', changedClientList)
                        }} type="button" className="submitButon">
                          Apply
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12 staffButton">
                      <div className="d-flex justify-content-center align-items-center ml-3 blue_theme switchAbsolute mb-2" >
                        <Switch
                          onChange={handleSwitch}
                          checked={isCheckActiveClient === true ? false : true}
                          onLabel={""}
                          offLabel={""}
                          className="switch-on ml-1"
                        />
                        <span className="switch-title-text  text-deign ml-1">
                          {" "}
                          {isCheckActiveClient
                            ? "Show Inactive "
                            : "Show Inactive "}
                        </span>
                      </div>
                      {
                        !loadingClientList && (
                          <div className="col-md-12">
                            <ClientDropDownComponent
                              onChangeClient={(event: any) => {
                                setChangedClientList(event.target.value)
                              }}
                              selectedClient={changedClientList}
                              isCheckActiveClient={isCheckActiveClient}
                              switchClientValue={switchValue}
                              hideLabel={false}
                              canRefresh={true}
                            />
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              ref={refStaff}
              onClick={(event: any) => {
                if (canSeeOtherStaffDoc) {
                  if (!refStaffClose.current?.contains(event.target)) {
                    setOpenStaffFilter(true)
                  }
                }
              }}
              className="service-main dropdown email-filter border-dashed-cus position-relative staffButton blue_theme "
            >
              <button className="btn  btn-size-cus pl-0 " type="button">
                {advSearchFields?.staff?.length && advSearchFields?.staff?.length > 0 ?
                  <i
                    ref={refStaffClose}
                    onClick={() => {
                      if (canSeeOtherStaffDoc) {
                        handleClearStaff()
                      }
                    }}
                    className={"fa fa-times cross-icon mr-2 "}
                  ></i>
                  :
                  <i
                    className={"fa fa-plus cross-icon "}
                  ></i>
                }
                Staff
                {advSearchFields?.staff?.length !== 0 && (
                  <span
                    className={advSearchFields?.staff && advSearchFields?.staff?.length !== 0 ? 'border-spann' : ''}
                  >
                    {advSearchFields?.staff?.at(0)?.name}{(advSearchFields?.staff?.length && advSearchFields?.staff?.length > 1) ? '(+' + staffCounts + ')' : ''}
                  </span>
                )}
              </button>

              {openStaffFilter && (
                <div className="dropdown-service document-dropdown blue_theme">
                  <div className="row justify-content-end staffButton">
                    <div ref={refStaffClose} className="col-lg-4 col-md-8 col-sm-12">
                      <div className="text-right ">
                        <button onClick={(event: any) => {
                          setOpenStaffFilter(false)
                          handleChangeMultiSelect('staff', changedStaffList)
                        }} type="button" className="submitButon">
                          Apply
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12 staffButton">
                      <div className="d-flex justify-content-center align-items-center ml-3 switchAbsolute" >
                        <Switch
                          onChange={handleSwitchStaff}
                          checked={isCheckActiveStaff === true ? false : true}
                          onLabel={""}
                          offLabel={""}
                          className="switch-on ml-1"
                        />
                        <span className="switch-title-text  text-deign ">
                          {" "}
                          {isCheckActiveStaff
                            ? "Show Inactive "
                            : "Show Inactive "}
                        </span>
                      </div>
                    </div>
                    {(!loadingStaffList) &&
                      <div className="col-md-12" ref={dropDownRef}>
                        {/* <MultiSelectDropDown
                          ref={dropDownRef}
                          data={staffList}
                          textField="name"
                          label="Staff Name"
                          name="staff"
                          disabled={!canSeeOtherStaffDoc}
                          value={changedStaffList}
                          onChange={(event) => {
                            setChangedStaffList(event.target.value)
                          }}
                          autoClose={false}
                          dataItemKey={"id"}
                          itemRender={renderToItem}
                        /> */}
                        <MultiSelectStaffDropDownComponent
                          selectedStaff={changedStaffList}
                          isCheckActiveStaff={isCheckActiveStaff}
                          switchStaffValue={switchStaffValue}
                          onChangeStaff={(event: any) => {
                            setChangedStaffList(event.target.value)
                          }}
                          disabled={!canSeeOtherStaffDoc}
                        />
                      </div>
                    }
                  </div>
                </div>
              )}
            </div>
            <div
              ref={refService}
              onClick={(event: any) => {
                if (!refServiceClose.current?.contains(event.target)) {
                  setOpenServiceFilter(true)
                }
              }
              }
              className="service-main dropdown email-filter border-dashed-cus position-relative staffButton "
            >
              <button className="btn  btn-size-cus pl-0 " type="button">
                {advSearchFields?.service?.length && advSearchFields?.service?.length > 0 ? (
                  <i
                    ref={refServiceClose}
                    onClick={handleClearService}
                    className={"fa fa-times cross-icon mr-2 "}
                  ></i>
                ) : (
                  <i
                    className={"fa fa-plus cross-icon "}
                  ></i>
                )}     Service
                {advSearchFields?.service?.length !== 0 && (
                  <span className={advSearchFields?.service ? "border-spann" : ''}>
                    {advSearchFields?.service?.at(0)?.name} {(advSearchFields?.service?.length && advSearchFields?.service?.length > 1) ? '(+' + serviceCounts + ')' : ''}
                  </span>
                )}
              </button>

              {openServiceFilter && (
                <div className="dropdown-service dropInputField service-dropdown ">
                  <div className="row justify-content-end">
                    <div ref={refServiceClose} className="col-lg-4 col-md-8 col-sm-12">
                      <div className="text-center ">
                        <button onClick={(event: any) => {
                          setOpenServiceFilter(false)
                          handleChangeService('service', changedServiceList)
                        }} type="button" className="submitButon">
                          Apply
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12 py-2 " ref={dropDownRef}>
                      <div className="topSpacing">
                      <MultiSelectDropDown
                        data={serviceList}
                        textField="name"
                        label="Service "
                        ref={dropDownRef}
                        name="service"
                        disabled={!canSeeOtherStaffDoc}
                        value={changedServiceList}
                        onChange={(event) => {
                          setChangedServiceList(event.target.value)
                        }}
                        autoClose={false}
                        dataItemKey={"id"}
                        itemRender={renderToItem}
                      />
                    </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              onClick={(event: any) => {
                if (!refSiteClose.current?.contains(event.target)) {
                  setOpenSiteFilter(true)
                }
              }}
              ref={refSite}
              className="service-main dropdown email-filter border-dashed-cus position-relative staffButton"
            >
              <button className="btn  btn-size-cus pl-0 " type="button">
                {advSearchFields?.site?.id ?
                  <i
                    ref={refSiteClose}
                    onClick={handleClearSite}
                    className={"fa fa-times cross-icon mr-2 "}
                  ></i>
                  :
                  <i
                    className={"fa fa-plus cross-icon "}

                  ></i>
                }     Site
                <span className={advSearchFields?.site?.id ? "border-spann" : ''}>
                  {advSearchFields?.site?.name && advSearchFields?.site?.name?.split('(')?.length > 0 ? advSearchFields.site.name.split('(')[0] : ""}
                </span>
              </button>

              {openSiteFilter && (
                <SiteDropDownComponent
                  selectedSite={advSearchFields.site}
                  onChangeSite={handleChange}
                />
              )}

            </div>
          </div>
        </div>
      </div >
      <div className="col-lg-3 col-sm-12 d-flex justify-content-end clear-add-filter">
        <div className="mr-3">
          <p className="mb-0" onClick={clearAllFilter}>
            Clear Filter
          </p>
        </div>
        <div>
          <p
            onClick={handleShowFilter}
            className="mb-0 add-filter-cus position relative"
          >
            <i className="fa fa-filter  fiter-icon "></i>
            <i className="fa fa-plus d-none"></i> Add Filter
          </p>
        </div>
      </div>
      {
        showFilter && (
          <SideFilter
            showFilter={showFilter}
            handleShowFilter={handleShowFilter}
            advSearchFields={advSearchFields}
            isPayrollPaid={advSearchFields.isPayrollPaid}
            setAdvSearchFields={setAdvSearchFields}
            setPage={setPage}
            setPageSize={setPageSize}
            defaultPageSettings={defaultPageSettings}
            docFilter={docFilter}
            setIsActiveCheck={setIsActiveCheck}
            isActiveCheck={isActiveCheck}
          />
        )
      }
    </>
  );
};

export default DocumentSearchView;