import React, { ComponentElement, ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { FormControlLabel, Radio } from "@mui/material";

export const Gad7Ad = ({
    listItems,
    setListItems,

    subTitle
}) => {

    const [opList, setOpList] = useState(listItems)
    const [totalSum, setTotalSum] = useState<any>();
    const [avgTotalSum, setAvgTotalSum] = useState<any>();
    const [prorated, setProrated] = useState<any>();

    useEffect(() => {
        if (opList && opList.length > 0) {
            setListItems(opList);
            let filteredList = opList.filter( function (score) {
                return score.value!==null
              });
            if(filteredList?.length>7){
                const totalSum=filteredList.reduce((sum, item) => sum + (Number(item.value) || 0), 0)
                setTotalSum(totalSum);
                if(filteredList?.length===opList.length){
                    setAvgTotalSum(totalSum/opList.length)
                }
                if(filteredList?.length<10){
                    setProrated(totalSum/filteredList?.length)
                }else{
                    setProrated(null)
                }
            } else{
                setAvgTotalSum(null)
                setTotalSum(null)
                setProrated(null)
            }
        }
    }, [opList]);

   

    const handleRadioChange = (itemId, value) => {
        // Find the item in the items array
        setOpList(list => list.map((item, i) =>
            item.id === itemId
                ? {
                    ...item,
                    value: value
                }
                : item
        ));
    };


    return (
        <div className="ACE_main_table mt-4">
            <div>
                {/* <div className="head-3">{title}</div> */}
                <label className="pb-3 mt-2">
                    {subTitle}
                </label>
            </div>
            <div className="row border-bottom mb-3">
                <div className="col-12">
                    <b>Instructions :</b>
                The following questions ask about thoughts, feelings, and behaviors, often tied to concerns about family, health, finances, school, and work. Please respond to each item by marking (&#10004; or &#10006; ) one box per row.
                </div>

            </div>
            <div className="row border-bottom mb-3">
                <div className="col-12">
                    <label className="fw-400">
                        NOTE: 0=Never, 1 = Occasionally; 2 = Half of the time; 3 = Most of the time; 4 = All of the time;
                    </label>
                </div>

            </div>
            {opList?.map((item) => (
                <div className="row mb-3 pb-2 border-bottom">
                    <div className="col-12">
                        <div key={item.id}>
                            <label>
                                {item.id}. {item.name}
                            </label>
                        </div>
                    </div>
                    <div className="col-12  select_table_phq cus-select-table-drops">
                        {[0, 1, 2, 3, 4].map((value) => (
                            <label key={value} className="cats_label">
                                <input
                                    type="radio"
                                    value={value}
                                    checked={item.value == value}
                                    onChange={() => handleRadioChange(item.id, value)}
                                    className="mr-2 mb-0 ml-2"
                                />
                                <span>{value}</span></label>
                        ))}
                    </div>
                </div>
            ))}
            <div className="text-start justify-content-start total_phq col-12">
                <p><b>Total/Partial Raw Score:</b> </p> 
               { (totalSum || totalSum===0) ? 
               <p className="submit_phq mb-0"> {totalSum}</p> : <p className="submit_phq mb-0"> &nbsp;</p>  }
            </div>
            <div className="text-start justify-content-start total_phq col-12">
                <p><b>Prorated Total Raw Score: (if 1-2 items left unanswered):</b> </p>
                {
                 (prorated || prorated===0) ? <p className="submit_phq mb-0"> {Math.round(prorated)}</p> : <p className="submit_phq mb-0"> &nbsp;</p>  
                }
            </div>

            <div className="text-start justify-content-start total_phq col-12">
                <p><b>Average Total Score:</b> </p>
                
                {
                 (avgTotalSum || avgTotalSum===0) ? <p className="submit_phq mb-0"> {Math.round(avgTotalSum)}</p> : <p className="submit_phq mb-0"> &nbsp;</p>  
                } 
            </div>
        </div>
    );
};

