import { takeLatest, put } from 'redux-saga/effects';
import { getClientMessageLabels, saveClientMessageLabels} from '../actions';
import * as TYPES from '../actions/types';
import { customAxios } from 'src/services/useExPressApi';
import { API_ENDPOINTS } from 'src/services/api-endpoints';
import { NotificationManager } from "react-notifications";



export function* getClientMessageLabelSaga() {
  yield takeLatest(TYPES.GET_CLIENT_MESSAGE_LABELS, labelWorker);
}

function* labelWorker(param: any) {
  try {
    const response = yield customAxios.get(API_ENDPOINTS.GET_CLIENT_MESSAGE_LABEL);
    if (response && response.status) {
      yield put(saveClientMessageLabels(response.resultData));
    }
  } catch (err) {
  }
}



export function* saveClientMessageLabelSaga() {
  yield takeLatest(TYPES.ADD_CLIENT_MESSAGE_LABELS, saveLabelWorker);
}

function* saveLabelWorker(param: any) {
  try {
    const response = yield customAxios.post(API_ENDPOINTS.INSERT_CLIENT_MESSAGE_LABEL,param['payload']);
    if (response && response.status) {
      NotificationManager.success('New label is added successfully!')
      yield put(getClientMessageLabels());
    }
  } catch (err) {
  }
}


export function* updateClientMessageLabelSaga() {
  yield takeLatest(TYPES.UPDATE_CLIENT_MESSAGE_LABELS, updateWorker);
}

function* updateWorker(param: any) {
  try {
    const response = yield customAxios.put(API_ENDPOINTS.UPDATE_CLIENT_MESSAGE_LABEL,param['payload']);
    if (response && response.status) {
      NotificationManager.success('Label is updated successfully!')
      yield put(getClientMessageLabels());
    }
  } catch (err) {
  }
}

export function* deleteClientMessageLabelSaga() {
  yield takeLatest(TYPES.DELETE_CLIENT_MESSAGE_LABELS, deleteWorker);
}

function* deleteWorker(param: any) {
  try {
    const response = yield customAxios.delete(`${API_ENDPOINTS.DELETE_CLIENT_MESSAGE_LABEL}${param['payload']}`);
    if (response && response.status) {
      NotificationManager.success('Label is deleted successfully!')
      yield put(getClientMessageLabels());
    }
  } catch (err) {
  }
}


export function* moveClientMessageSaga() {
  yield takeLatest(TYPES.MOVE_CLIENT_MESSAGE_TO_LEVEL, moveWorker);
}

function* moveWorker(param: any) {
  try {
    const response = yield customAxios.post(`${API_ENDPOINTS.MOVE_CLIENT_MESSAGE_TO_LEVEL}`,param['payload']);
    if (response && response.status) {
      NotificationManager.success("Success");
    }
  } catch (err) {
  }
}
