import {
  LOADER, LOADER_DOC_LIST ,LODING_INDICATOR
} from "../actions/types";

const initialState = {
  loadingState: false,
  loadingStateDocList: false,
  refresh: false,
  pullToRefreshIndicator: false,
  footerIndicator: false,
  loadingStateGrid: false,
  loadingIndicatorState :false
};

export const LoaderReducer = (state = initialState, action) => {
  const oldState = {...state};
  const {type, payload} = action;
  switch (type) {
    case LOADER:
      return {
        ...oldState,
        loadingState: payload,
      };
    case LOADER_DOC_LIST:
        return {
          ...oldState,
          loadingStateDocList: payload,
        };
        case LODING_INDICATOR:
          return {
            ...oldState,
            loadingIndicatorState: payload,
          };
  }
  return oldState;
};
