import {
  SAVE_STAFF_DOC_STAFF_LIST,
  SAVE_STAFF_DOC_TEMPLATES,
  SAVE_STAFF_GOALS,
  SAVE_STAFF_DOC_TEMPLATE_SETTING,
  SAVE_SKILL_SET,
  SAVE_STAFF_DOC,
  SAVE_STAFF_DOC_LISTING,
  SAVE_STAFF_DOC_LOADING_STATE,
  SAVE_STAFF_DOC_CREATE_LOADING_STATE,
  SAVE_STAFF_DOC_BY_ID,
  SAVE_STAFF_DOC_SIGNATURE,
  SAVE_STAFF_DOC_ATTACHMENTS,
  SAVE_STAFF_DOC_SEARCH_FILTERS
} from "../actions/types";

const initialState = {
  staffDocTemplateList: [],
  staffList: [],
  docSettingsData: {},
  staffGoals: [],
  skillsArray: [],
  docListData: {},
  docAttachments: [],
  docSignature: [],
  loadingStateDocList: false,
  loadingStateDocCreate: false,
  docCreateResponse: {},
  documentDetailedData: {},
  staffDocFilterObj:{}
};

export const StaffDocReducer = (state = initialState, action) => {
  const oldState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case SAVE_STAFF_DOC_TEMPLATES:
      return {
        ...oldState,
        staffDocTemplateList: payload,
      };

    case SAVE_STAFF_DOC_STAFF_LIST:
      return {
        ...oldState,
        staffList: payload,
      };
    case SAVE_STAFF_DOC_TEMPLATE_SETTING:
      return {
        ...oldState,
        docSettingsData: payload,
      };
    case SAVE_STAFF_GOALS:
      return {
        ...oldState,
        staffGoals: payload,
      };

    case SAVE_SKILL_SET:
      return {
        ...oldState,
        skillsArray: payload,
      };

    case SAVE_STAFF_DOC:
      return {
        ...oldState,
        docCreateResponse: payload,
      };

    case SAVE_STAFF_DOC_LISTING:
      return {
        ...oldState,
        docListData: payload,
      };

    case SAVE_STAFF_DOC_LOADING_STATE:
      return {
        ...oldState,
        loadingStateDocList: payload,
      };

    case SAVE_STAFF_DOC_CREATE_LOADING_STATE:
      return {
        ...oldState,
        loadingStateDocCreate: payload,
      };
    case SAVE_STAFF_DOC_BY_ID:
      return {
        ...oldState,
        documentDetailedData: payload,
      };
    case SAVE_STAFF_DOC_SIGNATURE:
      return {
        ...oldState,
        docSignature: payload,
      };
    case SAVE_STAFF_DOC_ATTACHMENTS:
      return {
        ...oldState,
        docAttachments: payload,
      };
      case SAVE_STAFF_DOC_SEARCH_FILTERS:
        return {
          ...oldState,
          staffDocFilterObj: payload,
        };
  }
  return oldState;
};
