import { useState } from "react";

function ClipboardCopy({ copyText }) {
    const [isCopied, setIsCopied] = useState(false);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
            });
    }

    return (
        <div>
            {/* <input type="text" value={copyText} readOnly /> */}
            {/* Bind our handler function to the onClick button property */}
            <button className="copy-mainbtn" onClick={handleCopyClick}>
            <i class="fa fa-copy" style={{ color: "white", marginRight: "5px" }}></i>  {isCopied ? 'Copied!' : 'Copy Meeting Link'}
                {/* <i onClick={handleCopyClick} className="fa fa fa-clone cursor-default">
                </i> */}
            </button>
        </div>
    );
}

export default ClipboardCopy