import React, { ComponentState, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ClientService } from "src/services/clientService";
import APP_ROUTES from "src/helper/app-routes";
import { renderErrors } from "src/helper/error-message-helper";
import NotificationManager from "react-notifications/lib/NotificationManager";

import { NichqHeader } from "./Nichq-heads";
import { Spsrsbns_Ps_QueSet } from "./Spsrsbns_Ps_QueSet";
import { Spsrsbns_Ns_QueSet } from "./Spsrsbns_Ns_QueSet";






export const SPSRSBNS_Scale = ({ SPSRSBNS_PS, SPSRSBNS_PS_ADDON, SPSRSBNS_NS, SPSRSBNS_NS_ADDON, response, interfered, isPrint, questionId }) => {



  const [spsrsbns_Ps, setSpsrsbns_Ps] = useState<any[]>([]);
  const [spsrsbns_Ps_Addon, setSpsrsbns_Ps_Addon] = useState<any[]>([]);

  const [spsrsbns_Ns, setSpsrsbns_Ns] = useState<any[]>([]);
  const [spsrsbns_Ns_Addon, setSpsrsbns_Ns_Addon] = useState<any[]>([]);

  const { state } = useLocation();
  const selectedClientId = useSelector((state: ComponentState) => state.selectedClientId);
  const navigate = useNavigate();


  useEffect(() => {

    const Spsrsbns_Ps = SPSRSBNS_PS.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });

    const Spsrsbns_Ps_Addon = SPSRSBNS_PS_ADDON.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    const Spsrsbns_Ns = SPSRSBNS_NS.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });

    const Spsrsbns_Ns_Addon = SPSRSBNS_NS_ADDON.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });


    setSpsrsbns_Ps(Spsrsbns_Ps)
    setSpsrsbns_Ps_Addon(Spsrsbns_Ps_Addon)

    setSpsrsbns_Ns(Spsrsbns_Ns)
    setSpsrsbns_Ns_Addon(Spsrsbns_Ns_Addon)



  }, [response])

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId, 0,
      [

        ...spsrsbns_Ps,
        ...spsrsbns_Ps_Addon,
        ...spsrsbns_Ns,
        ...spsrsbns_Ns_Addon,
      ],
      questionId
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        // setLoading(false);
        renderErrors(error.message);
      });
  };

  return (

    <div className="ml-3">

      <div className="mt-4 head-4">
        4-ITEM POSITIVE SYMPTOM RATING SCALE (PSRS)
        <br />
        (Version 5.0)
      </div>
      <div>
        SCALE ITEMS AND ANCHOR POINTS
      </div>


      <div className="mt-4">
        <b>1. SUSPICIOUSNESS: </b> &nbsp;
        Expressed or apparent belief that other persons have acted maliciously or with
        discriminatory intent. Include persecution by supernatural or other nonhuman agencies (e.g., the devil).
        Note: Ratings of "3" or above should also be rated under Unusual Thought Content.
        <br />
        <i>Do you ever feel uncomfortable in public? Does it seem as though others are watching you? </i> <br />
        <i>Are you concerned about anyone's intentions toward you? </i> <br />
        <i>Is anyone going out of their way to give you a hard time, or trying to hurt you? Do you feel in any
          danger? </i> <br />
        [If patient reports any persecutory ideas/delusions, ask the following]:  <br />
        <i>How often have you been concerned that [use patient's description]? Have you told anyone about these
          experiences? </i> <br />
      </div>
      <div className="mt-2 px-5">

        <div className="row md-12">
          <div className="md-4"><b>1 Not Present</b></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>2 Very Mild</b></div>
          <div className="md-8">&nbsp;
            Seems on guard. Reluctant to respond to some "personal" questions. Reports being overly selfconscious
            in public.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>3 Mild</b></div>
          <div className="md-8">&nbsp;
            Describes incidents in which others have harmed or wanted to harm him/her that sound
            plausible. Patient feels as if others are watching, laughing, or criticizing him/her in public, but this
            occurs only occasionally or rarely. Little or no preoccupation.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>4 Moderate</b></div>
          <div className="md-8">&nbsp;
            Says others are talking about him/her maliciously, have negative intentions, or may harm
            him/her. Beyond the likelihood of plausibility, but not delusional. Incidents of suspected
            persecution occur occasionally (less than once per week) with some preoccupation.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>5 Moderately Severe</b></div>
          <div className="md-8">&nbsp;
            Same as 4, but incidents occur frequently, such as more than once per week. Patient is
            moderately preoccupied with ideas of persecution OR patient reports persecutory delusions
            expressed with much doubt (e.g., partial delusion).
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>6 Severe</b></div>
          <div className="md-8">&nbsp;
            Delusional -- speaks of Mafia plots, the FBI, or others poisoning his/her food, persecution by
            supernatural forces.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>7 Extremely Severe</b></div>
          <div className="md-8">&nbsp;
            Same as 6, but the beliefs are bizarre or more preoccupying. Patient tends to disclose or act on
            persecutory delusions.
          </div>
        </div>
      </div>


      <div className="mt-4">
        <b>2. UNUSUAL THOUGHT CONTENT: </b> &nbsp;
        Unusual, odd, strange or bizarre thought content. Rate the degree of
        unusualness, not the degree of disorganization of speech. Delusions are patently absurd, clearly false
        or bizarre ideas that are expressed with full conviction. Consider the patient to have full conviction if
        he/she has acted as though the delusional belief were true. Ideas of reference/persecution can be
        differentiated from delusions in that ideas are expressed with much doubt and contain more elements of
        reality. Include thought insertion, withdrawal and broadcast. Include grandiose, somatic and
        persecutory delusions even if rated elsewhere. Note: If Suspiciousness is rated "6" or "7" due to
        delusions, then Unusual Thought Content must be rated a "4" or above.

        <br />
        <i>Have you been receiving any special messages from people or from the way things are arranged around
          you? Have you seen any references to yourself on TV or in the newspapers? </i> <br />
        <i>Can anyone read your mind? </i> <br />
        <i>Do you have a special relationship with God? </i> <br />
        <i>Is anything like electricity, X-rays, or radio waves affecting you? </i> <br />
        <i>Are thoughts put into your head that are not your own? </i> <br />
        <i>Have you felt that you were under the control of another person or force? </i> <br />
        [If patient reports any odd ideas/delusions, ask the following]:  <br />
        <i>How often do you think about [use patient's description]? </i> <br />
        <i>Have you told anyone about these experiences? How do you explain the things that have been
          happening [specify]? </i> <br />
      </div>
      <div className="mt-2 px-5">

        <div className="row md-12">
          <div className="md-4"><b>1 Not Present</b></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>2 Very Mild</b></div>
          <div className="md-8">&nbsp;
            Ideas of reference (people may stare or may laugh at him), ideas of persecution (people may
            mistreat him). Unusual beliefs in psychic powers, spirits, UFOs, or unrealistic beliefs in one's
            own abilities. Not strongly held. Some doubt.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>3 Mild</b></div>
          <div className="md-8">&nbsp;
            Same as 2, but degree of reality distortion is more severe as indicated by highly unusual ideas or
            greater conviction. Content may be typical of delusions (even bizarre), but without full conviction.
            The delusion does not seem to have fully formed, but is considered as one possible explanation
            for an unusual experience.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>4 Moderate</b></div>
          <div className="md-8">&nbsp;
            Delusion present but no preoccupation or functional impairment. May be an encapsulated
            delusion or a firmly endorsed absurd belief about past delusional circumstances.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>5 Moderately Severe</b></div>
          <div className="md-8">&nbsp;
            Full delusion(s) present with some preoccupation OR some areas of functioning disrupted by
            delusional thinking.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>6 Severe</b></div>
          <div className="md-8">&nbsp;
            Full delusion(s) present with much preoccupation OR many areas of functioning are disrupted by
            delusional thinking.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>7 Extremely Severe</b></div>
          <div className="md-8">&nbsp;
            Full delusions present with almost total preoccupation OR most areas of functioning are
            disrupted by delusional thinking.
          </div>
        </div>
      </div>
      <div className="mt-4">
        <b>3. HALLUCINATIONS: </b> &nbsp;
        Reports of perceptual experiences in the absence of relevant external stimuli.
        When rating degree to which functioning is disrupted by hallucinations, include preoccupation with the
        content and experience of the hallucinations, as well as functioning disrupted by acting out on the
        hallucinatory content (e.g., engaging in deviant behavior due to command hallucinations). Include
        "thoughts aloud" ("gedankenlautwerden") or pseudohallucinations (e.g., hears a voice inside head) if a
        voice quality is present.
        <br />
        <i>Do you ever seem to hear your name being called? </i> <br />
        <i>Have you heard any sounds or people talking to you or about you when there has been nobody around? </i> <br />
        <i>[If hears voices]: What does the voice/voices say? Did it have a voice quality? </i> <br />
        <i>Do you ever have visions or see things that others do not see'? What about smell — odors that others
          do not smell? </i> <br />
        [If the patient reports hallucinations, ask the following]:  <br />
        <i>Have these experiences interfered with your ability to perform your usual activities/work? </i> <br />
        <i>How do you explain them? How often do they occur? </i> <br />

      </div>
      <div className="mt-2 px-5">

        <div className="row md-12">
          <div className="md-4"><b>1 Not Present</b></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>2 Very Mild</b></div>
          <div className="md-8">&nbsp;
            While resting or going to sleep, sees visions, smells odors, or hears voices, sounds or whispers
            in the absence of external stimulation, but no impairment in functioning.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>3 Mild</b></div>
          <div className="md-8">&nbsp;
            While in a clear state of consciousness, hears a voice calling the subject’s name, experiences
            non-verbal auditory hallucinations (e.g., sounds or whispers), formless visual hallucinations, or
            has sensory experiences in the presence of a modality-relevant stimulus (e.g., visual illusions)
            infrequently (e.g., 1-2 times per week) and with no functional impairment.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>4 Moderate</b></div>
          <div className="md-8">&nbsp;
            Occasional verbal, visual, gustatory, olfactory, or tactile hallucinations with no functional
            impairment OR non-verbal auditory hallucinations/visual illusions more than infrequently or with
            impairment.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>5 Moderately Severe</b></div>
          <div className="md-8">&nbsp;
            Experiences daily hallucinations OR some areas of functioning are disrupted by hallucinations.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>6 Severe</b></div>
          <div className="md-8">&nbsp;
            Experiences verbal or visual hallucinations several times a day OR many areas of functioning are
            disrupted by these hallucinations.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>7 Extremely Severe</b></div>
          <div className="md-8">&nbsp;
            Persistent verbal or visual hallucinations throughout the day OR most areas of functioning are
            disrupted by these hallucinations.
          </div>
        </div>
      </div>
      <div className="mt-4">
        <b>4. CONCEPTUAL DISORGANIZATION: </b> &nbsp;
        Degree to which speech is confused, disconnected, vague or
        disorganized. Rate tangentiality, circumstantiality, sudden topic shifts, incoherence, derailment,
        blocking, neologisms, and other speech disorders. Do not rate content of speech.

        <br />
      </div>
      <div className="mt-2 px-5">

        <div className="row md-12">
          <div className="md-4"><b>1 Not Present</b></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>2 Very Mild</b></div>
          <div className="md-8">&nbsp;
            Peculiar use of words or rambling but speech is comprehensible.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>3 Mild</b></div>
          <div className="md-8">&nbsp;
            Speech a bit hard to understand or make sense of due to tangentiality, circumstantiality, or
            sudden topic shifts.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>4 Moderate</b></div>
          <div className="md-8">&nbsp;
            Speech difficult to understand due to tangentiality, circumstantiality, idiosyncratic speech, or
            topic shifts on many occasions OR 1-2 instances of incoherent phrases.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>5 Moderately Severe</b></div>
          <div className="md-8">&nbsp;
            Speech difficult to understand due to circumstantiality, tangentiality, neologisms, blocking, or
            topic shifts most of the time OR 3-5 instances of incoherent phrases.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>6 Severe</b></div>
          <div className="md-8">&nbsp;
            Speech is incomprehensible due to severe impairments most of the time. Many PSRS items
            cannot be rated by self-report alone.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>7 Extremely Severe</b></div>
          <div className="md-8">&nbsp;
            Speech is incomprehensible throughout interview.
          </div>
        </div>
      </div>

      
      {
        spsrsbns_Ns_Addon.length > 0 &&
        <NichqHeader

          title={""}
          disabled={response && response.length > 0}
          subTitle={''}
          listItems={spsrsbns_Ns_Addon}
          setListItems={setSpsrsbns_Ns_Addon}
        />
      }




      <div className="mt-4 head-4">
        BRIEF NEGATIVE SYMPTOM ASSESSMENT SCALE
        <br />
        (version 5.0)
      </div>
      <div>

        Items adapted from NSA and SANS
      </div>

      <div className="mt-4">
        <b>1. PROLONGED TIME TO RESPOND (a measure of Alogia) : </b> &nbsp;
        Observed throughout communication with
        the patient. After asking the patient a question, he or she pauses for inappropriately long periods
        before initiating a response. Delay is considered a pause if it feels as though you are waiting for a
        response or if you consider repeating the question because it appears that the patient has not heard
        you. He or she may seem "distant," and sometimes the examiner may wonder if he has even heard
        the question. Prompting usually indicates that the patient is aware of the question, but has been
        having difficulty in developing his thoughts in order to make an appropriate reply. Rate severity on the
        frequency of these pauses.
      </div>
      <div className="mt-2 px-5">
        <div className="row md-12">
          <div className="md-4"><b>1 Normal</b></div>
          <div className="md-8">&nbsp;
            No abnormal pauses before speaking.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>2 Minimal</b></div>
          <div className="md-8">&nbsp;
            Minimal evidence of inappropriate pauses (brief but not abnormally lengthy pauses
            occur) may be extreme of normal
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>3 Mild</b></div>
          <div className="md-8">&nbsp;
            Occasional noticeable pauses before answering questions. Due to the length of the
            pause, you feel the need to repeat yourself once or twice during the interview.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>4 Moderate</b></div>
          <div className="md-8">&nbsp;
            Distinct pauses occur frequently (20-40% of responses).
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>5 Marked</b></div>
          <div className="md-8">&nbsp;
            Distinct pauses occur most of the time (40-80% of responses).
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>6 Severe</b></div>
          <div className="md-8">&nbsp;
            Distinct pauses occur with almost every response (80-100% of responses).
          </div>
        </div>
      </div>
      <div className="mt-4">
        <b>2. EMOTION: UNCHANGING FACIAL EXPRESSION; BLANK, EXPRESSIONLESS FACE (a measure
          of Flat Affect):</b> &nbsp;
        The patient’s face appears wooden, mechanical, frozen. Facial musculature is
        generally expressionless and unchanging. The patient does not change expression, or change is less
        than normally expected, as the emotional content of discourse changes. Because of this, emotions
        may be difficult to infer. Disregard changes in facial expression due to abnormal involuntary
        movements, such as tics and tardive dyskinesia. The two dimensions of importance when making this
        rating are degree of emotional expression and spontaneity.

        <br />



      </div>
      <div className="mt-2 px-5">
        <div className="row md-12">
          <div className="md-4"><b>1 Normal</b></div>
          <div className="md-8">&nbsp;
            Spontaneous displays of emotion occur when expected. Normal degree of expressiveness of
            emotions is present.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>2 Minimal</b></div>
          <div className="md-8">&nbsp;
            Spontaneous expressions of emotion occur when expected. However, there is a reduction in
            degree or intensity of the emotions expressed. May be extreme of normal.

          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>3 Mild</b></div>
          <div className="md-8">&nbsp;
            Spontaneous expressions of emotion occur infrequently. When emotions are expressed, there
            is a reduction in degree or intensity displayed.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>4 Moderate</b></div>
          <div className="md-8">&nbsp;
            Obvious reduction in spontaneous expressions. Spontaneous expressions of emotion may
            occur very rarely during interaction and only when discussing topics of special interest or humor
            to the subject.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>5 Marked</b></div>
          <div className="md-8">&nbsp;
            Facial expression is markedly decreased. There are no spontaneous expressions of emotion
            unless prompted or coaxed by the interviewer.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>6 Severe</b></div>
          <div className="md-8">&nbsp;
            There are no expressions of emotion even when attempts are made to elicit an emotional
            response. The subject’s face remains blank throughout the interview.
          </div>
        </div>
      </div>

      <div className="mt-4">
        <b>3. REDUCED SOCIAL DRIVE. (a measure of Asociality): </b> &nbsp;

        This item assesses how much the subject desires
        to initiate social interactions. Desire may be measured in part by the number of actual or attempted social
        contacts with others. If the patient has frequent contact with someone (e.g., family member) who initiates the
        contact, does the patient appear to desire the contact (i.e., would he or she initiate contact if necessary?)? In
        making this rating, probe the desire to initiate social interactions, number of social interactions, and the ability
        to enjoy them.
        <br />
        <i>Assessed by asking the patient questions like: </i> <br />
        <i>How have you spent your time in the past week? </i> <br />
        <i>Do you live alone or with someone else?</i> <br />
        <i>Do you like to be around people?</i> <br />
        <i>Do you spend much time with others?</i> <br />
        <i>Do you have difficulty feeling close to others?</i> <br />
        <i>Who are your friends?</i> <br />
        <i>How often do you see them?</i> <br />
        <i>Did you see them this past week?</i> <br />
        <i>Have you called them on the phone?</i> <br />
        <i>When you get together, who decides what to do and where to go?</i> <br />
        <i>When you spend time with others, do you ask them to do something with you or do you wait until they ask you to do something?</i> <br />
        <i>Is anyone concerned about your happiness or well being?</i>


      </div>
      <div className="mt-2 px-5">
        <div className="row md-12">
          <div className="md-4"><b>1 Normal</b></div>
          <div className="md-8">&nbsp;
            Normal desire to initiate and normal number of contacts. Social contacts are enjoyable.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>2 Minimal</b></div>
          <div className="md-8">&nbsp;
            Minimal reduction in either the desire to initiate social contacts or the number of social
            relationships. May initially seem guarded, but has the ability to establish relationships over time.
            Social relationships are enjoyable.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>3 Mild</b></div>
          <div className="md-8">&nbsp;
            Reduction in desire to initiate social contacts. The patient has few social relationships and
            these social contacts are enjoyable.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>4 Moderate</b></div>
          <div className="md-8">&nbsp;
            Obvious reduction in the desire to initiate social contacts. The patient has few relationships
            toward which he or she feels indifference. However, a number of social contacts are initiated
            each week.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>5 Marked</b></div>
          <div className="md-8">&nbsp;
            Marked reduction in desire to initiate social contacts. The patient has very few relationships
            toward which he or she feels indifference. The patient does not initiate social contacts but may
            maintain a few contacts (such as with family).
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>6 Severe</b></div>
          <div className="md-8">&nbsp;
            Patient does not desire social contact. Actively avoids social interactions.
          </div>
        </div>
      </div>
      <div className="mt-4">
        <b>4. GROOMING AND HYGIENE (a measure of Amotivation): </b> &nbsp;

        Observed during interaction with the patient.
        The patient displays less attention to grooming and hygiene than normal. The patient presents with
        poorly groomed hair, disheveled clothing, etc. Do not rate grooming as poor if it is simply done in what
        one might consider poor taste (e.g., wild hairdo or excessive makeup). In addition to observation, one
        must ask the patient about regularity of bathing, brushing teeth, changing clothes, etc. This is
        particularly important with outpatients, as the patient may present his or her best grooming and hygiene
        at their clinic visit. Two dimensions
        <br />
        <i>Assess the patient by asking questions like: </i> <br />
        <i>How many times in the past week have you taken a shower or bath? </i> <br />
        <i>How often do you change your clothes? </i> <br />
        <i>How often do you shower and brush your teeth? </i> <br />



      </div>
      <div className="mt-2 px-5">
        <div className="row md-12">
          <div className="md-4"><b>1 Normal</b></div>
          <div className="md-8">&nbsp;
            Patient is clean (e.g., showers every day) and dressed neatly.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>2 Minimal</b></div>
          <div className="md-8">&nbsp;
            Minimal reduction in grooming and hygiene, may be at the extreme end of the normal range.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>3 Mild</b></div>
          <div className="md-8">&nbsp;
            Apparently clean but untidy appearance. Clothing may be mismatched. Patient may
            shower less often than every other day, or may brush teeth less than everyday.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>4 Moderate</b></div>
          <div className="md-8">&nbsp;
            There is an obvious reduction in grooming and hygiene. Clothes may appear unkempt, rumpled,
            or the patient may look as if he or she just got out of bed. The patient may go without showering
            or bathing for two days at a time. The patient may go for two days without brushing his/her
            teeth.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>5 Marked</b></div>
          <div className="md-8">&nbsp;
            There is a marked reduction in grooming and hygiene. Clothing may appear dirty, stained, or
            very unkempt. The subject may have greasy hair or a body odor. The patient may go 3 days at
            a time without showering or 3 or 4 days without brushing his/her teeth.
          </div>
        </div>
        <div className="row md-12">
          <div className="md-4"><b>6 Severe</b></div>
          <div className="md-8">&nbsp;
            Clothing is badly soiled. Patient has a foul odor. Patient may go more than 4 days in a row
            without showering or more than 4 days in a row without brushing his/her teeth. Poor hygiene
            may present a health risk.
          </div>
        </div>
      </div>



      <div className="mt-4 head-4">
        SCORE SHEET for
        <br />
        4-ITEM POSITIVE SYMPTOM RATING SCALE AND
        <br />
        BRIEF NEGATIVE SYMPTOM ASSESSMENT
      </div>

      



      <div className="mt-4">
        <b><u>4-Item Positive Symptom Rating Scale</u></b> <br />

        Use each item's anchor points to rate the patient.
      </div>


      {
        spsrsbns_Ps.length > 0 &&
        <Spsrsbns_Ps_QueSet

          subTitle={''}
          listItems={spsrsbns_Ps}
          setListItems={setSpsrsbns_Ps}
        />
      }
      <div>
        <b>* NA - not able to be assessed</b> <br />
      </div>



      <div className="mt-4">
        <b><u>4-Item Negative Symptom Rating Scale</u></b> <br />

        Use each item's anchor points to rate the patient.
      </div>

      {
        spsrsbns_Ns.length > 0 &&
        <Spsrsbns_Ns_QueSet

          subTitle={''}
          listItems={spsrsbns_Ns}
          setListItems={setSpsrsbns_Ns}
        />
      }
      {
        spsrsbns_Ps_Addon.length > 0 &&
        <NichqHeader

          title={""}
          disabled={response && response.length > 0}
          subTitle={''}
          listItems={spsrsbns_Ps_Addon}
          setListItems={setSpsrsbns_Ps_Addon}
        />
      }


      <p className="f-12 mt-3">
        The 4-item PSRS was adapted from the Expanded Version of the BPRS developed by:
        <br />
        Ventura, J.; Lukoff, D.; Nuechterlein, K.H.; Liberman, R.P.; Green, M.F.; and Shaner, A. Manual for the expanded Brief Psychiatric Rating
        Scale. International Journal of Methods Psychiatry Research, 3:227-244, 1993


      </p>
      <p className="f-12 mt-3">
        <b>
          The Brief Negative Symptom Assessment was adapted from the Negative Symptom Assessment and the Scale for the Assessment of
          Negative Symptoms developed respectively by:
        </b>
        <br />
        Alphs and Summerfelt. The Negative Symptom Assessment: A new instrument to assess negative symptoms of schizophrenia. Psychopharmacology
        Bulletin, 1989. 25(2): p. 159-163.
        <br />
        Andreason, N. Modified scale for the assessment of negative symptoms. NIMH treatment strategies in schizophrenia study. Public Health Administration
        U.S. Department of Health and Human Services, 1984. ADM (9/85): p. 9-102.


      </p>




      {state == null && !isPrint ? (
        <div className="text-start mt-3 col-12">
          <button onClick={handleSubmit} className="submit_phq ">
            Submit
          </button>
        </div>
      ) : (
        ""
      )}
    </div>

  )

} 