
import { CircularProgress } from '@mui/material';
import './loader.css';

function ProgressLoading({message}) {
    return (
        <div className="loader">
          
       
        <div className="custom-loading-color" 
        style={{position:"absolute",top:"350px", left:"700px" , color:"#5951e5"}}>
          <div className='center' style={{flexDirection:'column'}}>
          <CircularProgress></CircularProgress>
          <div style={{marginTop:2}} > {message}</div> 
          </div>
       
      </div>
      </div>
    )
}
export default ProgressLoading;



