import React, { useState, useEffect } from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {  Skeleton } from "@mui/material";
export const TableRowsLoader = ({ rowsNum }) => {

    return <div>
{
     [...Array(rowsNum)].map((row, index) => (
        <TableRow key={index}>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell component="th" scope="row" width={150}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
        </TableRow>
     ))
}
    </div>
    
  };