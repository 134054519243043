import React from "react";
import { useLocation } from "react-router";
import MultiClientAuthForm from "src/app-modules/client/client-profile/authorization/auth-multiple-client";
import CommonAuthorizationForm from "src/app-modules/client/client-profile/authorization/common-authorization-form";

const AddAuthrization = () => {
  const { state } = useLocation();
  return (
    <div className="d-flex flex-wrap">
      {
        state? <CommonAuthorizationForm/>:
        <MultiClientAuthForm/>
      }
     
    </div>
  );
};

export default AddAuthrization;
