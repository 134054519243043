import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const CommonActionDialog = (props: CommonActionDialogProps) => {
  const {
    title,
    message,
    onClose,
    handleOperation,
    btn1Text,
    btn2Text,
    className,
    ...rest
  } = props;

  const dialogClassName = `xs-modal small_modal blue_theme actionWidth  ${className || ''}`.trim();

  return (
    
    <Dialog
      className={dialogClassName} 
      onClose={onClose} title={title}>
      <p
        style={{
          margin: "25px",
        }}
      >
        {message}
      </p>
      <DialogActionsBar>
      
          <button
            className="submitButon"
            onClick={handleOperation}
          >
            {btn1Text}
          </button>
       
        <button
          className=" cancelBtn "
          onClick={onClose}
        >
         {btn2Text}
        </button>

      </DialogActionsBar>
    </Dialog>
  )
}

export default CommonActionDialog

export interface CommonActionDialogProps {
  title: string
  message: String
  btn1Text: String
  btn2Text: String
  onClose?: () => void | number;
  handleOperation?: () => void;
  className?: string; 
  
}