import ApiHelper from "../helper/api-helper";
import moment from 'moment';
import { Encrption } from '../app-modules/encrption';
import { API_ENDPOINTS } from "./api-endpoints";

const getStaffsDDL = (isActive = true) => {
  return new Promise((resolve, reject) => {
    ApiHelper.getRequest(API_ENDPOINTS.GET_STAFF_DDL_BY_CLINIC_ID+"true", null, true)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        //TODO://Implement error at common place in API Helper along with loader at common place
        reject(error);
      });
  });
};

const getGender = () => {
  return ApiHelper.getRequest(API_ENDPOINTS.GET_GENDER);
};

const getState = () => {
  return ApiHelper.getRequest(API_ENDPOINTS.GET_STATE)
}
const saveStaff = (fields, clinicId, positionEffectiveDate, dob) => {
  const newPositionEffectiveDate = new Date(positionEffectiveDate)
  const newDob = new Date(dob)


  var data = {
    firstName: fields.firstName.trim(),
    lastName: fields.lastName.trim(),
    email: fields.email.trim(),
    userName: fields.userName.trim(),
    mobile: fields.mobile.replace(/\D/g, ""),
    genderId: fields.gender.id,
    positionEffectiveDate: moment(newPositionEffectiveDate).format("YYYY-MM-DD"),
    roleId: fields?.roleId.id ? fields?.roleId.id : 0,
    dob: moment(newDob).format("YYYY-MM-DD"),
    clinicId: clinicId,
    position: fields.position,
    isActive: true,
    sendWelcomeEmail: fields.sendWelcomeEmail
  };
  return ApiHelper.postRequest(API_ENDPOINTS.INSERT_STAFF, data)
};

const getStaffDLL = () => {
  return ApiHelper.getRequest(API_ENDPOINTS.GET_STAFF_DDL_BY_CLINIC_ID+"true")
}

const getStaffTrack = (take, pageSize, finalValue) => {
  var data = {
    "pageNumber": finalValue ? finalValue : 1,
    "pageSize": take == null ? pageSize : take,
  };
  return ApiHelper.postRequest(API_ENDPOINTS.GET_STAFF_TRACK_TIME, data)
};

const insertStaffTrack = (fields) => {
  var data = {
    "time": fields.trackTime,
    "comments": fields.comments,
  };
  return ApiHelper.postRequest(API_ENDPOINTS.INSERT_STAFF_TRACK_TIME, data)
};

const assignTagToStaffDocument = (fields, docId) => {
  let tagIds = [];
  fields.tagName.map(objType => tagIds.push(objType.id))
  var data = {
    "docId": docId,
    "tagIds": tagIds,
  };
  return ApiHelper.putRequest(API_ENDPOINTS.ASSIGN_TAG_TO_STAFF_DOC, data,);
}

const assignTagToClinicDocument = (fields, docId) => {
  let tagIds = [];
  fields.tagName.map(objType => tagIds.push(objType.id))
  var data = {
    "docId": docId,
    "tagIds": tagIds,
  };
  return ApiHelper.putRequest(API_ENDPOINTS.ASSIGN_TAG_TO_CLINIC_DOC, data,);
}

const removeStaffDocTags = (id) => {
  return ApiHelper.deleteRequest(API_ENDPOINTS.REMOVE_STAFF_DOC_TAG + Encrption(id), null, true);
};
const removeClinicDocTags = (id) => {
  return ApiHelper.deleteRequest(API_ENDPOINTS.REMOVE_CLINIC_DOC_TAG + Encrption(id), null, true);
};

const getStaffPlanDocTemplateSettings = (templateId) => {
  const url = `${API_ENDPOINTS.GET_STAFF_PLAN_DOC_TEMPLATE_SETTINGS}?templateId=${templateId}`;
  return ApiHelper.getRequest(url);
}

export const deleteStaffDocTemplate = (templateId) => {
  const url = `${API_ENDPOINTS.DELETE_STAFF_PLAN_TEMPLATE}?templateId=${templateId}`;
  return ApiHelper.deleteRequest(url)
}

export const StaffService = {
  getStaffsDDL,
  getGender,
  getState,
  saveStaff,
  getStaffDLL,
  getStaffTrack,
  assignTagToStaffDocument,
  removeStaffDocTags,
  insertStaffTrack,
  assignTagToClinicDocument,
  removeClinicDocTags,
  getStaffPlanDocTemplateSettings,
  deleteStaffDocTemplate
};