import { useState, useCallback } from "react";

const useBirthDateCalculator = () => {
  const [calculatedAge, setCalculatedAge] = useState(0);

  const handleAge = useCallback((dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setCalculatedAge(age);
  }, []);

  return [calculatedAge, handleAge];
};

export default useBirthDateCalculator;
