import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { clinicServices } from 'src/services/clinicService';
import { getReminderSettings, saveAuditLogs, saveGlobalClinicSites, saveReminderLoadings, saveReminderSettings, saveSitesForClinic } from '../actions';
import { clinicReminderServices } from 'src/services/clinic-reminder-service';
import { NotificationManager } from "react-notifications";
import { docs } from 'src/services/documentService';

export function* getClinicsSitesSaga() {
    yield takeLatest(TYPES.GET_SITES_FOR_CLINIC, getSiteWorker)
}

function* getSiteWorker(param: any) {
    try { 
        const response:any = yield clinicServices.getClinicSites()
        yield put(saveSitesForClinic(response.resultData));
    }
    catch (err) { }
}


export function* getClinicReminderSettingsSaga() {
    yield takeLatest(TYPES.GET_REMINDER_SETTINGS, getSettingWorker)
}

function* getSettingWorker(param: any) {
    try { 
        yield put(saveReminderLoadings(false));
        const response:any = yield clinicReminderServices.getReminderSettings()
        yield put(saveReminderSettings(response.resultData?response.resultData:{}));
        yield put(saveReminderLoadings(true));
    }
    catch (err) { }
}

export function* insertReminderSettingsSaga() {
    yield takeLatest(TYPES.INSERT_REMINDER_SETTINGS, insertSettingWorker)
}

function* insertSettingWorker(param: any) {
    try { 
        const response:any = yield clinicReminderServices.insertSettings(param['payload'])
        if(response.resultData){
            NotificationManager.success('Settings are updated successfully');
            yield put(getReminderSettings());
        }
    }
    catch (err) { }
}

export function* insertReminderEmailSettingsSaga() {
    yield takeLatest(TYPES.INSERT_REMINDER_EMAIL_SETTINGS, insertEmailSettingWorker)
}

function* insertEmailSettingWorker(param: any) {
    try { 
        const response:any = yield clinicReminderServices.insertEmailSettings(param['payload'])
        if(response.resultData){
            NotificationManager.success('Email Reminder Settings are updated successfully');
            yield put(getReminderSettings());
        }
    }
    catch (err) { }
}

export function* insertPhoneSettingSaga() {
    yield takeLatest(TYPES.INSERT_REMINDER_PHONE_SETTINGS, phoneWorker)
}

function* phoneWorker(param: any) {
    try { 
        const response:any = yield clinicReminderServices.insertPhoneSettings(param['payload'])
        if(response.resultData){
            NotificationManager.success('Phone Reminder Settings are updated successfully');
            yield put(getReminderSettings());
        }
    }
    catch (err) { }
}

export function* insertTextSettingSaga() {
    yield takeLatest(TYPES.INSERT_REMINDER_TEXT_SETTINGS, insertTextSettingWorker)
}

function* insertTextSettingWorker(param: any) {
    try { 
        const response:any = yield clinicReminderServices.insertTextSettings(param['payload'])
        if(response.resultData){
            NotificationManager.success('Text Reminder Settings are updated successfully');
            yield put(getReminderSettings());
        }
    }
    catch (err) { }
}



export function* getAuditLogSaga() {
    yield takeLatest(TYPES.GET_AUDIT_LOGS, auditLogWorker)
}

function* auditLogWorker(param: any) {
    try { 
        const response:any = yield clinicReminderServices.getAuditLog(param['payload'])
        if(response.resultData){
            yield put(saveAuditLogs(response.resultData));
        }
    }
    catch (err) { }
}


export function* getGlobalClinicsSitesSaga() {
    yield takeLatest(TYPES.GET_GLOBAL_SITES_FOR_CLINIC, getGlobalSiteWorker)
}

function* getGlobalSiteWorker(param: any) {
    try { 
        const response:any = yield docs.siteList()
        yield put(saveGlobalClinicSites(response.resultData));
    }
    catch (err) { }
}
