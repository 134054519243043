import React, { ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { staffChartModel } from "src/dataModels/docListModel";
import { totalHoursAndMinutes } from "src/util/utility";
import Loader from "../../../control-components/loader/loader";
import { userIsSuperAdmin,userIsAdmin } from "src/helper/permission-helper";
import { ListView, ListViewHeader } from "@progress/kendo-react-listview";
import CustomSkeleton from "src/control-components/skeleton/skeleton";

const StaffDocumentsChart = () => {
  const state: ComponentState = useSelector((states) => {
    return states;
  });
  const staffLoginInfo = useSelector(
    (state: ComponentState) => state.getStaffReducer
  );
  const isBillingManager = state.getRolePermission.isBillingManager;
  const isHumanResourcesManager = useSelector((state:any) => state.getRolePermission?.isHumanResourcesManager);
  const [chartData, setChartData] = useState<staffChartModel[]>([]);
  const { documentStaffChartListResp } = state["DocumentReducer"];
  const userIsSA = userIsSuperAdmin(staffLoginInfo?.roleId);
  const userIsA = userIsAdmin(staffLoginInfo?.roleId);
  const showBillingAmounts = state.getRolePermission.showBillingAmounts;
  const canShowBillData = isBillingManager || userIsSA || showBillingAmounts;
  const canShowTime = isBillingManager || userIsSA || showBillingAmounts || isHumanResourcesManager || userIsA;
  const [isShowItems, setShowItems] = useState(false);

  useEffect(() => {
    setChartData([])
    if (documentStaffChartListResp && documentStaffChartListResp?.resultData) {
      setShowItems(true);
      // Sort the data by 'totDocs' in descending order
      const sortedData = documentStaffChartListResp?.resultData
        ?.slice()
        .sort((a, b) => b.totDocs - a.totDocs);
      sortedData?.map((item) => {
        const minutes = totalHoursAndMinutes(item?.totalMinutes);
        const totalbillValue = Number(item?.totalBilled).toFixed(2);
        const formattedTotalBill = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Number(totalbillValue));
        const label = `Docs: ${item?.totDocs}, ${canShowTime ? "Time:" + " " + minutes : ""} , ${canShowBillData ? ", Billed Amt:" + " " + formattedTotalBill : "" }`;
        var obj: staffChartModel = {
          staffName: item?.staffName,
          documents: item?.totDocs,
          time: canShowTime ? minutes : canShowTime,
          info: label,
          billingAmount: canShowBillData ? formattedTotalBill : "",
        };
        setChartData((oldItem) => [...oldItem, obj]);
      });
    }
  }, [documentStaffChartListResp]);

  // Find the staff member with the most documents
  const mostDocumentsStaff = chartData[0];
  const mostDocumentsCount = mostDocumentsStaff
    ? mostDocumentsStaff?.documents
    : 0;

  const MyHeader = () => {
    return (
      <ListViewHeader
        style={{
          color: "#000000",
          fontSize: 30,
        }}
        className="px-4 py-2 "
      >
        <div className="row border-bottom align-middle">
          <div className="col-3">
            <h2 className="f-14">Staff</h2>
          </div>
          <div className="col-3">
            <h2 className="f-14"># of Docs</h2>
          </div>
          <div className="col-3">
            <h2 className="f-14">Time</h2>
          </div>
          <div className="col-3">
            <h2 className="f-14 ">Billing Charges</h2>
          </div>
        </div>
      </ListViewHeader>
    );
  };
  const MyItemRender = (props) => {
    let item = props.dataItem;
    return (
      <div
        className="row px-2 py-2 border-bottom align-middle"
        style={{ margin: 0 }}
      >
        <div className="col-3">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.staffName}</div>
          )}
        </div>
        <div className="col-3">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.documents}</div>
          )}
        </div>
        <div className="col-3">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.time}</div>
          )}
        </div>
        <div className="col-3">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.billingAmount}</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {documentStaffChartListResp?.resultData?.length != 0 ? (
        <div className="grid-table filter-grid dash-filter-grid my-3 caseload-custom gridheight casload boxHeight traningList">
          <h5>
            {" "}
            Staff with most documents
          </h5>
          <ListView
            className="traningList"
            data={chartData}
            item={MyItemRender}
            style={{
              width: "100%",
              display: "block",
            }}
            header={MyHeader}
          />
        </div>
      ) : (
        <p>No Record found</p>
      )}
    </>
  );
};

export default StaffDocumentsChart;