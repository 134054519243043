import React, { ComponentElement, ComponentState, useEffect, useState } from "react";



export const NichqComponent1 = ({
    listItems,
    setListItems,
    subTitle
}) => {

    const [totalSum, setTotalSum] = useState(0);
    const [opList, setOpList] = useState<any[]>([])
    const [isListSetup, setIsListSetup] = useState(false)

    useEffect(() => {

        if (!isListSetup) {
            setOpList(listItems)
            setIsListSetup(true)
        }
    }, [isListSetup])


    useEffect(() => {

        if (opList && opList.length > 0) {
            setTotalSum(opList?.reduce(
                (sum, item) => sum + (Number(item.value) || 0),
                0
            ))
            setListItems(opList);
        }
    }, [opList])



    const handleRadioChange = (itemId, value) => {
        // Find the item in the items array

        const newData = opList.map((item, i) =>
            item.id === itemId
                ? {
                    ...item,
                    value: value
                }
                : item
        );
        setOpList(newData)
    }


    return (
        <div className="ACE_main_table mt-4">
            <div>
                <div className="head-6 pb-2">{subTitle}</div>
                {/* <label className="pb-3 mt-2">
                    {subTitle}
                </label> */}
            </div>
            <div className="row border-bottom mb-3">
                <div className="col-12">
                    <label className="fw-400">
                        NOTE: 0 = Never; 1 = Occasionally; 2 = Often; 3 =  Very Often
                    </label>
                </div>
            </div>
            {opList.map((item) => (

                <div key={item.id} className="row mb-3 pb-2 border-bottom">
                    <div className="col-12">
                        <div key={item.id}>
                            <label>
                                {item.id}. {item.name}
                            </label>
                        </div>
                    </div>
                    <div className="col-12  select_table_phq cus-select-table-drops">
                        {[0, 1, 2, 3].map((value) => (
                            <label key={value} className="cats_label">
                            <input
                              type="radio"
                              value={value}
                              checked={item.value == value}
                              onChange={() => handleRadioChange(item.id, value)}
                              className="mr-2 mb-0 ml-2"
                            />
                            <span>{value}</span>
                          </label>
                        ))}
                    </div>
                </div>
            ))}
            <div className="text-start justify-content-start total_phq col-12">
                <p>Total Score: </p>
                <p className="submit_phq mb-0"> {totalSum}</p>
            </div>
        </div>
    );
};


