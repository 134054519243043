// import React  from "react";
// import { Skeleton, SkeletonShape } from "@progress/kendo-react-indicators";




// export interface SkeletonProps{
//   shape:SkeletonShape,
//   width?:number,
//   height?:number,
//   marginRight?:number
// }

// const CustomSkeleton=(props:SkeletonProps)=>{
//    const {shape,
//     width,
//     height,
//     marginRight,
//     ...reset
//   }=props;

//   const shapeParam:SkeletonShape=!shape ? "text" : shape;
//     return(
//         <Skeleton
//                   shape={shapeParam}
//                   // style={{
//                   //   width: {width},
//                   //   height: {height},
//                   //   marginRight: {marginRight},
//                   // }}
//                 />
//     )
// }

// export default CustomSkeleton;


import React from "react";
import { Skeleton, SkeletonShape } from "@progress/kendo-react-indicators";

export interface SkeletonProps {
  shape: SkeletonShape;
  width?: number;
  height?: number;
  marginRight?: number;
  borderRadius?: number; 
  backgroundColor?: string;
}

const CustomSkeleton = (props: SkeletonProps) => {
  const {
    shape,
    width,
    height,
    marginRight,
    borderRadius = 8, 
    backgroundColor = "#f0f0f0",
    ...rest
  } = props;

  const shapeParam: SkeletonShape = !shape ? "text" : shape;

  return (
    <Skeleton
      shape={shapeParam}
      style={{
        width: width ? `${width}px` : '100%',
        height: height ? `${height}px` : '1.5em',
        marginRight: marginRight ? `${marginRight}px` : '0',
        borderRadius: `${borderRadius}px`,
        backgroundColor, 
        animation: 'loading-animation 1.5s infinite ease-in-out',
      }}
      {...rest}
    />
  );
};

export default CustomSkeleton;
