import React, { useState } from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import ApiHelper from "../../helper/api-helper";
import { Encrption } from "../encrption";
import DeleteDialogModal from "../../control-components/custom-delete-dialog-box/delete-dialog";
import { renderErrors } from "src/helper/error-message-helper";
import { API_ENDPOINTS } from "src/services/api-endpoints";

const DeleteBillingComment = ({
  onClose,
  fetchBillingComments,
  selectedDeleteId
}) => {
  const [loading, setLoading] = useState(false);

  const deleteBillingComment = async () => {
    setLoading(true);
    await ApiHelper.deleteRequest(
      API_ENDPOINTS.DELETE_BILLING_COMMENT + Encrption(selectedDeleteId)
    )
      .then((result) => {
        setLoading(false);
        NotificationManager.success("Delete Billing Comment  Successfully");
        fetchBillingComments()
        onClose();
      })
      .catch((error) => {
        renderErrors(error);

        setLoading(false);
      });
  };
  return (
    <>
   <DeleteDialogModal
              onClose={onClose}
              title="Billing Comment"
              message="billing comment"
              handleDelete={deleteBillingComment}
              />
    </>
  );
};
export default DeleteBillingComment;
