import React, { ComponentState, useEffect, useState } from "react";
import moment from "moment";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from "react-redux";
import DatePickerKendoRct from "../../control-components/date-picker/date-picker";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import ErrorHelper from "../../helper/error-helper";
import { getTimeClockById, updateStaffTimeClockById } from "src/redux/actions";
import { Error } from "@progress/kendo-react-labels";
import DateTimeHelper from "src/helper/date-time-helper";
import { displayDate } from "src/util/utility";
import calenderIcon from "../../assets/images/calendar-03.png"
import clockIcon from "../../assets/images/clock-01.png"

const UpdateTimeClockModel = ({ onClose, selectedClickId }) => {
    const dispatch = useDispatch();
    const state: ComponentState = useSelector(states => {
        return states;
    });
    const { timeClockById } = state['DashboardReducer']
    const [errors, setErrors] = useState<any>("");
    const [isAccept, setIsAccept] = useState(false)
    const [fields, setFields] = useState<any>({
        clockInDate: "",
        clockInTime: "",
        clockOutDate: "",
        clockOutTime: ""
    });



    useEffect(() => {
        if (!isAccept) {
            dispatch(getTimeClockById(selectedClickId))
            setIsAccept(true)
        }

    }, [])

    useEffect(() => {
        if (timeClockById) {

            if (timeClockById) {
                const clockInDateTime = moment.utc(timeClockById.clockIn).local()
                setFields(prevFields => ({
                    ...prevFields,
                    clockInDate: timeClockById?.clockIn === null ? new Date() : clockInDateTime.format("YYYY-MM-DD"),
                    clockInTime: timeClockById?.clockIn === null
                        ? new Date()
                        : new Date(
                            displayDate(new Date(), "MM/DD/yyyy") +
                            " " +
                            clockInDateTime.format("HH:mm")
                        ),
                }));
            }

            // Format and set clockOutDate and clockOutTime
            if (timeClockById) {
                const clockOutDateTime =  moment.utc(timeClockById.clockOut).local();
                setFields(prevFields => ({

                    ...prevFields,
                    clockOutDate: timeClockById?.clockOut === null ? new Date() : clockOutDateTime.format("YYYY-MM-DD"),
                    clockOutTime: timeClockById?.clockOut === null
                        ? new Date()
                        : new Date(
                            displayDate(new Date(), "MM/DD/yyyy") +
                            " " +
                            clockOutDateTime.format("HH:mm")
                        ),

                }));
            }
        }
    }, [timeClockById])




    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (!fields.clockInDate || fields.clockInDate.length < 1) {
            formIsValid = false;
            errors["clockInDate"] = ErrorHelper.FIELD_BLANK;
        }
        if (!fields.clockOutDate || fields.clockOutDate.length < 1) {
            formIsValid = false;
            errors["clockOutDate"] = ErrorHelper.FIELD_BLANK;
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleChange = (e?: any) => {
        setErrors("")
        const name = e.target.name;
        const value = e.target.value;
        setFields({
            ...fields,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        // if (handleValidation()) {
        const inClockTime = DateTimeHelper.concatDateTimeOfTwoDates(
            fields.clockInDate,
            fields.clockInTime
        );
        const  clockInTime= DateTimeHelper.localDateToUtc(inClockTime)
        const outClockTime = DateTimeHelper.concatDateTimeOfTwoDates(
            fields.clockOutDate,
            fields.clockOutTime
        );
        const  clockOutTime= DateTimeHelper.localDateToUtc(outClockTime)
        if(clockInTime.isAfter(clockOutTime)){
            let errors = {};
            errors["clockOutDate"] = ErrorHelper.FIELD_DATE_VALID;
            setErrors(errors);
            return;
        }
        const data = {
            id: selectedClickId,
            clockInDateTime: moment(clockInTime).format('YYYY-MM-DD HH:mm:ss'),
            clockOutDateTime: moment(clockOutTime).format('YYYY-MM-DD HH:mm:ss')
        }
        dispatch(updateStaffTimeClockById(data))
        onClose()
        // }
    };

    const handleCancel = () => {
        onClose();
    };
    return (
        <Dialog onClose={onClose} title={"Update Clock Time"} className="small-dailog medium-modal width_90 mediumHeight blue_theme"
            width={"50%"}
            height={"50%"}>
            <div className=" my-2 mx-2 row">
                <div className="mb-3 col-lg-6 col-md-6 col-12 ">
                    <DatePickerKendoRct
                        onChange={handleChange}
                        placeholder=""
                        // format={"MM/dd/YYYY"}
                        value={new Date(fields?.clockInDate)}
                        name={"clockInDate"}
                        title={"Clock In Date"}
                        label={"Clock In Date"}
                        error={!fields?.clockInDate && errors.clockInDate}
                        required={true}
                        validityStyles={!!errors.clockInDate}
                    />
                     <img src={calenderIcon} className="blueThemeIcons" alt="calender"/>
                </div>
                <div className="mb-2 col-lg-6 col-md-6 position-relative">
                    <TimePicker
                        name={"clockInTime"}
                        fillMode={"solid"}
                        size={"medium"}
                        title={"Clock In Time"}
                        label={"Clock In Time"}
                        value={fields?.clockInTime}
                        onChange={handleChange}
                    />
                     <img src={clockIcon} className="blueThemeIcons" alt="time picker"/>
                    {
                        (!fields?.clockInTime && errors.clockInTime) && <Error> {errors.clockInTime}</Error>
                    }
                </div>

                <div className="col-lg-6 col-md-6 col-12 position-relative">
                    <DatePickerKendoRct
                        onChange={handleChange}
                        placeholder=""
                        format={"MM/dd/YYYY"}
                        value={new Date(fields?.clockOutDate)}
                        name={"clockOutDate"}
                        title={"Clock Out Date"}
                        label={"Clock Out Date"}
                        error={errors.clockOutDate}
                        required={true}
                        validityStyles={!!errors.clockOutDate}
                    />
                     <img src={calenderIcon} className="blueThemeIcons" alt="calender"/>
                </div> 
                <div className="mb-2 col-lg-6 col-md-6 position-relative">
                    <TimePicker
                        name={"clockOutTime"}
                        fillMode={"solid"}
                        size={"medium"}
                        title={"Clock Out Time"}
                        label={"Clock Out Time"}
                        value={fields?.clockOutTime}
                        onChange={handleChange}
                    />
                     <img src={clockIcon} className="blueThemeIcons" alt="time picker"/>
                    {
                        (!fields?.clockOutTime && errors.clockOutTime) && <Error> {errors.clockOutTime}</Error>
                    }
                </div>
            </div>
            <div className="preview-btn-bottom">
                <div className="border-bottom-line"></div>
                <div className="d-flex flex-wrap my-3 mr-3">
                    <button className="submitButon " onClick={handleSubmit}>
                        Update
                    </button>
                    <button
                        onClick={handleCancel}
                        className="cancelBtn"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

export default UpdateTimeClockModel;
