import { Dialog } from '@progress/kendo-react-dialogs'
import React, { ComponentState, useEffect, useState } from 'react'
import DropDownKendoRct from 'src/control-components/drop-down/drop-down'
import { CommonService } from 'src/services/commonService'
import { renderErrors } from "src/helper/error-message-helper";
import { useDispatch, useSelector } from 'react-redux'
import { getBillingAction } from 'src/redux/actions'
import { NotificationManager } from "react-notifications";
import NOTIFICATION_MESSAGE from 'src/helper/notification-messages';
import ErrorHelper from "../../../helper/error-helper";
import moment from 'moment'
import { permissionEnum } from 'src/helper/permission-helper'
import { Error } from '@progress/kendo-react-labels'
import TextAreaKendoRct from 'src/control-components/text-area/text-area'
import { TextArea } from '@progress/kendo-react-inputs';

const DocumentActionModel = ({ userIsSuperAdmin, onClose, checkedDocuments, fetchDocuments, preSelectedStatus, resetCheckedDocuments }) => {
    const state: ComponentState = useSelector(states => {
        return states;
    });
    const { billingStatus } = state['DocumentReducer'];


    const dispatch = useDispatch()
    const [isAccepted, setIsAccepted] = useState<boolean>(false)
    const [settingError, setSettingError] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>("");
    const [field, setField] = useState({
        documentId: [],
        billingAction: "",
        comments: ""
    })

    useEffect(() => {
        if (!isAccepted) {
            dispatch(getBillingAction())
            setIsAccepted(true)
        }
    }, [isAccepted]);

    useEffect(() => {

        const listToBeUpdated = (userIsSuperAdmin || preSelectedStatus && preSelectedStatus != null) ? checkedDocuments :

            checkedDocuments.filter(function (document) {
                return document.billingStatusId !== permissionEnum.DOCUMENT_BILLING_STATUS_PAID
            });
        setField({
            ...field,
            documentId: listToBeUpdated
        })
    }, [checkedDocuments])

    useEffect(() => {

        if (preSelectedStatus && preSelectedStatus != null && billingStatus && billingStatus?.length > 0) {
            setField({
                ...field,
                ['billingAction']: billingStatus.find((statusItem) => statusItem.id === preSelectedStatus.id),
            });
        }
    }, [billingStatus])


    const handleChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setField({
            ...field,
            [name]: value,
        });
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (!field.billingAction) {
            formIsValid = false;
            errors["billingAction"] = ErrorHelper.BILLING_STATUS;
        }
        if (field.documentId.length <= 0) {
            formIsValid = false;
            errors["documentId"] = ErrorHelper.DOCUMENT_ID
        }
        setErrors(errors);
        return formIsValid;
    };

    const handleSubmit = () => {
        setSettingError(true);
        if (handleValidation()) {
            CommonService.updateMultiDocBillingStatus(field.billingAction, field.documentId, field.comments)
                .then((result) => {
                    NotificationManager.success(NOTIFICATION_MESSAGE.SUBMIT_BILLING_ACTION);
                    resetCheckedDocuments()
                    onClose()

                    fetchDocuments(field.billingAction)


                })
                .catch((error) => {
                    renderErrors(error.message);
                });
        }

    }

    return (
        <div>
            <Dialog onClose={onClose} title={"Update Document Billing Status"}
                className="small-dailog flag width_90 SmallHEight blue_theme" width={"40%"} height="80%">
                <div className="edit-client-popup">
                    <div className="popup-modal slibling-data">
                        <div >
                            <div>
                                <DropDownKendoRct
                                    data={billingStatus || []}
                                    onChange={handleChange}
                                    textField="name"
                                    name="billingAction"
                                    value={field?.billingAction}
                                    dataItemKey="id"
                                    label="Select Billing Status"
                                    validityStyles={settingError}
                                    error={!field.billingAction && errors.billingAction}
                                />
                            </div>


                            <div className="row"><div className="mb-2 col-lg-12 col-md-12 col-12">
                                <TextAreaKendoRct
                                    txtValue={field.comments}
                                    onChange={(value) => {
                                        setField({ ...field, comments: value.value })
                                    }}
                                    name="comments"
                                    label="Comment"
                                    rows={0}

                                /></div>
                            </div>
                        </div>
                    </div>

                </div>
                <ul>
                    {checkedDocuments.map((item) => {
                        return <>
                            {
                                (!userIsSuperAdmin && item.billingStatusId === permissionEnum.DOCUMENT_BILLING_STATUS_PAID) ?
                                    <li key={item.id} className="red-label">{item?.documentId} - [{moment(item?.serviceDate).format("M/D/yyyy")} - {item?.clientName}] {`(You're not authorized to change "Paid" status)`}</li>
                                    : <li key={item.id}>{item?.documentId} - [{moment(item?.serviceDate).format("M/D/yyyy")} - {item?.clientName}]</li>
                            }


                        </>
                    })}
                </ul>
                <div className="preview-btn-bottom ">
                    <div className="border-bottom-line"></div>
                    <div className="d-flex my-3 mx-3">
                        <div className="right-sde">
                            <button
                                className="submitButon"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                        <div className="right-sde-grey">
                            <button
                                className="cancelBtn"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default DocumentActionModel