import React, { useEffect, useState } from 'react'
import { Dialog } from "@progress/kendo-react-dialogs";
import { ClientService } from "../../../../services/clientService";
import Loader from "../../../../control-components/loader/loader";
import moment from "moment";

const AuthModel = ({ onClose, selectAuthId }) => {
  const [loading, setLoading] = useState(false);
  const [itemsToShow, setShowMore] = useState(3);
  let [fields, setFields] = useState({
    effectiveDate: "",
    endDate: "",
    numUnits: "",
    // dateAuth: "",
    authStatus: "",
    submittedDate: "",
    submittedBy: "",
    comments: "",
    custAuthId: "",
    authServices: [],
    isEnforceValidation: true,
    freqNumUnits: 0,
    frequency: { key: "Day", value: "Day" },
    clientId: "",
    totalUnitsUsed: "",
    remainingUnits: ""
  });

  useEffect(() => {
    getAuthorizationtById()
  }, [])

  const getAuthorizationtById = async () => {
    setLoading(true);
    await ClientService.getAuthById(selectAuthId)
      .then((result) => {
        setLoading(false);
        let authInfo = result.resultData;
        let services = result.resultData.serviceIds;
        let newData = [];
        if (services.length > 0) {
          for (var i = 0; i < services.length; i++) {
            const element = {
              id: services[i]?.id,
              fullName: services[i]?.name,
            };
            newData.push(element);
          }
        }
        const clientId = {
          id: authInfo?.clientId,
          name: authInfo?.clientName,
        };
        const authStatus = {
          id: authInfo?.authStatusId,
          name: authInfo?.authStatus,
        };
        const authSubmitted = {
          id: authInfo?.staffId,
          name: authInfo?.submittedByStaffName,
        };
        const frequencyVal = {
          key: authInfo?.frequency,
          value: authInfo?.frequency,
        };

        setFields({
          ...fields,
          effectiveDate:
            authInfo?.effectiveDate && new Date(authInfo.effectiveDate),
          endDate: authInfo?.endDate && new Date(authInfo.endDate),
          submittedDate:
            authInfo?.dateSubmitted && new Date(authInfo.dateSubmitted),
          // dateAuth: authInfo?.dateAuth && new Date(authInfo.dateAuth),
          numUnits: authInfo?.numUnits,
          comments: authInfo?.comments,
          custAuthId: authInfo?.custAuthId,
          authStatus: authStatus !== null && authStatus,
          submittedBy: authSubmitted !== null && authSubmitted,
          authServices: newData,
          isEnforceValidation: authInfo?.isEnforceValidation,
          freqNumUnits: authInfo?.freqNumUnits,
          frequency: frequencyVal !== null && frequencyVal,
          clientId: clientId !== null && clientId,
          totalUnitsUsed: authInfo?.totalUnitsUsed,
          remainingUnits: authInfo?.remainingUnits
        });
      })
      .catch((error) => {
        setLoading(false);
        // renderErrors(error.message);
      });
  };
  const initialExpanded = fields?.authServices?.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: true }),
    {}
  );


  const [expandedModule, setExpandedModule] = React.useState({
    ...initialExpanded,
  });
  const showMoreList = (id) => {

    setExpandedModule({ ...expandedModule, [id]: !expandedModule[id] });
  };

  let arry = fields?.authServices.slice(
    0,
    expandedModule[fields?.authServices.id] ? fields?.authServices.length : itemsToShow
  );
  return (
    <>
      <Dialog
        onClose={onClose}
        title={"Auth ID"}
        className="dialog-modal medium-modal education width_90 blue_theme" width={"50%"} height={"80%"}
      >
        {loading && <Loader />}

        {/* <div className="row shadowbox pt-3 border-radis px-3"> */}
        <div className="col-md-12  mb-3">
          <ul className="list-unstyled mb-0 details-info firts-details-border position-relative row">
            <li className="col-md-4 col-sm-6">
              <p className=" mb-3 col-md-6 labelColor">Effective Date</p>
              <p className="col-md-12 fw-600 valueColor">
                {moment(fields.effectiveDate).format("M/D/YYYY")}
              </p>
            </li>
            <li className="col-md-4 col-sm-6">
              <p className="col-md-10 mb-3 labelColor">End Date</p>
              <p className="col-md-12 fw-600 valueColor">{moment(fields.endDate).format("M/D/YYYY")}</p>

              <p></p>
            </li>
            <li className="col-md-4 col-sm-6">
              <p className="col-md-10 mb-3 labelColor">Status</p>
              <p className="col-md-12 fw-600 valueColor">
                {fields?.authStatus?.name}
              </p>
            </li>
            <li className="col-md-4 col-sm-6">
              <p className="col-md-10 mb-3 labelColor">Total Unit</p>
              <p className="col-md-12 fw-600 valueColor">
                {fields?.numUnits}
              </p>
            </li>

            <li className="col-md-4 col-sm-6">
              <p className="col-md-10 mb-3 labelColor">Total Units Used</p>
              <p className="col-md-12 fw-600 valueColor">
                {fields?.totalUnitsUsed}
              </p>
            </li>

            <li className="col-md-4 col-sm-6">
              <p className="col-md-10 mb-3 labelColor">Remaining Units</p>
              <p className="col-md-12 fw-600 valueColor ">
                {fields?.remainingUnits}
              </p>
            </li>
            <li className="col-md-4 col-sm-6">
              <p className="col-md-10 mb-3 labelColor">Auth Id</p>
              <p className="col-md-12 fw-600 valueColor">
                {fields?.custAuthId}
              </p>
            </li>
            <li className="col-md-4 col-sm-6">
              <p className="col-md-10 mb-3 labelColor">Services</p>
              <p className="col-md-12 fw-600 valueColor">
                {arry.length > 0 &&
                  arry.map((obj, i) => (
                    <>
                      <li className="mb-0" key={i} >
                        {obj?.fullName}
                        {fields?.authServices.length > 3 &&
                          arry?.at(-1).fullName === obj?.fullName && (
                            <span
                              className="plus-click  ml-2 cursor-pointer"
                              onClick={() => {
                                showMoreList(fields?.authServices.id);
                              }}

                            >
                              {expandedModule[fields?.authServices.id] ? (
                                <i className="fa fa-minus "></i>
                              ) : (
                                <i className="fa fa-plus"></i>
                              )}
                            </span>

                          )
                        }
                      </li>
                    </>
                  ))}
              </p>
            </li>
          </ul>
        </div>
        {/*End*/}
        {/* </div> */}
      </Dialog>
    </>
  )
}

export default AuthModel