import { SAVE_CLIENT_MESSAGE_LABELS } from "../actions/types";

const initialState = {
  clientMessageLabels: []
};

export const MessageReducer = (state = initialState, action) => {
  const oldState = {...state};
  const {type, payload} = action;
  switch (type) {
    case SAVE_CLIENT_MESSAGE_LABELS:
      return {
        ...oldState,
        clientMessageLabels: payload,
      };
  }
  return oldState;
};
