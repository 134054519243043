import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressLoading from "src/control-components/loader/loader_with_text";
import { saveClinicLogoBytes } from "src/redux/actions";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { getSettings } from "src/services/settingsService";
import { customAxios } from "src/services/useExPressApi";
import DocumentTemplatePdf from "./pdf-doc-temp";

const PrintDocument = () => {

    // Retrieve data from local storage
    const stateData = useSelector(states => {
        return states;
    });
    const clinicId = stateData.loggedIn.clinicId;
    const state = JSON.parse(localStorage.getItem('print-doc-state'));
    const [documentDetailItems, setDocDetailItems] = useState()
    const dispatch = useDispatch();

    useEffect(() => {
        if (!documentDetailItems && state) {
            getDocMetaData()
        }
    }, [documentDetailItems])

    const getDocMetaData = async () => {
        const clinicLogoResp = await getSettings.getClinicLogo(clinicId, true);
        if (clinicLogoResp.status) {
            dispatch(saveClinicLogoBytes(clinicLogoResp.resultData.clinicLogo));
        }

        let DocsArray = []
        state.forEach(element => {
            DocsArray.push(element.documentId)
        });
        const metaResponse = await customAxios.post(`${API_ENDPOINTS.GET_DOC_PRINT_META_DATA}`, DocsArray);
        if (metaResponse && metaResponse.status) {
            setDocDetailItems(metaResponse.resultData)
        }
    }

    if (!documentDetailItems) return <ProgressLoading message={'Please wait while we are collecting information for all selected documents.'}></ProgressLoading>

    if (!state || state.length < 1) return <></>

    return <DocumentTemplatePdf
        documentPrintData={state}
        onlyView={false}
        metaDataDocs={documentDetailItems}
    />
}

export default PrintDocument;