import React, { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import GridLoader from "src/control-components/loader/loader";
import { Grid, GridColumn } from "@progress/kendo-react-grid";


const ViewRejectionErrors = ({ onClose, claimErrors }) => {
    const [loading, setLoading] = useState(false);
    return (
        <Dialog
            onClose={onClose}
            title={"Claim Error Details"}
            className="pagination-row-cus"
            width={"70%"}
            height={"60%"}>
            {loading && <GridLoader />}
            <div className="grid-table table-heading-auth mt-2">
                <Grid
                    data={claimErrors}
                    total={
                        claimErrors && claimErrors.length > 0
                            ? claimErrors.length
                            : 0
                    }
                    className="pagination-row-cus customRowHeight"
                >
                    <GridColumn
                        title="Claim Id"
                        field="masterClaimId"
                        width={100}
                        className="cursor-default"
                        cell={(props) => {
                            let masterClaimId = props.dataItem.masterClaimId;
                            return <td style={{backgroundColor: "#edd1d1"}}>{masterClaimId}</td>;
                        }}
                    />
                    <GridColumn
                        title="Error Code"
                        field="errorCode"
                        width={100}
                        className="cursor-default"
                        cell={(props) => {
                            let errorCode = props.dataItem.errorCode;
                            return <td style={{backgroundColor: "#edd1d1"}}>{errorCode}</td>;
                        }}
                    />
                    <GridColumn
                        title="Error Initiator"
                        field="errorInitiator"
                        className="cursor-default"
                        width={120}
                        cell={(props) => {
                            let errorInitiator = props.dataItem.errorInitiator;
                            return <td style={{backgroundColor: "#edd1d1"}}>{errorInitiator}</td>;
                        }}
                    />
                    <GridColumn
                        title="Error Text"
                        field="errorText"
                        className="cursor-default"
                        cell={(props) => {
                            let errorText = props.dataItem.errorText;
                            return <td style={{backgroundColor: "#edd1d1"}}>{errorText}</td>;
                        }}
                    />
                    <GridColumn
                        title="Loop"
                        field="loop"
                        width={100}
                        className="cursor-default"
                        cell={(props) => {
                            let loop = props.dataItem.loop;
                            return <td style={{backgroundColor: "#edd1d1"}}>{loop}</td>;
                        }}
                    />
                    <GridColumn
                        title="Segment"
                        field="segment"
                        className="cursor-default"
                        width={100}
                        cell={(props) => {
                            let segment = props.dataItem.segment;
                            return <td style={{backgroundColor: "#edd1d1"}}>{segment}</td>;
                        }}
                    />
                </Grid>

            </div>
        </Dialog>
    );
};
export default ViewRejectionErrors;
