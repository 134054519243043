import moment from "moment";
import $ from "jquery";

export function onDataChange(htmlFileName,countDisabledFields=false) {

    var myForm = $("#" + htmlFileName);
    let disabled;
    if(countDisabledFields){
         disabled =   myForm.find(':input:disabled').removeAttr('disabled');
    }
    var data = myForm.serializeArray();
    if(countDisabledFields){
        disabled.attr('disabled','disabled');
    }

    let newArray= [];
    if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
            var c_name = data[i].name;
            var input_type = $("form#" + htmlFileName + " input[name=" + c_name + "]").attr("type");
            const element = {
                keyName: data[i].name,
                keyValue: input_type == "date" ? (moment(data[i].value).format("M/D/YYYY") ? moment(data[i].value).format("M/D/YYYY") : "") : data[i].value
            };
            newArray.push(element);
        }
    }

    return newArray;
}