import { InputChangeEvent, InputProps } from "@progress/kendo-react-inputs";


import React, { Component, useEffect, useState } from "react";
import { Error } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
function InputKendoRctV1(props:InputKendoRctProps) {
  const {
    validityStyles,
    name,
    value,
    onChange,
    label,
    error,
    minLength,
    required,
    maxLength,
    type,
    disabled,
    focusout,
    placeholder,
    pattern,
    width,
    min,
    format,
    autoComplete,
    ref,
    ...rest
  } = props;
  return (
    <div>
      <Input
        style={{ width:width }}
        validityStyles={validityStyles}
        name={name}
        value={value}
        onChange={onChange}
        label={label}
        maxLength={maxLength}
        type={type}
        disabled={disabled}
        minLength={minLength}
        required={required}
        placeholder={placeholder}
        pattern={pattern}
        width={width}
        min={min}
        ref={ref}
        autoComplete={autoComplete?autoComplete:"off"}
      />
      {error && <Error>{error}</Error>}
    </div>
  );
}
export default InputKendoRctV1;

export interface InputKendoRctProps extends InputProps {
    text?: string
    name?: string
    value?: string
    onChange?: (newValue: InputChangeEvent) => void
    label?: string
    error?: any
    minLength?: number
    required?:boolean
    maxLength?:number
    type?: React.HTMLInputTypeAttribute
    disabled?:boolean
    focusout?:any
    placeholder?:string
    pattern?:string
    width?:number
    min?:any
    format?:any
    ref?:any
    autoComplete?:string
  }
  