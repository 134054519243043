export const styles = `
   body, h1, h2, h3, h4, h5, h6 {
        font-family:  'Manrope', sans-serif !important;
      }
      label {
        font-size: 12px !important;
      }
   .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px; /* Adjust the height as needed */
    background-color: #F6F8FA;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure the header is on top of other elements */
}
.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px; /* Adjust the height as needed */
    background-color: #F6F8FA;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure the footer is on top of other elements */
}
   .customCell
   {
    color:Black;
    font-size: 10px !important;
   }
   .customCell-light
   {
    color: #4a4a4b;
    margin-left: 2px;
    font-size: 10px !important;
   }
  .grid-template-pdf-head {
    margin-top: 1rem; /* equivalent to mt-4 */
  }
  
  .client-name {
    color: #000;
    font-size: 11px;
    margin-left: 2px;
  }
  
  .list-unstyled {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }
  
  .additional-info {
    padding-top: 5px;
  }
  
  .client-detail-item {
    color: #000;
  }
  
  .client-detail-label {
    font-size: 10px;
    font-weight: 600;
  }
  
  .client-detail-value {
    color: #4a4a4b;
    margin-left: 2px;
  }
`;