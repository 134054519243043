import { useState } from "react";
import { VideoService } from "src/services/videoCallService";
import { renderErrors } from "src/helper/error-message-helper";
import { NotificationManager } from "react-notifications";
import NOTIFICATION_MESSAGE from "src/helper/notification-messages";

function SendLink({ roomName, clientDetails }) {
    const [isSend, setIsSend] = useState(false);


    // onClick handler function for the Send button
    const handleSendLink = async () => {
        // Asynchronously call SendLink
        await VideoService.sendLinkService(roomName, clientDetails)
            .then((result) => {
                NotificationManager.success(`Telehealth meeting link sent to ${clientDetails?.fName} ${clientDetails?.lName} successfully`);

                setIsSend(true);
                setTimeout(() => {
                    setIsSend(false);
                }, 1000);
            })
            .catch((err) => {
                renderErrors(err);

            });
    }

    return (
        <div>
            {/* Bind our handler function to the onClick button property */}
            <button className="copy-mainbtn mr-1" onClick={handleSendLink} >
            <i className="fa fa-paper-plane " style={{ color: "white", marginRight: "5px" }}></i>  {isSend ? 'Sent' : 'Send Meeting Link'}
                
            </button>
        </div>
    );
}

export default SendLink