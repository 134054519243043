import { AppSuccessResponse } from "src/dataModels/apiSuccessResponse";
import { API_ENDPOINTS } from "./api-endpoints";
import { customAxios } from "./useExPressApi";
import { DocumentListRequestObj, DocumentResponseObj, ChartResponse, DocumentByIdResponse, DocumentBillingHistoryResponse, BillingStatusResponse, DocumentServiceListResponse, UpdateDocumentService, LatestDoucmentByTemplateIdPayload, UpdateDocumentServiceDate, UpdateDocumentDiagnosis, UpdateDocumentSiteOfService } from "src/dataModels/docListModel";
import { ClientResponse, ServiceResponse, SiteResponse, StaffResponse } from "src/dataModels/clientResponse";
import { store } from "src/redux/store/store";
import { changeAppRoute } from "src/redux/actions";
import APP_ROUTES from "src/helper/app-routes";


export const docs = {
  draftCount: (siteID: string) => {
    return customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_DOCUMENT_DRAFT_COUNT}`, { params: { siteID: 0 } });
  },

  deleteDocument: (docId: string) => {
    return customAxios.delete<any, AppSuccessResponse>(`${API_ENDPOINTS.DELETE_DOCUMENT}${docId}`);
  },

  revertDocument: (docId: string) => {
    return customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.UNTRASH_DOCUMENT}${docId}`);
  },

  docList: (data: DocumentListRequestObj) => {
    return customAxios.post<any, DocumentResponseObj>(`${API_ENDPOINTS.GET_DOCUMENTS_PAGING}`, data);
  },

  staffDocumentChartList: (data: DocumentListRequestObj) => {
    return customAxios.post<any, ChartResponse>(`${API_ENDPOINTS.POST_STAFF_MOST_DOCUMENT}`, data);
  },

  clientDocumentChartList: (data: DocumentListRequestObj) => {
    return customAxios.post<any, ChartResponse>(`${API_ENDPOINTS.POST_CLIENT_MOST_DOCUMENT}`, data);
  },

  billingDocumentChartList: (data: DocumentListRequestObj) => {
    return customAxios.post<any, ChartResponse>(`${API_ENDPOINTS.POST_BILLING_STATUS_METRICS}`, data);
  },

  billingDocumentChartListByPayer: (data: DocumentListRequestObj) => {
    return customAxios.post<any, ChartResponse>(`${API_ENDPOINTS.POST_BILLING_BY_PAYER_METRICS}`, data);
  },

  billingDocumentChartListByStatus: (data: DocumentListRequestObj) => {
    return customAxios.post<any, ChartResponse>(`${API_ENDPOINTS.POST_BILLING_BY_STATUS_METRICS}`, data);
  },

  billingDocumentChartListByTemplate: (data: DocumentListRequestObj) => {
    return customAxios.post<any, ChartResponse>(`${API_ENDPOINTS.POST_BILLING_BY_TEMPLATE_METRICS}`, data);
  },

  clientList: (value: boolean) => {
    return customAxios.get<any, ClientResponse>(`${API_ENDPOINTS.GET_CLIENT_DDL_BY_CLINIC_ID}${value}`);
  },
  serviceList: (staffId: string) => {
    if (staffId && staffId != null) {
      return customAxios.get<any, ServiceResponse>(`${API_ENDPOINTS.GET_DOCUMENT_STAFF_SERVICES}${staffId}`);
    } else {
      store.dispatch(changeAppRoute(APP_ROUTES.DASHBOARD));
    }
  },
  siteList: () => {
    return customAxios.get<any, SiteResponse>(`${API_ENDPOINTS.GET_STAFF_GLOBAL_VIEW_SITES}`);
  },

  staffList: (value: boolean = true) => {
    return customAxios.get<any, StaffResponse>(`${API_ENDPOINTS.GET_STAFF_DDL_BY_CLINIC_ID}${value}`);
  },
  staffListActiveInactive: (value: boolean) => {
    return customAxios.post<any, StaffResponse>(`${API_ENDPOINTS.GET_STAFF_LIST}${value}`);
  },


  documentByID: (docId: string) => {
    return customAxios.get<any, DocumentByIdResponse>(`${API_ENDPOINTS.GET_DOCUMENT_BY_ID}${docId}`);
  },

  documentBillingHistory: (docId: string) => {
    return customAxios.get<any, DocumentBillingHistoryResponse>(`${API_ENDPOINTS.GET_DOCUMENT_BILLING_HISTORY}${docId}`);
  },
  checkCompliance: (dataItem: any) => {
    return customAxios.post<any>(`${API_ENDPOINTS.CHECK_COMPLIANCE}${dataItem?.selectedTemplate?.id}&clientID=${dataItem?.selectedPatientList?.id}&serviceId=${dataItem?.selectedAuthor?.id}&dateOfService=${dataItem?.selectedServiceDate}`);
  },

  getBillingAction: () => {
    return customAxios.get<BillingStatusResponse>(`${API_ENDPOINTS.GET_BILLING_STATUSES}`);
  },
  getDocumentServiceList: (id: string) => {
    return customAxios.get<DocumentServiceListResponse>(`${API_ENDPOINTS.GET_DOCUMENT_SERVICES}${id}`);
  },
  updateDocumentServiceList: (data: UpdateDocumentService) => {
    return customAxios.post<any>(`${API_ENDPOINTS.UPDATE_DOCUMENT_SERVICE}`, data);
  },
  updateDocumentServiceDate: (data: UpdateDocumentServiceDate) => {
    return customAxios.post<any>(`${API_ENDPOINTS.UPDATE_DOCUMENT_SERVICE_DATE}`, data);
  },
  updateDocumentDiagnosis: (data: UpdateDocumentDiagnosis) => {
    return customAxios.post<any>(`${API_ENDPOINTS.UPDATE_DOCUMENT_DIAGNOSIS}`, data);
  },
  updateDocumentSiteOfService: (data: UpdateDocumentSiteOfService) => {
    return customAxios.post<any>(`${API_ENDPOINTS.UPDATE_DOCUMENT_SITE_OF_SERVICE}`, data);
  },
  getPlaceOfService: (id: any) => {
    return customAxios.get<any>(`${API_ENDPOINTS.GET_PLACE_OF_SERVICES_DDL_BY_CLINIC_ID}${id}`);
  },
  updatePOSPayload: (data: any) => {
    return customAxios.post<any>(`${API_ENDPOINTS.UPDATE_DOCUMENT_PLACE_OF_SERVICE}`, data);
  },
  getLatestDocumentByTemplate: (data: LatestDoucmentByTemplateIdPayload) => {
    return customAxios.get<any>(`${API_ENDPOINTS.GET_LATEST_DOCUMENT_BY_TEMPLATE_ID}?documentTemplateId=${data?.documentTemplateId}&clientId=${data?.clientId}`);
  },
  getRiskDDL: (riskType: String) => {
    return customAxios.get<any>(`${API_ENDPOINTS.GET_RISK_DDL}${riskType}`);
  },
  lockUnlockDocuments: (payload: object) => {
    return customAxios.post<any>(API_ENDPOINTS.LOCK_UNLOCK_DOCUMENTS, payload)
  },

  approveOrDisapproveDocument: (payload: object) => {
    return customAxios.post<any>(API_ENDPOINTS.APPROVE_DISAPPROVE_DOCUMENTS, payload);
  },

  docMetricsByServiceCode: (data: DocumentListRequestObj) => {
    return customAxios.post<any, ChartResponse>(`${API_ENDPOINTS.POST_DOC_METRICS_BY_SERVICE_CODE}`, data);
  },

  superBill: (payload: string) => {
    return customAxios.get<any>(`${API_ENDPOINTS.GET_SUPER_BILL}${payload}`);
  },

  getEbpCodes: () => {
    return customAxios.get<any>(`${API_ENDPOINTS.GET_EBP_CODES}`);
  },

  getDocTempType:()=>{
    return customAxios.get<any>(`${API_ENDPOINTS.GET_DOCUMENT_TEMPLATE_TYPES}`);
  },
  getDocAttachment: (docId: string) => {
    return customAxios.get<any>(`${API_ENDPOINTS.GET_DOCUMENT_ATTACHMENT}${docId}`);
  },

  removeDocUnits: (data: any) => {
    return customAxios.delete<any>(`${API_ENDPOINTS.REMOVE_DOC_UNITS}?documentId=${data?.documentId}`);
  },

  recalculateDocUnits: (data: any) => {
    return customAxios.post<any>(`${API_ENDPOINTS.RECALCULATE_DOC_UNITS}?documentId=${data?.documentId}`);
  }
}
