import { RadioGroup } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";


export const contactTypeArr = [
    {
        label: "Face to Face",
        value: "isFaceToFace",
    },
    {
        label: "Attempts",
        value: "attempts"
    },
    {
        label: "Phone",
        value: "phone"
    },
    {
        label: "NA",
        value: "na"
    },
];
export const  ContactType=({contactType,handleChangeVisit,errorObj})=> {
    

    
    return (
        <div>
            <div className="d-flex align-items-center mb-4">
                <p className="mb-0 f-12 d-flex align-items-center">
                    <RadioGroup
                        
                        data={contactTypeArr}
                        value={contactType}
                        onChange={handleChangeVisit}
                        name="visit"
                        layout={"horizontal"}
                    />
                </p>
                <p className="mb-0 f-12 ml-3 d-flex align-items-center">
                {errorObj?.contactType && <Error>{errorObj?.contactType}</Error>}
                </p>
            </div>
        </div>
    );
}
