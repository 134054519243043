
import { ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTemplateListData } from "../globalDataCheckerService";
import MultiSelectDropDown from "src/control-components/multi-select-drop-down/multi-select-drop-down";
import React from "react";


export const MultiSelectTemplateDropDownComponent = (props: TemplateDdlProps) => {
    const state: ComponentState = useSelector(states => {
        return states;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { selectedTemplate, onChangeTemplate, autoClose, ...rest } = props
    const { documentTemplateList } = state['ClinicsReducer'];
    const [templateList, setTemplateList] = useState([]);
    const [isDataLoadStart, setDataLoadStart] = useState(false);
    const [filter, setFilter] = useState<any>();
    const [loading, setLoading] = useState(false);


    const callTemplateListApiData = async () => {
        getTemplateListData()
    }
    useEffect(() => {
        if (!isDataLoadStart) {
            setLoading(true)
            callTemplateListApiData()
            setDataLoadStart(true)
        }
        if (documentTemplateList && Object.keys(documentTemplateList).length > 0 && Array.isArray(documentTemplateList.resultData)) {
            setLoading(false)
            const list = documentTemplateList.resultData.map(r => ({
                id: r.id,
                name: r.templateName
            }));
            setTemplateList(list);
        }

    }, [isDataLoadStart, documentTemplateList])


    function renderToItem(li, itemProps) {
        const itemChildren = (
            <span>
                <input
                    type="checkbox"
                    name={itemProps.dataItem}
                    checked={itemProps.selected}
                    onChange={(e) => itemProps.onClick(itemProps.index, e)}
                />
                &nbsp;{li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }

    function onFilterChange(event, listName) {
        setFilter({ ...filter, [listName]: event.filter });
    }

    return <div className="">
        <div className="">
            <div className="p-0 col-md-12">
                <MultiSelectDropDown
                    data={templateList}
                    textField="name"
                    label='Template'
                    name="template"
                    value={selectedTemplate}
                    onChange={onChangeTemplate}
                    autoClose={autoClose}
                    dataItemKey={"id"}
                    itemRender={renderToItem}
                    showExtraPadding={true}
                    loading={loading}
                />
            </div>
        </div>
    </div>
}

export interface TemplateDdlProps {
    selectedTemplate: any,
    // isCheckActiveStaff: boolean,
    // switchStaffValue: any,
    onChangeTemplate?: (newValue: any) => void,
    autoClose: boolean
}
