import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { clinicServices } from 'src/services/clinicService';
import { loaderAction, saveComplianceReport } from '../actions';
import { ComplianceReportResponse } from 'src/dataModels/complianceModel';


export function* getComplianceReportSaga() {
    yield takeLatest(TYPES.GET_COMPLIANCE_REPORT, getComplianceReportWorker)
}

function* getComplianceReportWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ComplianceReportResponse = yield clinicServices.complianceReport(param['payload']);
        yield put(loaderAction(false));
        yield put(saveComplianceReport(response.resultData))
    }
    catch (err) {
    }

}
