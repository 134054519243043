import React, { useEffect, useState } from "react";


export const NichqComponent2 = ({
    listItems,
    setListItems,
    
    subTitle
}) => {

    const [opList, setOpList] = useState(listItems)

    useEffect(() => {
        if (opList && opList.length>0) {
           
            setListItems(opList);
        }
    }, [opList])



    const handleRadioChange = (itemId, value) => {
        // Find the item in the items array

        setOpList(list => list.map((item, i) =>
            item.id === itemId
                ? {
                    ...item,
                    value: value
                }
                : item
        ));


    };


    return (
        <div className="ACE_main_table mt-4">
            <div>
                {/* <div className="head-3">{title}</div> */}
                <label className="pb-3 mt-2">
                    {subTitle}
                </label>
            </div>
            <div className="row border-bottom mb-3">
                <div className="col-12">
                    <label className="fw-400">
                        NOTE: 1 = Excellent; 2 = Above Average; 3 = Average; 4 = Problem; 5 = Problematic
                    </label>
                </div>

            </div>
            {opList?.map((item) => (
                <div className="row mb-3 pb-2 border-bottom">
                    <div className="col-12">
                        <div key={item.id}>
                            <label>
                                {item.id}. {item.name}
                            </label>
                        </div>
                    </div>
                    <div className="col-12  select_table_phq cus-select-table-drops">
                        {[1, 2, 3, 4, 5].map((value) => (
                             <label key={value} className="cats_label">
                             <input
                               type="radio"
                               value={value}
                               checked={item.value == value}
                               onChange={() => handleRadioChange(item.id, value)}
                               className="mr-2 mb-0 ml-2"
                             />
                             <span>{value}</span></label>
                        ))}
                    </div>
                </div>
            ))}
            
        </div>
    );
};

