
import { getClientList, getSiteList, getStaffList, getDoumentTemplate } from "src/redux/actions";

import { store } from "src/redux/store/store";

export function getSiteListData() {
    store.dispatch(getSiteList({}));
}

export function getStaffListData(isCheckActiveStaff: boolean, switchStaffValue: any) {
    store.dispatch(getStaffList(switchStaffValue !== undefined ? switchStaffValue : isCheckActiveStaff))
}

export function getClientListData(isCheckActiveStaff: boolean, switchStaffValue: any) {
    store.dispatch(getClientList(switchStaffValue !== undefined ? switchStaffValue : isCheckActiveStaff))
}

export function getTemplateListData() {
    store.dispatch(getDoumentTemplate({}));
}