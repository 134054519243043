import { API_ENDPOINTS } from "./api-endpoints";
import { customAxios } from "./useExPressApi";
import { AddAuthReq, ClientBillingClaimResponse, ClientComplianceListResponse, ServiceCodeResponse, CompleteComplianceResponse, clinicFlagResponse, clientStatusResponse, unreadCountResponse, ResponseMultiClientList, CheckDuplicateUsersRequests } from "src/dataModels/clientResponse";
import { UserAuthResponse } from "src/dataModels/authDataModels";
import { InsertReviewGoalModel, SaveDefaultClientObj, UpdateGoalModel, UpdateIntvModel, UpdateObjectiveModel } from "src/dataModels/clientDataModel";
import { GetTpStaffTypesResponse, ReviewGoalResponse, ServiceSetsResponse, DocSettingResponse } from "src/dataModels/clinicResponseModels";
import { store } from "src/redux/store/store";
import { changeAppRoute } from "src/redux/actions";
import APP_ROUTES from "src/helper/app-routes";
import { Encrption } from "../../src/app-modules/encrption";

export const clientServices = {

  billingClaims: (data: any) => {
    return customAxios.post<any, ClientBillingClaimResponse>(`${API_ENDPOINTS.GET_CLIENT_BILLING_CLAIMS}`, data);
  },
  clientCompliance: (id: string) => {
    return customAxios.get<any, ClientComplianceListResponse>(`${API_ENDPOINTS.GET_CLIENT_COMPLIANCE}${id}`);
  },
  completeCompliance: (data: any) => {
    if (data && data.clientId) {
      return customAxios.post<any, CompleteComplianceResponse>(`${API_ENDPOINTS.COMPLETE_COMPLIANCE}${data?.clientId}&completingComplianceId=${data?.completingComplianceId}&completedDate=${data?.completedDate}`);
    } else {
      store.dispatch(changeAppRoute(APP_ROUTES.DASHBOARD));
    }
  },

  getDocumentsForCompliance: (payloadString: string) => {
    return customAxios.get<any, any>(`${API_ENDPOINTS.GET_ATTACHED_DOCS_FOR_COMPLIANCE}${payloadString}`);
  },

  deleteManuallyCompletedCompliance: (dataItem: any) => {
    return customAxios.delete<any>(`${API_ENDPOINTS.DELETE_MANUALLY_COMPLETED_COMPLIANCE}`, { data: dataItem });
  },

  saveMultipleAuth: (data: AddAuthReq) => {
    return customAxios.post<any, UserAuthResponse>(`${API_ENDPOINTS.SAVE_MULTI_AUTH}`, data);
  },

  getServicesWoModifiers: () => {
    return customAxios.get<any, ServiceCodeResponse>(`${API_ENDPOINTS.GET_SERVICE_CODES_WO_MODIFIERS}`);
  },
  getClinicFlag: () => {
    return customAxios.get<any, clinicFlagResponse>(`${API_ENDPOINTS.GET_CLINIC_CLIENT_FLAGS}`);
  },
  saveClinicFlag: (dataItem: any) => {
    return customAxios.post<any>(`${API_ENDPOINTS.ASSIGN_FLAG_TO_CLIENT}`, dataItem);
  },

  getClientServicebyServiceCodes: (data: string[]) => {
    return customAxios.post<any>(`${API_ENDPOINTS.GET_SERVICE_BY_SERVICE_CODE}`, { 'srvCodes': data });
  },

  getClientStatus: () => {
    return customAxios.get<any, clientStatusResponse>(`${API_ENDPOINTS.GET_CLIENT_STATUS}`);
  },
  saveSelectedClientStatus: (dataItem: any) => {
    return customAxios.post<any>(`${API_ENDPOINTS.UPDATE_CLIENTS_STATUS}`, dataItem);
  },
  saveSelectedClientEligibility: (dataItem: any) => {
    return customAxios.post<any>(`${API_ENDPOINTS.INSERT_ELIGIBILITY_QUEUE}`, dataItem);
  },

  getUnreadCount: () => {
    return customAxios.get<any, unreadCountResponse>(`${API_ENDPOINTS.GET_UNREAD_COUNT}`);
  },

  getMultiClientList: (users: string[]) => {
    return customAxios.post<any, ResponseMultiClientList>(`${API_ENDPOINTS.MULTI_CLIENT_LIST}`, { 'clientIds': users });
  },

  saveClientDefaultGoals: (data: SaveDefaultClientObj[]) => {
    return customAxios.post<any, ResponseMultiClientList>(`${API_ENDPOINTS.SAVE_DEFAULT_GOALS}`, data);
  },

  updateDefaultGoals: (data: SaveDefaultClientObj[]) => {
    return customAxios.post<any, ResponseMultiClientList>(`${API_ENDPOINTS.UPDATE_DEFAULT_GOALS}`, data);
  },

  updateDefaultObjectives: (data: UpdateObjectiveModel) => {
    return customAxios.post<any, ResponseMultiClientList>(`${API_ENDPOINTS.UPDATE_DEFAULT_OBJECTIVES}`, data);
  },
  updateDefaultInterventions: (data: UpdateIntvModel) => {
    return customAxios.post<any, ResponseMultiClientList>(`${API_ENDPOINTS.UPDATE_DEFAULT_INTERVENTION}`, data);
  },


  getDefaultGoalDataById: (idP: number) => {
    return customAxios.get<any, any>(`${API_ENDPOINTS.GET_DEFAULT_GOAL_BY_ID}?id=${idP}`);
  },

  deleteDefaultObjective: (idP: number) => {
    return customAxios.delete<any, ResponseMultiClientList>(`${API_ENDPOINTS.DELETE_DEFAULT_OBJECTIVE}?id=${idP}`);
  },

  deleteDefaultIntervention: (idP: number) => {
    return customAxios.delete<any, ResponseMultiClientList>(`${API_ENDPOINTS.DELETE_DEFAULT_INTERVENTION}?id=${idP}`);
  },

  insertDefaultObjective: (data: UpdateObjectiveModel) => {
    return customAxios.post<any, ResponseMultiClientList>(`${API_ENDPOINTS.ADD_DEFAULT_OBJECTIVE}`, data);
  },

  insertDefaultInterventions: (data: UpdateIntvModel) => {
    return customAxios.post<any, ResponseMultiClientList>(`${API_ENDPOINTS.ADD_DEFAULT_INTERVENTION}`, data);
  },
  editDefaultGoalzObjective: (data: UpdateGoalModel) => {
    return customAxios.post<any>(`${API_ENDPOINTS.UPDATE_DEFAULT_GOAL}`, data);
  },
  getServiceSets: () => {
    return customAxios.get<any, ServiceSetsResponse>(`${API_ENDPOINTS.GET_SERVICE_SETS_LIST}`);
  },
  getTpStaffType: () => {
    return customAxios.get<GetTpStaffTypesResponse>(`${API_ENDPOINTS.GET_TP_STAFF_TYES}`);
  },

  moveClientsSite: (dataItem: any) => {
    return customAxios.post<any>(`${API_ENDPOINTS.MOVE_CLIENT_SITE}`, dataItem);
  },
  insertReviewGoal: (data: InsertReviewGoalModel) => {
    return customAxios.post<any>(`${API_ENDPOINTS.INSERT_REVIEW_GOAL}`, data);
  },
  getReviewGoalByGoalId: (id: number) => {
    return customAxios.get<ReviewGoalResponse>(`${API_ENDPOINTS.GET_REVIEW_GOAL_BY_GOAL_ID}?goalId=${id}`);
  },
  deleteReviewGoal: (id: number) => {
    return customAxios.delete<any>(`${API_ENDPOINTS.DELETE_REVIEW_GOAL}?id=${id}`);
  },

  fetchDocSettings: (id: number) => {
    return customAxios.get<any, DocSettingResponse>(`${API_ENDPOINTS.GET_DOCUMENT_PREFS_BY_CLINIC_ID}${id}`);
  },

  checkDuplicateClient: (data: CheckDuplicateUsersRequests) => {
    return customAxios.post<any, DocSettingResponse>(`${API_ENDPOINTS.CHECK_DUPLICATE_CLIENTS}`, data);
  },

  getClientLedgerCurrentBalance: (clientId) => {
    return customAxios.get(API_ENDPOINTS.GET_CLIENT_LEDGER_CURRENT_BALANCE, {
      params: {
        clientId,
      },
    });
  },

  getClientLedgerByClientId: (data) => {
    return customAxios.post(`${API_ENDPOINTS.GET_CLIENT_LEDGER_BY_CLIENT_ID}`, data);
  },

  insertClientLedgerRecord: (data) => {
    return customAxios.post(`${API_ENDPOINTS.INSERT_CLIENT_LEDGER}`, data);
  },

  deleteClientLedgerRecordWithId: (id) => {
    return customAxios.delete(`${API_ENDPOINTS.DELETE_CLIENT_LEDGER_BY_ID}` + id);
  },

  getClientLedgerPaymentTypes: (isActive) => {
    return customAxios.get(`${API_ENDPOINTS.GET_PAYMENT_TYPES_CLIENT_LEDGER}` + isActive);
  },

  getClientLedgerById: (ledgerId) => {
    return customAxios.get(`${API_ENDPOINTS.GET_CLIENT_LEDGER_BY_ID}` + Encrption(ledgerId));
  },

  updateClientLedger: (data) => {
    return customAxios.post(`${API_ENDPOINTS.UPDATE_CLIENT_LEDGER}`, data);
  },

  getClientAuditLogList: (payload) => {
    return customAxios.post(`${API_ENDPOINTS.GET_CLIENT_AUDIT_LOG}`, payload);
  },

  getClientAccessAccount: () => {
    return customAxios.get(`${API_ENDPOINTS.GET_CLIENT_PORTAL_ACCOUNT_ACCESS_LIST}`);
  },

  createClientPortalAccessAccount: (payload) => {
    return customAxios.put(`${API_ENDPOINTS.CREATE_ACCOUNT_FOR_CLIENT_PORTAL_ACCESS}`, payload);
  },

  updateClientPortalAccess: (payload) => {
    return customAxios.post(`${API_ENDPOINTS.UPDATE_ACCOUNT_FOR_CLIENT_PORTAL_ACCESS}`, payload);
  },

  getDetailCpAccountAccessDetail: (id) => {
    return customAxios.get(`${API_ENDPOINTS.GET_CLIENT_PORTAL_ACCOUNT_ACCESS_DETAILS}${id}`);
  },

  deleteClientPortalAccess: (id) => {
    return customAxios.delete(`${API_ENDPOINTS.DELETE_CLIENT_PORTAL_ACCOUNT_ACCESS}${id}`);
  },

  resetPasswordForCpAccess: (data) => {
    return customAxios.post(`${API_ENDPOINTS.RESET_ACCESS_PWD}`,data);
  },


  updateClientMedication: (data: any) => {
    return customAxios.put<any, ResponseMultiClientList>(`${API_ENDPOINTS.UPDATE_CLIENT_MEDICATION}`, data);
  },
  insertClientMedication: (data: any) => {
    return customAxios.post<any, ResponseMultiClientList>(`${API_ENDPOINTS.INSERT_CLIENT_MEDICATION}`, data);
  },
  deleteClientMedication: (id: Number) => {
    return customAxios.delete<ResponseMultiClientList>(`${API_ENDPOINTS.DELETE_CLIENT_MEDICATION}?id=${id}`);
  },  
  getClientMedicationById: (data: any) => {
    const { id, isActive } = data;
    return customAxios.get<any, ResponseMultiClientList>(`${API_ENDPOINTS.GET_CLIENT_MEDICATION_BY_ID}?id=${id}&isActive=${isActive}`, data);
  },
  getClientMedicationByClientId: (data: any) => {
    const { id, isActive } = data;
    return customAxios.get<any, ResponseMultiClientList>(`${API_ENDPOINTS.GET_CLIENT_MEDICATION_BY_CLIENT_ID}?clientId=${id}&isActive=${isActive}`);
  },

  insertSocialDeterminants: (data: any) => {
    return customAxios.post<any>(`${API_ENDPOINTS.INSERT_UPDATE_SOCIAL_DETERMINANTS}`, data);
  },
  getSocialDeterminants: (clientId:any) => {
    return customAxios.get(`${API_ENDPOINTS.SAVE_SOCIAL_DETERMINANTS_RESPONSE}?clientId=${clientId}`);
  },
  updateSocialDeterminant: (data: any) => {
    return customAxios.post<any>(`${API_ENDPOINTS.INSERT_UPDATE_SOCIAL_DETERMINANTS}`, data);
  },


  getContactTypeList: () => {
    return customAxios.get(`${API_ENDPOINTS.GET_CONTACT_TYPE_LIST}`);
  },
  getCollateralContact: (selectedClientId:any) => {
    return customAxios.post(`${API_ENDPOINTS.GET_CLIENT_COLLATERAL_CONTACT}?clientId=${selectedClientId}`);
  },
  insertCollateralContactInfo: (data: any) => {
    return customAxios.post<any>(`${API_ENDPOINTS.SAVE_CLIENT_COLLATERAL_CONTACT_INFO}`, data);
  },
  updateCollateralContactInfo: (data: any) => {
    return customAxios.put<any>(`${API_ENDPOINTS.UPDATE_CLIENT_COLLATERAL_CONTACT_INFO}`, data);
  },
  deleteCollateralContact: (id: Number) => {
    return customAxios.delete<any>(`${API_ENDPOINTS.DELETE_CLIENT_COLLATERAL_CONTACT}?id=${id}`);
  },
  getCollateralContactById: (id:any) => {
    return customAxios.get(`${API_ENDPOINTS.GET_CLIENT_COLLATERAL_CONTACT_BY_ID}?id=${id}`);
  },

  docAttachments: (data:any) => {
    return customAxios.post(`${API_ENDPOINTS.CLIENT_STORED_DOCUMENTS_ATTACHMENTS}`,data);
  },
  getComplianceAuditHistory: (param:any) => {
    return customAxios.get(`${API_ENDPOINTS.GET_COMPLIANCE_AUDIT_HISTORY}?clientId=${param?.clientId}&complianceId=${param?.complianceId}`);
  },
  
  
}
