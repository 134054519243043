import React, { useEffect } from "react";

const HeadwayWidget = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://cdn.headwayapp.co/widget.js";
        document.head.appendChild(script);

        const config = {
            selector: ".headway-badge",
            account: "7KpOpJ"
        };

        script.onload = () => {
            if (window.Headway) {
                window.Headway.init(config);
            }
        };

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <span
            className="headway-badge"
            id="headway-badge"
            style={{ cursor: "pointer" }}
            onClick={() => {
                window.open('https://updates.notenetic.com', '_blank');
            }}
        >
            <i className="fa-regular fa-bell" style={{ color: "#ea4d36" }}></i>
        </span>
    );
};

export default HeadwayWidget;