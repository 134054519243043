import React from "react"
import { AddDocumentFileTemplate } from "../add-document-file-template";
import { useLocation } from "react-router-dom";

export const AdminViewDocumentFileTemplate = () => {

    const location = useLocation();
    let templateName = location.state.templateName;

    return <div className="form-group p-0 col-md-12 public-template-view">
        <AddDocumentFileTemplate
            controlErrors={null}
            showValidationError={null}
            focusEnable={null}
            name={templateName}
            selectedPatientList={[]}
            isEdit={false}
            onGoalsDataStateChange={() => {
            }}
            isViewDoc={true}
            preselctedGoalEdit={undefined}
            dxPreselectedData={undefined}
            onDxStateChange={() => {
            }}
            tpId={0}
            isDraft={false}
            draftCommonControlls={[]}
            isViewMode={true}
            docFieldMappings={null}
            onComponentLoaded={null}
            documentId={null}
        />
    </div>
}