import { saveAs } from "@progress/kendo-file-saver";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressLoading from "src/control-components/loader/loader_with_text";
import './document-styles.css';
import DocumentTemplatePdfItem from "./pdf-doc-temp-item";
import { saveExportCount } from "src/redux/actions";
import pako from 'pako';
import { onShowFileTempFields } from "../file-template-utility";

function DocumentTemplatePdf({ documentPrintData, onlyView, metaDataDocs }) {

  const _elementRef = useRef(null);
  const [isFieldSet, setFieldSet] = useState(false);
  const [docCalDone, setDocCalDone] = useState(false);
  const dispatch = useDispatch();
  const [isPrepared, setIsPrePared] = useState(false);
  const state = useSelector(states => {
    return states;
  });
  const { docPreparedCount, docExportCount } = state['DocumentReducer'];
  const [isFileTempFieldMapped, setIsFileTempFieldMapped] = useState(false);
  const [isDocumentRendered, setIsDocumentRendered] = useState(false);
  const [isDocumentFileComponentLoaded, setIsDocumentFileComponentLoaded] = useState(false);
  const [documentFileComponentLoadCount, setDocumentFileComponentLoadCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    const nonFileTemplateDocs = documentPrintData.filter(doc => {
      // console.log(doc)
      return !doc.isHtmlFileTypeTemplate
    }).length
    setDocumentFileComponentLoadCount(x => x + nonFileTemplateDocs);
  }, [documentPrintData]);

  useEffect(() => {
    // console.log("documentFileComponentLoadCount", documentFileComponentLoadCount, "/", documentPrintData.length);
    if (documentFileComponentLoadCount === documentPrintData.length) {
      setIsDocumentFileComponentLoaded(true)
      // console.log("Document loaded completely!");
    }
  }, [documentFileComponentLoadCount]);

  const exportElement = async (elements, metaDataDocs) => {
    if (elements.length > 0) {
      const formattedElements = elements.map((optionItem, index) => {
        const cDocId = optionItem.getAttribute('id');
        const styles = [...document.styleSheets]
          .map(styleSheet => {
            try {
              return [...styleSheet.cssRules]
                .map(rule => rule.cssText)
                .join('');
            } catch (e) {
              console.log('Access to stylesheet %s is denied. Ignoring...', styleSheet.href);
            }
            return '';
          })
          .join('\n');
        return {
          id: cDocId,
          html: optionItem.outerHTML,
          title: "Client Document Print",
          author: "Notenetic Team",
          styles,
          metaData: metaDataDocs.find((item) => +item.documentId === +cDocId),
          index
        };
      });

      try {
        dispatch(saveExportCount(formattedElements.length))

        const url = 'https://puppeteer.notenetic.com:3001/generate-pdf/document'
        // const url = 'http://localhost:3001/generate-pdf/document'

        const body = JSON.stringify({ elements: formattedElements });
        const compressedBody = pako.gzip(body);

        let retry = 1;
        let maxRetry = 3;

        while (retry <= maxRetry) {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/octet-stream'
            },
            body: compressedBody,
          });

          if (!response.ok) {
            if (retry == maxRetry) {
              setErrorMessage(`Error: ${response?.statusText || response?.status}`)
              console.error('Error generating PDF: Network response was not ok', response);
              break;
            }
          } else {
            const blob = await response.blob();
            saveAs(blob, 'Document.pdf');
            window.close();
            break;
          }
          retry++;
        }
      } catch (error) {
        setErrorMessage(`Error: ${error}`)
        console.error('Error generating PDF:', error);
      }
    }
  };

  React.useEffect(() => {
    // onFieldsSet();
    setFieldSet(true)
  }, []);


  React.useEffect(() => {
    // console.log("_elementRef.current", _elementRef?.current)
    if (_elementRef.current && !docCalDone) {
      handleSelect().then(() => { });
      setDocCalDone(true)
    }
  }, [isDocumentRendered, isFieldSet]);

  const handleSelect = async () => {
    if (!onlyView) {
      setIsPrePared(true)
      await exportElement(_element1, metaDataDocs);
    }
  }

  let _element1 = [];

  useEffect(() => {
    if (isDocumentFileComponentLoaded) {
      mapDocumentFileFields();
    }
  }, [documentPrintData, isDocumentFileComponentLoaded])

  const mapDocumentFileFields = () => {
    // console.log("mapDocumentFileFields..")
    if (!isFileTempFieldMapped && documentPrintData && documentPrintData.length > 0) {
      documentPrintData.map((currentDocumentData, index) => {
        onShowFileTempFields(currentDocumentData.fieldsMapping, currentDocumentData.documentId, true, currentDocumentData.htmlFileName, index);
        if (index === (documentPrintData.length - 1)) {
          setIsFileTempFieldMapped(true)
        }
      })
    } else {
      setIsFileTempFieldMapped(true)
    }
  }

  function onComponentLoaded() {
    setDocumentFileComponentLoadCount(x => x + 1);
  }

  const docComponent = () => {
    return (<div>
      {
        documentPrintData.map((currentDocumentData) => (
          <div
            key={currentDocumentData.documentId}
            id={currentDocumentData.documentId}
            className="k-pdf-export"
            ref={(div) => {
              if (!docCalDone && div != null) {
                _element1.push(div);
              }
            }}>
            <DocumentTemplatePdfItem
              onComponentLoaded={onComponentLoaded}
              staffInfo={currentDocumentData.staffInfo}
              documentDetail={currentDocumentData.documentDetail}
              diagnosisList={currentDocumentData.diagnosisList}
              docSignature={currentDocumentData.docSignature}
              docClientSignature={currentDocumentData.docClientSignature}
              isHtmlFileTypeTemplate={currentDocumentData.isHtmlFileTypeTemplate}
              htmlFileName={currentDocumentData.htmlFileName}
              template={currentDocumentData.template}
              fieldsMapping={currentDocumentData.fieldsMapping}
              documentId={currentDocumentData.documentId}
              docTreatmentPlans={currentDocumentData.docTreatmentPlans}
              historyList={currentDocumentData.historyList}
              documentCommonControls={currentDocumentData.documentCommonControls}
              metaData={metaDataDocs.find((i) => i.documentId === currentDocumentData.documentId)}
              docHasControlls={currentDocumentData.docHasControlls}
            ></DocumentTemplatePdfItem>
          </div>
        ))
      }
    </div>)
  }

  return (
    errorMessage
      ? <ProgressLoading message={'Something went wrong, please try again later!' + errorMessage} />
      : (
        (!isFileTempFieldMapped)
          ? <div>
            {docComponent()}
            <ProgressLoading message={'Please wait while we are collecting information for all selected documents.'} />
          </div> :
          <div ref={(div) => {
            _elementRef.current = div;
            setIsDocumentRendered(true);
          }}>
            {docComponent()}
            {!onlyView && <ProgressLoading message={isPrepared ?
              `Exporting documents, It may take a while...`
              : `Prepared documents (${docPreparedCount}/${documentPrintData.length})`}>
            </ProgressLoading>}
          </div>
      )
  )
}

export default DocumentTemplatePdf;
