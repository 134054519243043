import { SaveDefaultClientObj, ServiceSetsDataModel } from "src/dataModels/clientDataModel";
import { CLINIC_ID, GET_CLINIC_DETAILS_BY_ID, SAVE_CLIENT_COMPLIANCE, SAVE_CLIENT_COMPLIANCE_FOR_CLINIC, SAVE_CLINIC_SERVICES, SAVE_DOCUMENT_TEMPLATE, SAVE_CLIENT_COMPLIANCE_BY_ID, SAVE_CLINIC_DEFAULT_GOALS, SAVE_GOAL_BY_ID, SAVE_SERVICE_SETS, SAVE_AVAILABLE_SERVICE_LIST, SAVE_SERVICE_DDL_BY_CLINIC_ID, SAVE_SERVICE_SET_DDL, SAVE_ASSIGN_TO_STAFF_RESPONSE, SAVE_COMPLIANCE_REPORT, SAVE_AUTH_SET, SAVE_CLINIC_DEFAULT_GOALS_TEMPLATES, SAVE_DEFAULT_GOALS_TEMPLATES_INFO, SAVE_CLINIC_STORED_DOCUMENT_BY_CLINIC_ID, SAVE_CLINIC_DOCUMENT_BYID, SAVE_CLINIC_TAGS, SAVE_INSERT_CLINICIAN, SAVE_CLINICIAN_LIST, SAVE_CLINIC_NOTIFICATION_SETTING, SAVE_AUTH_TRANSACTIONS, SAVE_ROLE, SAVE_ROLE_LIST, GET_STORE_COMPLIANCE_REPORT, SAVE_ASSIGNED_ROLES_FOR_NOTIFICATION_SETTINGS, SAVE_CLINIC_TP_SERVICE_TYPES, SAVE_CLINIC_TP_STAFF_TYPES, SAVE_CALENDAR_CATEGORY, SAVE_DEFAULT_CALENDAR_CATEGORYS, SAVE_SITES_FOR_CLINIC, SAVE_REMINDER_SETTINGS, SAVE_AUDIT_LOGS, SAVE_GLOBAL_SITES_FOR_CLINIC, SAVE_CLINIC_NEW_AUTH_ALERT_SETTING, SAVE_REMINDER_LOADINGS } from "../actions/types";

import { ComplianceData, ComplianceResponse, ClinicServiceListData, DocumentTemplateData, DefaultGoalsTemplateListResultData, ClinicStoredDocumentResult, ClinicianListModel, clinicTpStaffOrServiceResultData, calendarSettingResultData, DefaultCalendarCategoriesResultData } from "src/dataModels/clinicResponseModels";
import { ComplianceReportList } from "src/dataModels/complianceModel";
import { AuthSets } from "src/dataModels/authSetModels";
import { AuditLogData, ReminderData } from "src/dataModels/reminder-model";

const initialState = {
  complianceList: {} as ComplianceData,
  clinicServiceList: {} as ClinicServiceListData,
  documentTemplateList: {} as DocumentTemplateData,
  goalDataObject: {} as SaveDefaultClientObj,
  serviceSetsData: null as ServiceSetsDataModel[] | null,
  complianceReport: [] as ComplianceReportList[],
  listAuthSets: [] as AuthSets[],
  listDefaultGoalsTemplate: [] as DefaultGoalsTemplateListResultData[],
  listClinicDocumentByClinicId: [] as ClinicStoredDocumentResult[],
  listClinician: [] as ClinicianListModel[],
  insertClinicianResponse: null,
  clinicTpServiceList: [] as clinicTpStaffOrServiceResultData[],
  clinicTpStaffList: [] as clinicTpStaffOrServiceResultData[],
  calendarCategoryList: [] as calendarSettingResultData[],
  DefaultCalendarCategories: {} as DefaultCalendarCategoriesResultData,
  clinicSites: [],
  listClinicNewAuthAlertSetting: [],
  AppointmentReminderSettings: {} as ReminderData,
  auditLogData: {} as AuditLogData,
  clinicGLobalSites: [],
  reminderSettingsFetched: false
}

export const ClinicsReducer = (state = initialState, action: any) => {
  const oldState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case SAVE_CLIENT_COMPLIANCE_FOR_CLINIC:
      return {
        ...oldState,
        complianceList: payload,
      };
    case SAVE_CLINIC_SERVICES:
      return {
        ...oldState,
        clinicServiceList: payload
      }
    case SAVE_DOCUMENT_TEMPLATE:
      return {
        ...oldState,
        documentTemplateList: payload
      }
    case SAVE_CLIENT_COMPLIANCE:
      return {
        ...oldState,
        clientComplianceList: payload
      }
    case SAVE_CLIENT_COMPLIANCE_BY_ID:
      return {
        ...oldState,
        clientComplianceById: payload
      }
    case SAVE_CLINIC_DEFAULT_GOALS:
      return {
        ...oldState,
        clincDefaultGoalList: payload
      }

    case SAVE_GOAL_BY_ID:
      return {
        ...oldState,
        goalDataObject: payload
      }
    case SAVE_SERVICE_SETS:
      return {
        ...oldState,
        serviceSetsData: payload
      }
    case SAVE_AVAILABLE_SERVICE_LIST:
      return {
        ...oldState,
        availableServiceList: payload
      }
    case SAVE_SERVICE_DDL_BY_CLINIC_ID:
      return {
        ...oldState,
        serviceDDlByClinicId: payload
      }
    case SAVE_SERVICE_SET_DDL:
      return {
        ...oldState,
        serviceSetDDL: payload,
      };
    case SAVE_ASSIGN_TO_STAFF_RESPONSE:
      return {
        ...oldState,
        AssigntoStaffResponse: payload,
      };

    case SAVE_COMPLIANCE_REPORT:
      return {
        ...oldState,
        complianceReport: payload,
      };

    case SAVE_AUTH_SET:
      return {
        ...oldState,
        listAuthSets: payload,
      };
    case SAVE_CLINIC_DEFAULT_GOALS_TEMPLATES:
      return {
        ...oldState,
        listDefaultGoalsTemplate: payload,
      };
    case SAVE_CLINIC_STORED_DOCUMENT_BY_CLINIC_ID:
      return {
        ...oldState,
        listClinicDocumentByClinicId: payload,
      };
    case SAVE_CLINIC_DOCUMENT_BYID:
      return {
        ...oldState,
        clinicDocumentByid: payload,
      };
    case SAVE_CLINIC_TAGS:
      return {
        ...oldState,
        listClinicTags: payload,
      };
    case SAVE_INSERT_CLINICIAN:
      return {
        ...oldState,
        insertClinicianResponse: payload,
      };

    case SAVE_CLINICIAN_LIST:
      return {
        ...oldState,
        listClinician: payload,
      };
    case SAVE_CLINIC_NOTIFICATION_SETTING:
      return {
        ...oldState,
        listClinicNotificationSetting: payload,
      };
    case SAVE_CLINIC_NEW_AUTH_ALERT_SETTING:
      return {
        ...oldState,
        listClinicNewAuthAlertSetting: payload,
      };

    case SAVE_AUTH_TRANSACTIONS:
      return {
        ...oldState,
        listAuthViewtrans: payload
      };
    case GET_STORE_COMPLIANCE_REPORT:
      return {
        ...oldState,
        complianceReportData: payload
      }
    case SAVE_ROLE_LIST:
      return {
        ...oldState,
        roleList: payload
      }
    case SAVE_ASSIGNED_ROLES_FOR_NOTIFICATION_SETTINGS:
      return {
        ...oldState,
        AssignedRoleForNotification: payload
      }
    case SAVE_CLINIC_TP_SERVICE_TYPES:
      return {
        ...oldState,
        clinicTpServiceList: payload
      }
    case SAVE_CLINIC_TP_STAFF_TYPES:
      return {
        ...oldState,
        clinicTpStaffList: payload
      }
    case SAVE_CALENDAR_CATEGORY:
      return {
        ...oldState,
        calendarCategoryList: payload
      }
    case SAVE_DEFAULT_CALENDAR_CATEGORYS:
      return {
        ...oldState,
        DefaultCalendarCategories: payload
      }

    case SAVE_SITES_FOR_CLINIC:
      return {
        ...oldState,
        clinicSites: payload
      }

    case SAVE_REMINDER_SETTINGS:
      return {
        ...oldState,
        AppointmentReminderSettings: payload
      }

    case SAVE_AUDIT_LOGS:
      return {
        ...oldState,
        auditLogData: payload
      }
    case SAVE_GLOBAL_SITES_FOR_CLINIC:
      return {
        ...oldState,
        clinicGLobalSites: payload
      }
    case SAVE_REMINDER_LOADINGS:
      return {
        ...oldState,
        reminderSettingsFetched: payload
      }

  }
  return oldState;

}

export const clinicIDReducer = (state = false, action) => {
  switch (action.type) {
    case CLINIC_ID:
      return action.payload;
    default:
      return state;
  }
};

export const getClinicDetails = (state = false, action) => {
  switch (action.type) {
    case GET_CLINIC_DETAILS_BY_ID:
      return action.payload;
    default:
      return state;
  }
};
