import { API_ENDPOINTS } from "./api-endpoints";
import { StaffResponse } from "src/dataModels/clientResponse";
import { customAxios } from "./useExPressApi";
import { GetActiveStaffSeatsResponse, ServiceSetDDLResponse, StaffNotificationPayloadModel,DeleteStaffCredentialResponse } from "src/dataModels/staffResponsesModels";

export const staffServicess = {
    activeStaffSeats: (id?: string) => {
        return customAxios.get<any, GetActiveStaffSeatsResponse>(`${API_ENDPOINTS.GET_ACTIVE_STAFF_SEATS}`);
    },
    serviceSetDDL: (serviceSetFor: string) => {
        return customAxios.get<ServiceSetDDLResponse>(`${API_ENDPOINTS.GET_SERVICE_SET_DDL}${serviceSetFor}`);
    },
    getstaffNotificationSetting: (data: any) => {
        return customAxios.get<ServiceSetDDLResponse>(`${API_ENDPOINTS.GET_STAFF_NOTIFICATION_SETTING}?staffId=${data?.staffId}&alertType=${data?.alertType}`);
    },
    savestaffNotification: (data: StaffNotificationPayloadModel) => {
        return customAxios.post<ServiceSetDDLResponse>(`${API_ENDPOINTS.INSERT_STAFF_NOTIFICATION_SETTING}`, data);
    },
    getstaffNotificationMediumSetting: (staffId: any) => {
        return customAxios.get<ServiceSetDDLResponse>(`${API_ENDPOINTS.GET_STAFF_NOTIFICATION_MEDIUM_SETTING}?staffId=${staffId}`);
    },
    saveStaffNotificationMediumSettings: (data: any) => {
        return customAxios.post<ServiceSetDDLResponse>(`${API_ENDPOINTS.INSERT_STAFF_NOTIFICATION_MEDIUM_SETTING}`, data);
    },
    staffList: () => {
        return customAxios.get<any, StaffResponse>(`${API_ENDPOINTS.GET_STAFF_DDL_BY_CLINIC_ID}true`);
    },
    SaveStaffCredentialInfo: (data: any) => {
        return customAxios.post<any>(`${API_ENDPOINTS.INSERT_STAFF_CREDENTIAL_INFO}`, data);
    },
    DeleteStaffCredential: (staffCredentialId: number) => {
        return customAxios.delete<DeleteStaffCredentialResponse>(`${API_ENDPOINTS.DELETE_STAFF_CREDENTIAL}${staffCredentialId}`);
    },
    getStaffCredentialList: (staffId: any) => {
        return customAxios.get<any,any>(`${API_ENDPOINTS.GET_STAFF_CREDENTIAL_LIST}${staffId}`);
    },
    getStaffCredentialById: (staffCredentialId: any) => {
        return customAxios.get<any,any>(`${API_ENDPOINTS.GET_STAFF_CREDENTIAL_BY_ID}${staffCredentialId}`);
    },
    updateStaffCredential: (data: any) => {
        return customAxios.put<any>(`${API_ENDPOINTS.UPDATE_STAFF_CREDENTIAL}`, data);
    },
    getStaffDetail: (staffId: any) => {
        return customAxios.get<any,any>(`${API_ENDPOINTS.GET_STAFF_BY_ID}${staffId}`);
    },
    getStaffAuditLogList: (payload: any) => {
        return customAxios.post<any,any>(`${API_ENDPOINTS.GET_STAFF_AUDIT_LOG}`, payload);
    },
    assignAdditionalClientsToStaff: (data: any) => {
        return customAxios.post<any>(`${API_ENDPOINTS.ASSIGN_ADDITIONAL_CLIENTS_TO_STAFF}`, data);
    },
    assignStaffToSite: (data: any) => {
        return customAxios.post<any>(`${API_ENDPOINTS.ASSIGN_STAFF_TO_SITE}`, data);
    },
    getRace: () =>{
        return customAxios.get<any>(`${API_ENDPOINTS.GET_RACE}`);
    },
    getEthnicity: () => {
        return customAxios.get<any>(`${API_ENDPOINTS.GET_ETHNICITY}`);
    },
    getCaseloadReportExcel: (data: any) => {
        return customAxios.post<any>(`${API_ENDPOINTS.EXPORT_CASELOAD_REPORT_TO_EXCEL}`, data);
    }
}