import {
  SAVE_BILLING_TRANS_ITEM_BY_CLAIMID,
  SAVE_CLAIM_SERVICE_LINE,
  SAVE_EDI_BY_BATCH_ID,
  SAVE_BILLING_STATUSES,
  SAVE_BILLING_STATUS_ID
} from "../actions/types";

const initialState = {
  serviceLineList: null,
  billingStatusResp: [],
  billingStatusSelectedId:{}

};

export const BillingReducer = (state = initialState, action) => {
  const oldState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case SAVE_CLAIM_SERVICE_LINE:
      return {
        ...oldState,
        serviceLineList: payload,
      };
    case SAVE_BILLING_TRANS_ITEM_BY_CLAIMID:
      return {
        ...oldState,
        billingTransClaim: payload,
      };
    case SAVE_EDI_BY_BATCH_ID:
      return {
        ...oldState,
        EDIResult: payload,
      };
      case SAVE_BILLING_STATUSES:
      return {
        ...oldState,
        billingStatusResp: payload,
      };
      case SAVE_BILLING_STATUS_ID:
      return {
        ...oldState,
        billingStatusSelectedId: payload,
      };
  }
  return oldState;
};
