const GAD = [
  {
    id: 1,
    questionId: "GAD_Q1",
    name: "Feeling nervous, anxious, or on edge",
    value: 0,
  },
  {
    id: 2,
    questionId: "GAD_Q2",
    name: "Not being able to stop or control worrying",
    value: 0,
  },
  {
    id: 3,
    questionId: "GAD_Q3",
    name: "Worrying too much about different things",
    value: 0,
  },
  {
    id: 4,
    questionId: "GAD_Q4",
    name: "Trouble relaxing",
    value: 0,
  },
  {
    id: 5,
    questionId: "GAD_Q5",
    name: "Being so restless that it is hard to sit still",
    value: 0,
  },
  {
    id: 6,
    questionId: "GAD_Q6",
    name: "Becoming easily annoyed or irritable",
    value: 0,
  },
  {
    id: 7,
    questionId: "GAD_Q7",
    name: "Feeling afraid, as if something awful might happen",
    value: 0,
  },
];

const Connor = [
  { id: 1, questionId: "CARS_Q1", name: "RESTLESS AND OVERACTIVE", value: 0 },
  { id: 2, questionId: "CARS_Q2", name: "EXCITABLE,IMPULSIVE", value: 0 },
  { id: 3, questionId: "CARS_Q3", name: "DISTURBS OTHER CHILDREN", value: 0 },
  {
    id: 4,
    questionId: "CARS_Q4",
    name: "FAILS TO FINISH THINGS - SHORT ATTENTION SPAN",
    value: 0,
  },
  {
    id: 5,
    questionId: "CARS_Q5",
    name: "CONSTANTLY FIDGETING",
    value: 0,
  },
  {
    id: 6,
    questionId: "CARS_Q6",
    name: "INATTENTIVE, EASILY DISTRACTED",
    value: 0,
  },
  {
    id: 7,
    questionId: "CARS_Q7",
    name: "DEMAND MUST BE MET IMMEDIATELY - EASILY FRUSTRATED",
    value: 0,
  },
  {
    id: 8,
    questionId: "CARS_Q8",
    name: "CRIES OFTEN AND EASILY",
    value: 0,
  },
  {
    id: 9,
    questionId: "CARS_Q9",
    name: "MOOD CHANGES QUICLY AND DRASTICALLY",
    value: 0,
  },
  {
    id: 10,
    questionId: "CARS_Q10",
    name: "TEMPER OUTBURST, EXPLOSIVE AND UNPREDICTABLE BEHAVIOUR",
    value: 0,
  },
];

const PHQ = [
  {
    id: 1,
    questionId: "PHQ_Q1",
    name: "Little interest or pleasure in doing things",
    value: 0,
  },
  {
    id: 2,
    questionId: "PHQ_Q2",
    name: "Feeling down, depressed, or hopeless",
    value: 0,
  },
  {
    id: 3,
    questionId: "PHQ_Q3",
    name: "Trouble falling or staying asleep, or sleeping too much",
    value: 0,
  },
  {
    id: 4,
    questionId: "PHQ_Q4",
    name: "Feeling tired or having little energy",
    value: 0,
  },
  {
    id: 5,
    questionId: "PHQ_Q5",
    name: "Poor appetite or overeating",
    value: 0,
  },
  {
    id: 6,
    questionId: "PHQ_Q6",
    name: "Feeling bad about yourself or that you are a failure or have let yourself or your family down",
    value: 0,
  },
  {
    id: 7,
    questionId: "PHQ_Q7",
    name: "Trouble concentrating on things, such as reading the newspaper or watching television",
    value: 0,
  },
  {
    id: 8,
    questionId: "PHQ_Q8",
    name: "Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or restless that you have been moving around a lot more than usual",
    value: 0,
  },
  {
    id: 9,
    questionId: "PHQ_Q9",
    name: "Thoughts that you would be better off dead, or of hurting yourself",
    value: 0,
  },
];

const PrimeScreenQuestionName = [
  {
    id: 1,
    questionId: "PS_Q1",
    name: "I think that I have felt that there are odd or unusual things going on that I can\'t explain",
    value: null,
  },
  {
    id: 2,
    questionId: "PS_Q2",
    name: "I think that I might be able to predict the future",
    value: null,
  },
  {
    id: 3,
    questionId: "PS_Q3",
    name: "I may have felt that there could possibly be something interrupting or controlling my thoughts, feelings, or actions",
    value: null,
  },
  {
    id: 4,
    questionId: "PS_Q4",
    name: "I have had the experience of doing something differently because of my superstitions",
    value: null,
  },
  {
    id: 5,
    questionId: "PS_Q5",
    name: "I think that I may get confused at times whether something I experience or perceive may be real or may be just part of my imagination or dreams",
    value: null,
  },
  {
    id: 6,
    questionId: "PS_Q6",
    name: "I have thought that it might be possible that other people can read my mind, or that I can read other\'s minds",
    value: null,
  },
  {
    id: 7,
    questionId: "PS_Q7",
    name: "I wonder if people may be planning to hurt me or even may be about to hurt me",
    value: null,
  },
  {
    id: 8,
    questionId: "PS_Q8",
    name: "I believe that I have special natural or supernatural gifts beyond my talents and natural strengths",
    value: null,
  },
  {
    id: 9,
    questionId: "PS_Q9",
    name: "I think I might feel like my mind is “playing tricks” on me",
    value: null,
  },
  {
    id: 10,
    questionId: "PS_Q10",
    name: "I have had the experience of hearing faint or clear sounds of people or a person mumbling or talking when there is no one near me",
    value: null,
  },
  {
    id: 11,
    questionId: "PS_Q11",
    name: "I think that I may hear my own thoughts being said out loud",
    value: null,
  },
  {
    id: 12,
    questionId: "PS_Q12",
    name: "I have been concerned that I might be “going crazy”",
    value: null,
  },
];

const ACE = [
  {
    id: 1,
    questionId: "ACE_Q1",
    name: "Did you lose a parent through divorce, abandonment, death, or other reason?",
    value: false,
    type: "checkbox",
  },
  {
    id: 2,
    questionId: "ACE_Q2",
    name: "Did you live with anyone who was depressed, mentally ill, or attempted suicide?",
    value: false,
    type: "checkbox",
  },
  {
    id: 3,
    questionId: "ACE_Q3",
    name: "Did you feel that you didn\'t have enough to eat, had to wear dirty clothes, or had no one to protect or take care of you?",
    value: false,
    type: "checkbox",
  },
  {
    id: 4,
    questionId: "ACE_Q4",
    name: "Did your parents or adultsin your home ever hit, punch, beat, or threaten to harm each other?",
    value: false,
    type: "checkbox",
  },
  {
    id: 5,
    questionId: "ACE_Q5",
    name: "Did you live with anyone who went to jail or prison?",
    value: false,
    type: "checkbox",
  },
  {
    id: 6,
    questionId: "ACE_Q6",
    name: "Did a parent or adult in your home ever swear at you, insult you, or put you down?",
    value: false,
    type: "checkbox",
  },
  {
    id: 7,
    questionId: "ACE_Q7",
    name: " Did a parent or adult in your home ever hit, beat, kick, or physically hurt you in any way?",
    value: false,
    type: "checkbox",
  },
  {
    id: 8,
    questionId: "ACE_Q8",
    name: "Did you feel that no one in your family loved you or thought you were special?",
    value: false,
    type: "checkbox",
  },
  {
    id: 9,
    questionId: "ACE_Q9",
    name: "Did you experience unwanted sexual contact (such as fondling or oral/anal/vaginal intercourse/penetration)?",
    value: false,
    type: "checkbox",
  },
  {
    id: 10,
    questionId: "ACE_Q10",
    name: "Did you live with anyone who had a problem with drinking or using drugs, including prescription drugs?",
    value: false,
    type: "checkbox",
  },
  {
    id: 11,
    questionId: "ACE_Q11",
    name: "Do you believe that these experiences have affected your health?",
    value: false,
    type: "radio",
    buttons: [
      { label: "Never", value: "0" },
      { label: "Occasionally", value: "1" },
      { label: "Half of the time", value: "2" },
      { label: "Most of the time", value: "3" },
      { label: "All of the time", value: "4" },
    ],
  },
];

const CATS = [
  {
    id: 1,
    questionId: "CATS_Q1",
    name: "Serious natural disaster like a flood, tornado, hurricane, earthquake, or fire.",
    value: 0,
  },
  {
    id: 2,
    questionId: "CATS_Q2",
    name: "Serious accident or injury like a car/bike crash, dog bite, sports injury.",
    value: 0,
  },
  {
    id: 3,
    questionId: "CATS_Q3",
    name: "Robbed by threat, force or weapon. ",
    value: 0,
  },
  {
    id: 4,
    questionId: "CATS_Q4",
    name: "Slapped, punched, or beat up in your family.",
    value: 0,
  },
  {
    id: 5,
    questionId: "CATS_Q5",
    name: "Slapped, punched, or beat up by someone not in your family.",
    value: 0,
  },
  {
    id: 6,
    questionId: "CATS_Q6",
    name: "Seeing someone in your family get slapped, punched or beat up.",
    value: 0,
  },
  {
    id: 7,
    questionId: "CATS_Q7",
    name: "Seeing someone in the community get slapped, punched or beat up.",
    value: 0,
  },
  {
    id: 8,
    questionId: "CATS_Q8",
    name: "Someone older touching your private parts when they shouldn\'t.",
    value: 0,
  },
  {
    id: 9,
    questionId: "CATS_Q9",
    name: "Someone forcing or pressuring sex, or when you couldn\'t say no.",
    value: 0,
  },
  {
    id: 10,
    questionId: "CATS_Q10",
    name: "Someone close to you dying suddenly or violently.",
    value: 0,
  },
  {
    id: 11,
    questionId: "CATS_Q11",
    name: "Attacked, stabbed, shot at or hurt badly.",
    value: 0,
  },
  {
    id: 12,
    questionId: "CATS_Q12",
    name: "Seeing someone attacked, stabbed, shot at, hurt badly or killed.",
    value: 0,
  },
  {
    id: 13,
    questionId: "CATS_Q13",
    name: "Stressful or scary medical procedure.",
    value: 0,
  },
  {
    id: 14,
    questionId: "CATS_Q14",
    name: "Being around war.",
    value: 0,
  },
  {
    id: 15,
    questionId: "CATS_Q15",
    name: "Other stressful or scary event?",
    type: "question",
    value: "",
    isSelected: null,
  },
];

const RecentValues = [
  {
    id: 1,
    questionId: "RI_Q1",
    name: "Upsetting thoughts or pictures about what happened that pop into your head. ",
    value: 0,
  },
  {
    id: 2,
    questionId: "RI_Q2",
    name: "Bad dreams reminding you of what happened.",
    value: 0,
  },
  {
    id: 3,
    questionId: "RI_Q3",
    name: "Feeling as if what happened is happening all over again.",
    value: 0,
  },
  {
    id: 4,
    questionId: "RI_Q4",
    name: "Feeling very upset when you are reminded of what happened.",
    value: 0,
  },
  {
    id: 5,
    questionId: "RI_Q5",
    name: "Strong feelings in your body when you are reminded of what happened (sweating, heart beating fast, upset stomach).  ",
    value: 0,
  },
  {
    id: 6,
    questionId: "RI_Q6",
    name: "Trying not to think about or talk about what happened. Or to not have feelings about it. ",
    value: 0,
  },
  {
    id: 7,
    questionId: "RI_Q7",
    name: "Staying away from people, places, things, or situations that remind you of what happened.  ",
    value: 0,
  },
  {
    id: 8,
    questionId: "RI_Q8",
    name: "Not being able to remember part of what happened. ",
    value: 0,
  },
  {
    id: 9,
    questionId: "RI_Q9",
    name: "Negative thoughts about yourself or others. Thoughts like I won\'t have a good life, no one can be trusted, the whole world is unsafe. ",
    value: 0,
  },
  {
    id: 10,
    questionId: "RI_Q10",
    name: " Blaming yourself for what happened, or blaming someone else when it isn\'t their fault. ",
    value: 0,
  },
  {
    id: 11,
    questionId: "RI_Q11",
    name: "Bad feelings (afraid, angry, guilty, ashamed) a lot of the time.",
    value: 0,
  },
  {
    id: 12,
    questionId: "RI_Q12",
    name: "Not wanting to do things you used to do. ",
    value: 0,
  },
  {
    id: 13,
    questionId: "RI_Q13",
    name: "Not feeling close to people.  ",
    value: 0,
  },
  {
    id: 14,
    questionId: "RI_Q14",
    name: "Not being able to have good or happy feelings. ",
    value: 0,
  },
  {
    id: 15,
    questionId: "RI_Q15",
    name: "Feeling mad. Having fits of anger and taking it out on others. ",
    value: 0,
  },
  {
    id: 16,
    questionId: "RI_Q16",
    name: "Doing unsafe things. ",
    value: 0,
  },
  {
    id: 17,
    questionId: "RI_Q17",
    name: "Being overly careful or on guard (checking to see who is around you). ",
    value: 0,
  },
  {
    id: 18,
    questionId: "RI_Q18",
    name: "Being jumpy. ",
    value: 0,
  },
  {
    id: 19,
    questionId: "RI_Q19",
    name: "Problems paying attention. ",
    value: 0,
  },
  {
    id: 20,
    questionId: "RI_Q20",
    name: "Trouble falling or staying asleep. ",
    value: 0,
  },
];

const Interference = [
  {
    id: 1,
    questionId: "ITF_Q1",
    name: "Getting along with others ",
    value: 0,
  },
  {
    id: 2,
    questionId: "ITF_Q2",
    name: "Hobbies/Fun  ",
    value: 0,
  },
  {
    id: 3,
    questionId: "ITF_Q3",
    name: "School or work  ",
    value: 0,
  },
  {
    id: 4,
    questionId: "ITF_Q4",
    name: "Family relationships ",
    value: 0,
  },
  {
    id: 5,
    questionId: "ITF_Q5",
    name: "General happiness  ",
    value: 0,
  },
];

const MoodDisorderQuestions = [
  {
    id: 1,
    questionId: "MDQ_SQ1",
    name: " ...you felt so good or so hyper that other people thought you were not your  normal self or you were so hyper that you got into trouble?",
    value: null,
  },
  {
    id: 2,
    questionId: "MDQ_SQ2",
    name: " ...you were so irritable that you shouted at people or started fights or arguments?",
    value: null,
  },
  {
    id: 3,
    questionId: "MDQ_Q3",
    name: "...you felt much more self-confident than usual?",
    value: null,
  },
  {
    id: 4,
    questionId: "MDQ_Q4",
    name: "...you got much less sleep than usual and found you didn\'t really miss it?",
    value: null,
  },
  {
    id: 5,
    questionId: "MDQ_Q5",
    name: "...you were much more talkative or spoke much faster than usual?",
    value: null,
  },
  {
    id: 6,
    questionId: "MDQ_Q6",
    name: "...thoughts raced through your head or you couldn\'t slow your mind down?",
    value: null,
  },
  {
    id: 7,
    questionId: "MDQ_Q7",
    name: "...you were so easily distracted by things around you that you had trouble concentrating or staying on track?",
    value: null,
  },
  {
    id: 8,
    questionId: "MDQ_Q8",
    name: "...you had much more energy than usual?",
    value: null,
  },
  {
    id: 9,
    questionId: "MDQ_Q9",
    name: "...you were much more active or did many more things than usual?",
    value: null,
  },
  {
    id: 10,
    questionId: "MDQ_Q10",
    name: "...you were much more social or outgoing than usual, for example, you telephoned friends in the middle of the night?",
    value: null,
  },
  {
    id: 11,
    questionId: "MDQ_Q11",
    name: "...you were much more interested in sex than usual?",
    value: null,
  },
  {
    id: 12,
    questionId: "MDQ_Q12",
    name: "...you did things that were unusual for you or that other people might have thought were excessive, foolish, or risky?",
    value: null,
  },
  {
    id: 13,
    questionId: "MDQ_Q13",
    name: "...spending money got you or your family into trouble?",
    value: null,
  },
];

const MoodDisorder = [
  {
    id: 1,
    questionId: "MDQ_Q1",
    name: " Has there ever been a period of time when you were not your usual self and...",
    value: Array(MoodDisorderQuestions.length).fill(null),
    type: null,
    question: MoodDisorderQuestions,
  },
  {
    id: 2,
    questionId: "MDQ_Q2",
    name: "If you checked YES to more than one of the above, have several of these ever happened during the same period of time?",
    value: null,
    type: "boolean",
    isSelected: null,
    openId: 3,
  },
  {
    id: 3,
    questionId: "MDQ_Q3",
    name: "How much of a problem did any of these cause you - like being unable to work; having family, money or legal troubles; getting into arguments or fights?",
    value: 0,
    type: "dropdown",
    isOpen: 0,
    option: [
      {
        label: "No problem",
        value: 0,
      },
      {
        label: "Minor problem ",
        value: 1,
      },
      {
        label: "Moderate problem",
        value: 2,
      },
      {
        label: "Serious problem",
        value: 3,
      },
    ],
  },
  {
    id: 4,
    questionId: "MDQ_Q4",
    name: " Have any of your blood relatives (i.e. children, siblings, parents, grandparents, aunts, uncles) had manic-depressive illness or bipolar disorder?",
    value: null,
    type: "boolean",
  },
  {
    id: 5,
    questionId: "MDQ_Q5",
    name: " Has a health professional ever told you that you have manic-depressive illness or bipolar disorder?",
    value: null,
    type: "boolean",
  },
];


const NICHQ = [
  {
    id: 1,
    questionId: "NICH_Q1",
    name: "Does not pay attention to details or makes careless mistakes with, for example, homework",
    value: 0,
  },
  {
    id: 2,
    questionId: "NICH_Q2",
    name: "Has difficulty keeping attention to what needs to be done",
    value: 0,
  },
  {
    id: 3,
    questionId: "NICH_Q3",
    name: "Does not seem to listen when spoken to directly",
    value: 0,
  },
  {
    id: 4,
    questionId: "NICH_Q4",
    name: "Does not follow through when given directions and fails to finish activities (not due to refusal or failure to understand)",
    value: 0,
  },
  {
    id: 5,
    questionId: "NICH_Q5",
    name: "Has difficulty organizing tasks and activities",
    value: 0,
  },
  {
    id: 6,
    questionId: "NICH_Q6",
    name: "Avoids, dislikes, or does not want to start tasks that require ongoing mental effort",
    value: 0,
  },
  {
    id: 7,
    questionId: "NICH_Q7",
    name: "Loses things necessary for tasks or activities (toys, assignments, pencils or books)",
    value: 0,
  },
  {
    id: 8,
    questionId: "NICH_Q8",
    name: "Is easily distracted by noises or other stimuli",
    value: 0,
  },
  {
    id: 9,
    questionId: "NICH_Q9",
    name: "Is forgetful in daily activities",
    value: 0,
  },
  {
    id: 10,
    questionId: "NICH_Q10",
    name: "Fidgets with hands or feet or squirms in seat",
    value: 0,
  },
  {
    id: 11,
    questionId: "NICH_Q11",
    name: " Leaves seat when remaining seated is expected",
    value: 0,
  },
  {
    id: 12,
    questionId: "NICH_Q12",
    name: " Runs about or climbs too much when remaining seated is expected",
    value: 0,
  },
  {
    id: 13,
    questionId: "NICH_Q13",
    name: "Has difficulty playing or beginning quiet play activities",
    value: 0,
  },
  {
    id: 14,
    questionId: "NICH_Q14",
    name: "Is “on the go” or often acts as if “driven by a motor” ",
    value: 0,
  },
  {
    id: 15,
    questionId: "NICH_Q15",
    name: "Talks too much",
    value: 0,
  },
  {
    id: 16,
    questionId: "NICH_Q16",
    name: "Blurts out answers before questions have been completed",
    value: 0,
  },
  {
    id: 17,
    questionId: "NICH_Q17",
    name: "Has difficulty waiting his or her turn",
    value: 0,
  },
  {
    id: 18,
    questionId: "NICH_Q18",
    name: "Interrupts or intrudes in on others\' conversations and/or activities",
    value: 0,
  },
  {
    id: 19,
    questionId: "NICH_Q19",
    name: "Argues with adults",
    value: 0,
  },
  {
    id: 20,
    questionId: "NICH_Q20",
    name: "Loses temper",
    value: 0,
  },
  {
    id: 21,
    questionId: "NICH_Q21",
    name: "Actively defies or refuses to go along with adults\' requests or rules",
    value: 0,
  },
  {
    id: 22,
    questionId: "NICH_Q22",
    name: "Deliberately annoys people",
    value: 0,
  },
  {
    id: 23,
    questionId: "NICH_Q23",
    name: "Blames others for his or her mistakes or misbehaviors",
    value: 0,
  },
  {
    id: 24,
    questionId: "NICH_Q24",
    name: "Is touchy or easily annoyed by others",
    value: 0,
  },
  {
    id: 25,
    questionId: "NICH_Q25",
    name: "Is angry or resentful",
    value: 0,
  },
  {
    id: 26,
    questionId: "NICH_Q26",
    name: "Is spiteful and wants to get even",
    value: 0,
  },
  {
    id: 27,
    questionId: "NICH_Q27",
    name: "Bullies, threatens, or intimidates others",
    value: 0,
  },
  {
    id: 28,
    questionId: "NICH_Q28",
    name: "Starts physical fights",
    value: 0,
  },
  {
    id: 29,
    questionId: "NICH_Q29",
    name: "Lies to get out of trouble or to avoid obligations (ie,“cons” others)",
    value: 0,
  },
  {
    id: 30,
    questionId: "NICH_Q30",
    name: "Is truant from school (skips school) without permission",
    value: 0,
  },
  {
    id: 31,
    questionId: "NICH_Q31",
    name: "Is physically cruel to people",
    value: 0,
  },
  {
    id: 32,
    questionId: "NICH_Q32",
    name: "Has stolen things that have value",
    value: 0,
  },
  {
    id: 33,
    questionId: "NICH_Q33",
    name: "Deliberately destroys others\' property",
    value: 0,
  },
  {
    id: 34,
    questionId: "NICH_Q34",
    name: "Has used a weapon that can cause serious harm (bat, knife, brick, gun)",
    value: 0,
  },
  {
    id: 35,
    questionId: "NICH_Q35",
    name: "Is physically cruel to animals",
    value: 0,
  },
  {
    id: 36,
    questionId: "NICH_Q36",
    name: "Has deliberately set fires to cause damage",
    value: 0,
  },
  {
    id: 37,
    questionId: "NICH_Q37",
    name: "Has broken into someone else\'s home, business, or car",
    value: 0,
  },
  {
    id: 38,
    questionId: "NICH_Q38",
    name: "Has stayed out at night without permission ",
    value: 0,
  },
  {
    id: 39,
    questionId: "NICH_Q39",
    name: "Has run away from home overnight",
    value: 0,
  },
  {
    id: 40,
    questionId: "NICH_Q40",
    name: "Has forced someone into sexual activity",
    value: 0,
  },
  {
    id: 41,
    questionId: "NICH_Q41",
    name: "Is fearful, anxious, or worried",
    value: 0,
  },
  {
    id: 42,
    questionId: "NICH_Q42",
    name: "Is afraid to try new things for fear of making mistakes",
    value: 0,
  },
  {
    id: 43,
    questionId: "NICH_Q43",
    name: "Feels worthless or inferior",
    value: 0,
  },
  {
    id: 44,
    questionId: "NICH_Q44",
    name: "Blames self for problems, feels guilty",
    value: 0,
  },
  {
    id: 45,
    questionId: "NICH_Q45",
    name: "Feels lonely, unwanted, or unloved; complains that “no one loves him or her”",
    value: 0,
  },
  {
    id: 46,
    questionId: "NICH_Q46",
    name: "Is sad, unhappy, or depressed",
    value: 0,
  },
  {
    id: 47,
    questionId: "NICH_Q47",
    name: "Is self-conscious or easily embarrassed",
    value: 0,
  }
]


const NICHQ_PARENT_PERFORMANCE = [
  {
    id: 48,
    questionId: "NICH_PP1", //48......55
    name: "Overall school performance",
    value: 1,
  },
  {
    id: 49,
    questionId: "NICH_PP2",
    name: "Reading",
    value: 1,
  },
  {
    id: 50,
    questionId: "NICH_PP3",
    name: "Writing",
    value: 1,
  },
  {
    id: 51,
    questionId: "NICH_PP4",
    name: "Mathematics",
    value: 1,
  },
  {
    id: 52,
    questionId: "NICH_PP5",
    name: "Relationship with parents",
    value: 1,
  },
  {
    id: 53,
    questionId: "NICH_PP6",
    name: "Relationship with siblings",
    value: 1,
  },
  {
    id: 54,
    questionId: "NICH_PP7",
    name: "Relationship with peers",
    value: 1,
  },
  {
    id: 55,
    questionId: "NICH_PP8",
    name: "Participation in organized activities (eg, teams)",
    value: 1,
  }
]


const NICHQ_TEACHER_ASSESSMENT = [
  {
    id: 1,
    questionId: "NICHQ_TA1",// Till1- 35
    name: "Fails to give attention to details or makes careless mistakes in schoolwork",
    value: 0,
  },
  {
    id: 2,
    questionId: "NICHQ_TA2",
    name: "Has difficulty sustaining attention to tasks or activities ",
    value: 0,
  },
  {
    id: 3,
    questionId: "NICHQ_TA3",
    name: "Does not seem to listen when spoken to directly",
    value: 0,
  },
  {
    id: 4,
    questionId: "NICHQ_TA4",
    name: "Does not follow through on instructions and fails to finish schoolwork (not due to oppositional behavior or failure to understand)",
    value: 0,
  },
  {
    id: 5,
    questionId: "NICHQ_TA5",
    name: "Has difficulty organizing tasks and activities",
    value: 0,
  },
  {
    id: 6,
    questionId: "NICHQ_TA6",
    name: "Avoids, dislikes, or is reluctant to engage in tasks that require sustained mental effort",
    value: 0,
  },
  {
    id: 7,
    questionId: "NICHQ_TA7",
    name: "Loses things necessary for tasks or activities (school assignments, pencils, or books)",
    value: 0,
  },
  {
    id: 8,
    questionId: "NICHQ_TA8",
    name: "Is easily distracted by extraneous stimuli",
    value: 0,
  },
  {
    id: 9,
    questionId: "NICHQ_TA9",
    name: "Is forgetful in daily activities",
    value: 0,
  },
  {
    id: 10,
    questionId: "NICHQ_TA10",
    name: "Fidgets with hands or feet or squirms in seat",
    value: 0,
  },
  {
    id: 11,
    questionId: "NICHQ_TA11",
    name: "Leaves seat in classroom or in other situations in which remaining seated is expected",
    value: 0,
  },
  {
    id: 12,
    questionId: "NICHQ_TA12",
    name: "Runs about or climbs excessively in situations in which remaining seated is expected",
    value: 0,
  },
  {
    id: 13,
    questionId: "NICHQ_TA13",
    name: "Has difficulty playing or engaging in leisure activities quietly",
    value: 0,
  },
  {
    id: 14,
    questionId: "NICHQ_TA14",
    name: "Is “on the go” or often acts as if “driven by a motor”",
    value: 0,
  },
  {
    id: 15,
    questionId: "NICHQ_TA15",
    name: "Talks excessively",
    value: 0,
  },
  {
    id: 16,
    questionId: "NICHQ_TA16",
    name: "Blurts out answers before questions have been completed",
    value: 0,
  },
  {
    id: 17,
    questionId: "NICHQ_TA17",
    name: "Has difficulty waiting in line ",
    value: 0,
  },
  {
    id: 18,
    questionId: "NICHQ_TA18",
    name: "Interrupts or intrudes on others (eg, butts into conversations/games)",
    value: 0,
  },
  {
    id: 19,
    questionId: "NICHQ_TA19",
    name: "Loses temper",
    value: 0,
  },
  {
    id: 20,
    questionId: "NICHQ_TA20",
    name: "Actively defies or refuses to comply with adult\'s requests or rules",
    value: 0,
  },
  {
    id: 21,
    questionId: "NICHQ_TA21",
    name: "Is angry or resentful",
    value: 0,
  },
  {
    id: 22,
    questionId: "NICHQ_TA22",
    name: "Is spiteful and vindictive",
    value: 0,
  },
  {
    id: 23,
    questionId: "NICHQ_TA23",
    name: "Bullies, threatens, or intimidates others",
    value: 0,
  },
  {
    id: 24,
    questionId: "NICHQ_TA24",
    name: "Initiates physical fights",
    value: 0,
  },
  {
    id: 25,
    questionId: "NICHQ_TA25",
    name: "Lies to obtain goods for favors or to avoid obligations (eg, “cons” others)",
    value: 0,
  },
  {
    id: 26,
    questionId: "NICHQ_TA26",
    name: "Is physically cruel to people",
    value: 0,
  },
  {
    id: 27,
    questionId: "NICHQ_TA27",
    name: "Has stolen items of nontrivial value",
    value: 0,
  },
  {
    id: 28,
    questionId: "NICHQ_TA28",
    name: "Deliberately destroys others\' property",
    value: 0,
  },
  {
    id: 29,
    questionId: "NICHQ_TA29",
    name: "Is fearful, anxious, or worried",
    value: 0,
  },
  {
    id: 30,
    questionId: "NICHQ_TA30",
    name: "Is self-conscious or easily embarrassed",
    value: 0,
  },
  {
    id: 31,
    questionId: "NICHQ_TA31",
    name: "Is afraid to try new things for fear of making mistakes",
    value: 0,
  },
  {
    id: 32,
    questionId: "NICHQ_TA32",
    name: "Feels worthless or inferior",
    value: 0,
  },
  {
    id: 33,
    questionId: "NICHQ_TA33",
    name: "Blames self for problems; feels guilty",
    value: 0,
  },
  {
    id: 34,
    questionId: "NICHQ_TA34",
    name: "Feels lonely, unwanted, or unloved; complains that “no one loves him or her”",
    value: 0,
  },
  {
    id: 35,
    questionId: "NICHQ_TA35",
    name: "Is sad, unhappy, or depressed",
    value: 0,
  }
]


const NICHQ_TEACHER_PERFORMANCE = [
  {
    id: 36,
    questionId: "NICHQ_TP1",// FROM 36-38
    name: "Reading",
    value: 1,
  },
  {
    id: 37,
    questionId: "NICHQ_TP2",
    name: "Mathematics",
    value: 1,
  },
  {
    id: 38,
    questionId: "NICHQ_TP3",
    name: "Written expression",
    value: 1,
  }
]

const NICHQ_TEACHER_BEH_PERFORMANCE = [
  {
    id: 39,
    questionId: "NICHQ_TP4",// FROM 39-43
    name: "Relationship with peers",
    value: 1,
  },
  {
    id: 40,
    questionId: "NICHQ_TP5",
    name: "Following directions",
    value: 1,
  },
  {
    id: 41,
    questionId: "NICHQ_TP6",
    name: "Disrupting class",
    value: 1,
  },
  {
    id: 42,
    questionId: "NICHQ_TP7",
    name: "Assignment completion",
    value: 1,
  },
  {
    id: 43,
    questionId: "NICHQ_TP8",
    name: "Organizational skills",
    value: 1,
  }
]



const NICHQ_FOLLOWUP_PARENT = [
  {
    id: 1,
    questionId: "NICHQ_FP1",// FROM 1-18
    name: "Does not pay attention to details or makes careless mistakes with, for example, homework",
    value: 0,
  },
  {
    id: 2,
    questionId: "NICHQ_FP2",
    name: "Has difficulty keeping attention to what needs to be done",
    value: 0,
  },
  {
    id: 3,
    questionId: "NICHQ_FP3",
    name: "Does not seem to listen when spoken to directly",
    value: 0,
  },
  {
    id: 4,
    questionId: "NICHQ_FP4",
    name: "Does not follow through when given directions and fails to finish activities (not due to refusal or failure to understand)",
    value: 0,
  },
  {
    id: 5,
    questionId: "NICHQ_FP5",
    name: "Has difficulty organizing tasks and activities ",
    value: 0,
  },
  {
    id: 6,
    questionId: "NICHQ_FP6",
    name: "Avoids, dislikes, or does not want to start tasks that require ongoing mental effort",
    value: 0,
  },
  {
    id: 7,
    questionId: "NICHQ_FP7",
    name: "Loses things necessary for tasks or activities (toys, assignments, pencils, or books)",
    value: 0,
  },
  {
    id: 8,
    questionId: "NICHQ_FP8",
    name: "Is easily distracted by noises or other stimuli",
    value: 0,
  },
  {
    id: 9,
    questionId: "NICHQ_FP9",
    name: "Is forgetful in daily activities",
    value: 0,
  },
  {
    id: 10,
    questionId: "NICHQ_FP10",
    name: "Fidgets with hands or feet or squirms in seat",
    value: 0,
  },
  {
    id: 11,
    questionId: "NICHQ_FP11",
    name: "Leaves seat when remaining seated is expected",
    value: 0,
  },
  {
    id: 12,
    questionId: "NICHQ_FP12",
    name: "Runs about or climbs too much when remaining seated is expected",
    value: 0,
  },
  {
    id: 13,
    questionId: "NICHQ_FP13",
    name: "Has difficulty playing or beginning quiet play activities",
    value: 0,
  },
  {
    id: 14,
    questionId: "NICHQ_FP14",
    name: "Is “on the go” or often acts as if “driven by a motor”",
    value: 0,
  },
  {
    id: 15,
    questionId: "NICHQ_FP15",
    name: "Talks too much",
    value: 0,
  },
  {
    id: 16,
    questionId: "NICHQ_FP16",
    name: "Blurts out answers before questions have been completed",
    value: 0,
  },
  {
    id: 17,
    questionId: "NICHQ_FP17",
    name: "Has difficulty waiting his or her turn",
    value: 0,
  },
  {
    id: 18,
    questionId: "NICHQ_FP18",
    name: "Interrupts or intrudes in on others\' conversations and/or activities",
    value: 0,
  }
]

const NICHQ_FOLLOWUP_PARENT_PERFORMANCE = [
  {
    id: 19,
    questionId: "NICHQ_FPP1",// FROM 19-26
    name: "Overall school performance",
    value: 1,
  },
  {
    id: 20,
    questionId: "NICHQ_FPP2",
    name: "Reading",
    value: 1,
  },
  {
    id: 21,
    questionId: "NICHQ_FPP3",
    name: "Writing",
    value: 1,
  },
  {
    id: 22,
    questionId: "NICHQ_FPP4",
    name: "Mathematics",
    value: 1,
  },
  {
    id: 23,
    questionId: "NICHQ_FPP5",
    name: "Relationship with parents",
    value: 1,
  },
  {
    id: 24,
    questionId: "NICHQ_FPP6",
    name: "Relationship with siblings",
    value: 1,
  },
  {
    id: 25,
    questionId: "NICHQ_FPP7",
    name: "Relationship with peers",
    value: 1,
  },
  {
    id: 26,
    questionId: "NICHQ_FPP8",
    name: "Participation in organized activities (eg, teams)",
    value: 1,
  }
]


const NICHQ_FOLLOWUP_PARENT_SIDE_EFFECT = [
  {
    id: 1,
    questionId: "NICHQ_FPS1",//NO NO
    name: "Headache",
    value: 0,
  },
  {
    id: 2,
    questionId: "NICHQ_FPS2",
    name: "Stomachache",
    value: 0,
  },
  {
    id: 3,
    questionId: "NICHQ_FPS3",
    name: "Change of appetite—explain below",
    value: 0,
  },
  {
    id: 4,
    questionId: "NICHQ_FPS4",
    name: "Trouble sleeping",
    value: 0,
  },
  {
    id: 5,
    questionId: "NICHQ_FPS5",
    name: "Irritability in the late morning, late afternoon, or evening—explain below",
    value: 0,
  },
  {
    id: 6,
    questionId: "NICHQ_FPS6",
    name: "Socially withdrawn—decreased interaction with others",
    value: 0,
  },
  {
    id: 7,
    questionId: "NICHQ_FPS7",
    name: "Extreme sadness or unusual crying",
    value: 0,
  },
  {
    id: 8,
    questionId: "NICHQ_FPS8",
    name: "Dull, tired, listless behavior",
    value: 0,
  },
  {
    id: 9,
    questionId: "NICHQ_FPS9",
    name: "Tremors/feeling shaky",
    value: 0,
  },
  {
    id: 10,
    questionId: "NICHQ_FPS10",
    name: "Repetitive movements, tics, jerking, twitching, eye blinking—explain below",
    value: 0,
  },
  {
    id: 11,
    questionId: "NICHQ_FPS11",
    name: "Picking at skin or fingers, nail biting, lip or cheek chewing—explain below",
    value: 0,
  },
  {
    id: 12,
    questionId: "NICHQ_FPS12",
    name: "Sees or hears things that aren\'t there",
    value: 0,
  }
]

const NICHQ_FOLLOWUP_TEACHER = [
  {
    id: 1,
    questionId: "NICHQ_FT1",// FROM 1-18
    name: "Does not pay attention to details or makes careless mistakes with, for example, homework",
    value: 0,
  },
  {
    id: 2,
    questionId: "NICHQ_FT2",
    name: "Has difficulty keeping attention to what needs to be done",
    value: 0,
  },
  {
    id: 3,
    questionId: "NICHQ_FT3",
    name: "Does not seem to listen when spoken to directly",
    value: 0,
  },
  {
    id: 4,
    questionId: "NICHQ_FT4",
    name: "Does not follow through when given directions and fails to finish activities (not due to refusal or failure to understand)",
    value: 0,
  },
  {
    id: 5,
    questionId: "NICHQ_FT5",
    name: "Has difficulty organizing tasks and activities ",
    value: 0,
  },
  {
    id: 6,
    questionId: "NICHQ_FT6",
    name: "Avoids, dislikes, or does not want to start tasks that require ongoing mental effort",
    value: 0,
  },
  {
    id: 7,
    questionId: "NICHQ_FT7",
    name: "Loses things necessary for tasks or activities (toys, assignments, pencils, or books)",
    value: 0,
  },
  {
    id: 8,
    questionId: "NICHQ_FT8",
    name: "Is easily distracted by noises or other stimuli",
    value: 0,
  },
  {
    id: 9,
    questionId: "NICHQ_FT9",
    name: "Is forgetful in daily activities",
    value: 0,
  },
  {
    id: 10,
    questionId: "NICHQ_FT10",
    name: "Fidgets with hands or feet or squirms in seat",
    value: 0,
  },
  {
    id: 11,
    questionId: "NICHQ_FT11",
    name: "Leaves seat when remaining seated is expected",
    value: 0,
  },
  {
    id: 12,
    questionId: "NICHQ_FT12",
    name: "Runs about or climbs too much when remaining seated is expected",
    value: 0,
  },
  {
    id: 13,
    questionId: "NICHQ_FT13",
    name: "Has difficulty playing or beginning quiet play activities",
    value: 0,
  },
  {
    id: 14,
    questionId: "NICHQ_FT14",
    name: "Is “on the go” or often acts as if “driven by a motor”",
    value: 0,
  },
  {
    id: 15,
    questionId: "NICHQ_FT15",
    name: "Talks too much",
    value: 0,
  },
  {
    id: 16,
    questionId: "NICHQ_FT16",
    name: "Blurts out answers before questions have been completed",
    value: 0,
  },
  {
    id: 17,
    questionId: "NICHQ_FT17",
    name: "Has difficulty waiting his or her turn",
    value: 0,
  },
  {
    id: 18,
    questionId: "NICHQ_FT18",
    name: "Interrupts or intrudes in on others\' conversations and/or activities",
    value: 0,
  }
]

const NICHQ_FOLLOWUP_TEACHER_PERFORMANCE = [
  {
    id: 19,
    questionId: "NICHQ_FTP1",// FROM 19-26
    name: "Reading",
    value: 1,
  },
  {
    id: 20,
    questionId: "NICHQ_FTP2",
    name: "Mathematics",
    value: 1,
  },
  {
    id: 21,
    questionId: "NICHQ_FTP3",
    name: "Written expression",
    value: 1,
  },
  {
    id: 22,
    questionId: "NICHQ_FTP4",
    name: "Relationship with peers",
    value: 1,
  },
  {
    id: 23,
    questionId: "NICHQ_FTP5",
    name: "Following direction",
    value: 1,
  },
  {
    id: 24,
    questionId: "NICHQ_FTP6",
    name: "Disrupting class ",
    value: 1,
  },
  {
    id: 25,
    questionId: "NICHQ_FTP7",
    name: "Assignment completion",
    value: 1,
  },
  {
    id: 26,
    questionId: "NICHQ_FTP8",
    name: "Organizational skills",
    value: 1,
  }
]

const NICHQ_FOLLOWUP_TEACHER_SIDE_EFFECT = [
  {
    id: 1,
    questionId: "NICHQ_FTS1",
    name: "Headache",
    value: 0,
  },
  {
    id: 2,
    questionId: "NICHQ_FTS2",
    name: "Stomachache",
    value: 0,
  },
  {
    id: 3,
    questionId: "NICHQ_FTS3",
    name: "Change of appetite—explain below",
    value: 0,
  },
  {
    id: 4,
    questionId: "NICHQ_FTS4",
    name: "Trouble sleeping",
    value: 0,
  },
  {
    id: 5,
    questionId: "NICHQ_FTS5",
    name: "Irritability in the late morning, late afternoon, or evening—explain below",
    value: 0,
  },
  {
    id: 6,
    questionId: "NICHQ_FTS6",
    name: "Socially withdrawn—decreased interaction with others",
    value: 0,
  },
  {
    id: 7,
    questionId: "NICHQ_FTS7",
    name: "Extreme sadness or unusual crying",
    value: 0,
  },
  {
    id: 8,
    questionId: "NICHQ_FTS8",
    name: "Dull, tired, listless behavior",
    value: 0,
  },
  {
    id: 9,
    questionId: "NICHQ_FTS9",
    name: "Tremors/feeling shaky",
    value: 0,
  },
  {
    id: 10,
    questionId: "NICHQ_FTS10",
    name: "Repetitive movements, tics, jerking, twitching, eye blinking—explain below",
    value: 0,
  },
  {
    id: 11,
    questionId: "NICHQ_FTS11",
    name: "Picking at skin or fingers, nail biting, lip or cheek chewing—explain below",
    value: 0,
  },
  {
    id: 12,
    questionId: "NICHQ_FTS12",
    name: "Sees or hears things that aren\'t there",
    value: 0,
  }
]

const NICHQ_SET1_HEADERS = [
  {
    id: 1,
    questionId: 'NICHQ_H1_Q1',
    name: 'Today\'s Date',
    value: '',
    type: 'date'
  },
  {
    id: 2,
    questionId: "NICHQ_H1_Q2",
    name: "Child\'s Name",
    value: '',
    type: 'question'
  },
  {
    id: 3,
    questionId: "NICHQ_H1_Q3",
    name: "Date of Birth",
    value: '',
    type: 'date'
  }, {
    id: 4,
    questionId: "NICHQ_H1_Q4",
    name: "Parent\'s Name",
    value: '',
    type: 'question'
  },
  {
    id: 5,
    questionId: "NICHQ_H1_Q5",
    name: "Parent\'s Phone Number",
    value: '',
    type: 'question'
  },

  {
    id: 6,
    questionId: "NICHQ_H1_Q6",
    name: "Is this evaluation based on a time when the child",
    value: false,
    type: 'radio',
    buttons: [
      { label: "was on medication", value: "was on medication" },
      { label: "was not on medication", value: "was not on medication" },
      { label: "not sure", value: "not sure" },
    ],
  }
]

const NICHQ_SET2_HEADERS = [
  {
    id: 1,
    questionId: "NICHQ_H2_Q1",
    name: "Teacher\'s Name",
    value: '',
    type: 'question'
  },
  {
    id: 2,
    questionId: "NICHQ_H2_Q2",
    name: "Class Time",
    value: '',
    type: 'question'
  },
  {
    id: 3,
    questionId: "NICHQ_H2_Q3",
    name: "Class Name/Period",
    value: '',
    type: 'question'
  }, {
    id: 4,
    questionId: "NICHQ_H2_Q4",
    name: "Today\'s Date",
    value: '',
    type: 'date'
  },
  {
    id: 5,
    questionId: "NICHQ_H2_Q5",
    name: "Child\'s Name",
    value: '',
    type: 'question'
  },
  {
    id: 6,
    questionId: "NICHQ_H2_Q6",
    name: "Grade Level",
    value: '',
    type: 'question'
  },


  {
    id: 7,
    questionId: "NICHQ_H2_Q7",
    name: "Is this evaluation based on a time when the child",
    value: false,
    type: 'radio',
    buttons: [
      { label: "was on medication", value: "was on medication" },
      { label: "was not on medication", value: "was not on medication" },
      { label: "not sure", value: "not sure" },
    ],
  }
]

const NICHQ_SET3_HEADERS = [
  {
    id: 1,
    questionId: "NICHQ_H3_Q1",
    name: "Today\'s Date",
    value: '',
    type: 'date'
  },
  {
    id: 2,
    questionId: "NICHQ_H3_Q2",
    name: "Child\'s Name",
    value: '',
    type: 'question'
  },
  {
    id: 3,
    questionId: "NICHQ_H3_Q3",
    name: "Date of Birth",
    value: '',
    type: 'date'
  }, {
    id: 4,
    questionId: "NICHQ_H3_Q4",
    name: "Parent\'s Name",
    value: '',
    type: 'question'
  },
  {
    id: 5,
    questionId: "NICHQ_H3_Q5",
    name: "Parent\'s Phone Number",
    value: '',
    type: 'question'
  },

  {
    id: 6,
    questionId: "NICHQ_H3_Q6",
    name: "Is this evaluation based on a time when the child",
    value: false,
    type: 'radio',
    buttons: [
      { label: "was on medication", value: "was on medication" },
      { label: "was not on medication", value: "was not on medication" },
      { label: "not sure", value: "not sure" },
    ],
  }
]

const NICHQ_SET4_HEADERS = [
  {
    id: 1,
    questionId: "NICHQ_H4_Q1",
    name: "Teacher\'s Name",
    value: '',
    type: 'question'
  },
  {
    id: 2,
    questionId: "NICHQ_H4_Q2",
    name: "Class Time",
    value: '',
    type: 'question'
  },
  {
    id: 3,
    questionId: "NICHQ_H4_Q3",
    name: "Class Name/Period",
    value: '',
    type: 'question'
  }, {
    id: 4,
    questionId: "NICHQ_H4_Q4",
    name: "Today\'s Date",
    value: '',
    type: 'date'
  },
  {
    id: 5,
    questionId: "NICHQ_H4_Q5",
    name: "Child\'s Name",
    value: '',
    type: 'question'
  },
  {
    id: 6,
    questionId: "NICHQ_H4_Q6",
    name: "Grade Level",
    value: '',
    type: 'question'
  },


  {
    id: 7,
    questionId: "NICHQ_H4_Q7",
    name: "Is this evaluation based on a time when the child",
    value: false,
    type: 'radio',
    buttons: [
      { label: "was on medication", value: "was on medication" },
      { label: "was not on medication", value: "was not on medication" },
      { label: "not sure", value: "not sure" },
    ],
  }
]

const NICHQ_S1_COMMENT = [
  {
    id: 1,
    questionId: "NICHQ_S1_C1",
    name: "",
    value: '',
    type: 'question'
  }]

const NICHQ_S2_COMMENT = [
  {
    id: 1,
    questionId: "NICHQ_S2_C1",
    name: "",
    value: '',
    type: 'question'
  }]

const NICHQ_S3_COMMENT = [
  {
    id: 1,
    questionId: "NICHQ_S3_C1",
    name: "",
    value: '',
    type: 'question'
  }]

const NICHQ_S4_COMMENT = [
  {
    id: 1,
    questionId: "NICHQ_S4_C1",
    name: "",
    value: '',
    type: 'question'
  }]

const Gad7Ad = [
  {
    id: 1,
    questionId: "Gad7Ad_Q1",
    name: "felt moments of sudden terror, fear, or fright",
    value: null,
  },
  {
    id: 2,
    questionId: "Gad7Ad_Q2",
    name: "felt anxious, worried, or nervous",
    value: null,

  },
  {
    id: 3,
    questionId: "Gad7Ad_Q3",
    name: "had thoughts of bad things happening, such as family tragedy, ill health, loss of a job, or accidents",
    value: null,

  },
  {
    id: 4,
    questionId: "Gad7Ad_Q4",
    name: "felt a racing heart, sweaty, trouble breathing, faint, or shaky",
    value: null,

  },
  {
    id: 5,
    questionId: "Gad7Ad_Q5",
    name: "felt tense muscles, felt on edge or restless, or had trouble relaxing or trouble sleeping",
    value: null,

  },
  {
    id: 6,
    questionId: "Gad7Ad_Q6",
    name: "avoided, or did not approach or enter, situations about which I worry",
    value: null,

  },
  {
    id: 7,
    questionId: "Gad7Ad_Q7",
    name: "left situations early or participated only minimally due to worries",
    value: null,

  },
  {
    id: 8,
    questionId: "Gad7Ad_Q8",
    name: "spent lots of time making decisions, putting off making decisions, or preparing for situations, due to worries",
    value: null,

  },
  {
    id: 9,
    questionId: "Gad7Ad_Q9",
    name: "sought reassurance from others due to worries",
    value: null,

  },
  {
    id: 10,
    questionId: "Gad7Ad_Q10",
    name: "needed help to cope with anxiety (e.g., alcohol or medication, superstitious objects, or other people)",
    value: null,

  },
];
const Gad7Ad_HEADERS = [
  {
    id: 1,
    questionId: 'Gad7Ad_H1_Q1',
    name: 'Name:',
    value: '',
    type: 'question'
  },
  {
    id: 2,
    questionId: "Gad7Ad_H1_Q2",
    name: "Age:",
    value: '',
    type: 'question'
  },
  {
    id: 3,
    questionId: "Gad7Ad_H1_Q3",
    name: "Sex:",
    value: false,
    type: "radio",
    buttons: [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
    ],
  },
  {
    id: 4,
    questionId: "Gad7Ad_H1_Q4",
    name: "Date:",
    value: '',
    type: 'date'
  },
];


const BDSS = [
  {
    id: 1,
    questionId: "Bdss_Q1",
    name: " Hostility",
    parentHeader: "Manic/Hypomanic:",
    value: null,
  },
  {
    id: 2,
    questionId: "Bdss_Q2",
    name: " Elevated Mood",
    value: null,

  },
  {
    id: 3,
    questionId: "Bdss_Q3",
    name: " Grandiosity",
    value: null,

  },
  {
    id: 4,
    questionId: "Bdss_Q4",
    name: " Excitement",
    value: null,

  },
  {
    id: 5,
    questionId: "Bdss_Q5",
    name: " Motor Hyperactivity",
    value: null,

  },
  {
    id: 6,
    questionId: "Bdss_Q6",
    name: " Depressed Mood",
    parentHeader: "Major Depressive:",
    value: null,

  },
  {
    id: 7,
    questionId: "Bdss_Q7",
    name: " Anxiety",
    value: null,

  },
  {
    id: 8,
    questionId: "Bdss_Q8",
    name: " Emotional Withdrawal",
    value: null,

  },
  {
    id: 9,
    questionId: "Bdss_Q9",
    name: " Blunted Affect",
    value: null,

  },
  {
    id: 10,
    questionId: "Bdss_Q10",
    name: " Unusual Thought Content",
    parentHeader: "Psychotic:",
    value: null,

  },
];
const BDSS_HEADERS = [
  {
    id: 1,
    questionId: "QidsSr_H1_Q1",
    name: "Visit Date:",
    value: '',
    type: 'date'
  },
  {
    id: 2,
    questionId: 'QidsSr_H1_Q2',
    name: 'Overall Side Effect Severity (from CRF):',
    value: '',
    type: 'question'
  },
];
const BDSS_ADDON = [
  {
    id: 1,
    questionId: "Bdss_Addon_Q1",
    name: "Source of Information (check all applicable)",
    value: false,
    type: "radio",
    buttons: [
      { label: "Patient", value: "1" },
      { label: "Parents/Relatives", value: "2" },
      { label: "Mental Health Professionals", value: "3" },
      { label: "Chart", value: "4" },
    ],
  },
  {
    id: 2,
    questionId: "Bdss_Addon_Q2",
    name: "Explain here if validity of assessment is questionable:",
    value: false,
    type: "radio",
    buttons: [
      { label: "Symptoms possibly drug-induced", value: "1" },
      { label: "Underreported due to lack of rapport", value: "2" },
      { label: "Underreported due to negative symptoms", value: "3" },
      { label: "Patient uncooperative", value: "4" },
      { label: "Difficult to assess due to formal thought disorder", value: "5" },
      { label: "Other", value: "6" },

    ],
  },
  {
    id: 3,
    questionId: "Bdss_Addon_Q3",
    name: "Explain Other:",
    value: '',
    type: "question",
  },
  {
    id: 4,
    questionId: "Bdss_Addon_Q4",
    name: "Confidence in assessment",
    value: false,
    type: "radio",
    buttons: [
      { label: "1=Not at all", value: "1" },
      { label: "5=Very confident", value: "5" },
    ],
  },
];

const PHQ9MFT = [
  {
    id: 1,
    questionId: "Phq9Mft_Q1",
    name: "Feeling down, depressed, irritable, or hopeless?",
    value: null,
  },
  {
    id: 2,
    questionId: "Phq9Mft_Q2",
    name: "Little interest or pleasure in doing things?",
    value: null,
  },
  {
    id: 3,
    questionId: "Phq9Mft_Q3",
    name: "Trouble falling or staying asleep, or sleeping too much?",
    value: null,
  },
  {
    id: 4,
    questionId: "Phq9Mft_Q4",
    name: "Poor appetite, weight loss, or overeating?",
    value: null,
  },
  {
    id: 5,
    questionId: "Phq9Mft_Q5",
    name: "Feeling tired, or having little energy?",
    value: null,
  },
  {
    id: 6,
    questionId: "Phq9Mft_Q6",
    name: "Feeling bad about yourself or that you are a failure or have let yourself or your family down?",
    value: null,
  },
  {
    id: 7,
    questionId: "Phq9Mft_Q7",
    name: "Trouble concentrating on things, such as reading the newspaper or watching TV?",
    value: null,
  },
  {
    id: 8,
    questionId: "Phq9Mft_Q8",
    name: "Moving or speaking so slowly that other people could have noticed? Or the opposite-being so fidgety or restless that you were moving around a lot more than usual?",
    value: null,
  },
  {
    id: 9,
    questionId: "Phq9Mft_Q9",
    name: "Thoughts that you would be better off dead, or of hurting yourself in some way?",
    value: null,
  }


];
const PHQ9MFTAddON = [
  {
    id: 10,
    questionId: "Phq9Mft_Q10",
    name: "In the past year have you felt depressed or sad most days, even if you felt okay sometimes?",
    value: false,
    type: "radio",
    buttons: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
  },
  {
    id: 11,
    questionId: "Phq9Mft_Q11",
    name: "If you are experiencing any of the problems on this form, how difficult have these problems made it for you to do your work, take care of things at home or get along with other people?",
    value: false,
    type: "radio",
    buttons: [
      { label: "Not difficult at all", value: "Not difficult at all" },
      { label: "Somewhat difficult", value: "Somewhat difficult" },
      { label: "Very difficult", value: "Very difficult" },
      { label: "Extremely difficult", value: "Extremely difficult" },
    ],
  },
  {
    id: 12,
    questionId: "Phq9Mft_Q12",
    name: "Has there been a time in the past month when you have had serious thoughts about ending your life?",
    value: false,
    type: "radio",
    buttons: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
  },
  {
    id: 13,
    questionId: "Phq9Mft_Q13",
    name: "Have you EVER, in your WHOLE LIFE, tried to kill yourself or made a suicide attempt?",
    value: false,
    type: "radio",
    buttons: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
  },


];
const PHQ9MFT_HEADERS = [
  {
    id: 1,
    questionId: 'Phq9Mft_H1_Q1',
    name: 'Name:',
    value: '',
    type: 'question'
  },
  {
    id: 2,
    questionId: "Phq9Mft_H1_Q2",
    name: "Clinician:",
    value: '',
    type: 'question'
  },
  {
    id: 4,
    questionId: "Phq9Mft_H1_Q4",
    name: "Date:",
    value: '',
    type: 'date'
  },
];


const SPSRSBNS_PS = [
  {
    id: 1,
    questionId: "Spsrsbns_Ps_Q1",
    name: "Suspiciousness",
    value: null,
  },
  {
    id: 2,
    questionId: "Spsrsbns_Ps_Q2",
    name: "Unusual Thought Content",
    value: null,

  },
  {
    id: 3,
    questionId: "Spsrsbns_Ps_Q3",
    name: "Hallucinations",
    value: null,

  },
  {
    id: 4,
    questionId: "Spsrsbns_Ps_Q4",
    name: "Conceptual Disorganization",
    value: null,

  },
];
const SPSRSBNS_PS_ADDON = [
  {
    id: 1,
    questionId: "Spsrbns_Ps_Q1",
    name: "Source of Information (check all applicable)",
    value: false,
    type: "radio",
    buttons: [
      { label: "Patient", value: "1" },
      { label: "Parents/Relatives", value: "2" },
      { label: "Mental Health Professionals", value: "3" },
      { label: "Chart", value: "4" },
    ],
  },
  {
    id: 2,
    questionId: "Spsrbns_Ps_Q2",
    name: "Explain here if validity of assessment is questionable:",
    value: false,
    type: "radio",
    buttons: [
      { label: "Symptoms possibly drug-induced", value: "1" },
      { label: "Underreported due to lack of rapport", value: "2" },
      { label: "Underreported due to negative symptoms", value: "3" },
      { label: "Patient uncooperative", value: "4" },
      { label: "Difficult to assess due to formal thought disorder", value: "5" },
      { label: "Other", value: "6" },

    ],
  },
  {
    id: 3,
    questionId: "Spsrbns_Ps_Q3",
    name: "Explain:",
    value: '',
    type: "question",
  },
  {
    id: 4,
    questionId: "Spsrbns_Ps_Q4",
    name: "Confidence in assessment",
    value: false,
    type: "radio",
    buttons: [
      { label: "1=Not at all", value: "1" },
      { label: "5=Very confident", value: "5" },
    ],
  },
];

const SPSRSBNS_NS = [
  {
    id: 1,
    questionId: "Spsrsbns_NS_Q1",
    name: "Prolonged Time to Respond",
    value: null,
  },
  {
    id: 2,
    questionId: "Spsrsbns_NS_Q2",
    name: "Emotion (Unchanging facial expression, blank, expressionless face.)",
    value: null,

  },
  {
    id: 3,
    questionId: "Spsrsbns_NS_Q3",
    name: "Reduced Social Drive",
    value: null,

  },
  {
    id: 4,
    questionId: "Spsrsbns_NS_Q4",
    name: "Poor Grooming and Hygiene",
    value: null,

  },
];
const SPSRSBNS_NS_ADDON = [
  {
    id: 1,
    questionId: "Spsrbns_Ns_Q1",
    name: "Source of Information (check all applicable)",
    value: false,
    type: "radio",
    buttons: [
      { label: "Patient", value: "1" },
      { label: "Parents/Relatives", value: "2" },
      { label: "Mental Health Professionals", value: "3" },
      { label: "Chart", value: "4" },
    ],
  },
  {
    id: 2,
    questionId: "Spsrbns_Ns_Q2",
    name: "Explain here if validity of assessment is questionable:",
    value: false,
    type: "radio",
    buttons: [
      { label: "Symptoms possibly drug-induced", value: "1" },
      { label: "Underreported due to lack of rapport", value: "2" },
      { label: "Underreported due to negative symptoms", value: "3" },
      { label: "Patient uncooperative", value: "4" },
      { label: "Difficult to assess due to formal thought disorder", value: "5" },
      { label: "Other", value: "6" },

    ],
  },
  {
    id: 3,
    questionId: "Spsrbns_Ns_Q3",
    name: "Explain:",
    value: '',
    type: "question",
  },
  {
    id: 4,
    questionId: "Spsrbns_Ns_Q4",
    name: "Confidence in assessment",
    value: false,
    type: "radio",
    buttons: [
      { label: "1=Not at all", value: "1" },
      { label: "5=Very confident", value: "5" },
    ],
  },
];

const QIDS_A17 = [
  {
    id: 1,
    questionId: "QidsA17_Q1",
    name: "1. Sleep Onset Insomnia:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= You always fall asleep in less than 30 minutes.", value: "0" },
      { label: "1= You take at least 30 minutes to fall asleep, less than half the time.", value: "1" },
      { label: "2= You take at least 30 minutes to fall asleep, more than half the time.", value: "2" },
      { label: "3= You take more than 60 minutes to fall asleep, more than half the time.", value: "3" },
    ],
  },
  {
    id: 2,
    questionId: "QidsA17_Q2",
    name: "2. Mid-Nocturnal Insomnia:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= You do not wake up at night.", value: "0" },
      { label: "1= You toss and turn a lot on some nights.", value: "1" },
      { label: "2= You wake up at least once in the middle of the night, but go back to sleep easily.", value: "2" },
      { label: "3= You wake up many times in the middle of the night and usually stay awake for 20 minutes or more on most nights.", value: "3" },
    ],
  },
  {
    id: 3,
    questionId: "QidsA17_Q3",
    name: "3. Early Morning Insomnia:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Most of the time, you wake up no more than 30 minutes before you need to get up.", value: "0" },
      { label: "1= More than half the time, you wake up more than 30 minutes before you need to get up.", value: "1" },
      { label: "2= You almost always wake up at least one hour or so before you need or want to, but you go back to sleep eventually.", value: "2" },
      { label: "3= You wake up at least one hour before you need or want to, and can't go back to sleep.", value: "3" },
    ],
  },
  {
    id: 4,
    questionId: "QidsA17_Q4",
    name: "4. Hypersomnia:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= You sleep no longer than 7-8 hours each night, without napping during the day.", value: "0" },
      { label: "1= You sleep no longer than 10 out of 24 hours a day including naps.", value: "1" },
      { label: "2= You sleep no longer than 12 out of 24 hours a day including naps.", value: "2" },
      { label: "3= You sleep longer than 12 out of 24 hours a day including naps.", value: "3" },
    ],
  },
  {
    id: 5,
    questionId: "QidsA17_Q5",
    name: "5. Mood (Sad):",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= You do not feel down, unhappy, sad, or miserable.", value: "0" },
      { label: "1= You feel down, unhappy, sad, or miserable less than half the time.", value: "1" },
      { label: "2= You feel down, unhappy, sad, or miserable more than half the time.", value: "2" },
      { label: "3= You feel really down, unhappy, sad, or miserable pretty much all of the time.", value: "3" },
    ],
  },
  {
    id: 6,
    questionId: "QidsA17_Q6",
    name: "6. Mood (Irritable):",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= You do not feel crabby, grouchy, or cranky.", value: "0" },
      { label: "1= You feel crabby, grouchy, or cranky less than half the time.", value: "1" },
      { label: "2= You feel crabby, grouchy, or cranky more than half the time.", value: "2" },
      { label: "3= You feel crabby, grouchy, or cranky nearly all of the time.", value: "3" },
    ],
  },
  {
    id: 7,
    questionId: "QidsA17_Q7",
    name: "7. Appetite (Decreased):",
    parentHeader: "COMPLETE EITHER 7 OR 8 (NOT BOTH)",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= There is no change from your normal appetite.", value: "0" },
      { label: "1= You eat less often or smaller amounts of food than normal.", value: "1" },
      { label: "2= You eat much less than normal and you have to make yourself eat.", value: "2" },
      { label: "3= You hardly ever eat during a whole day, and then only after you push yourself to eat or because other people make you eat.", value: "3" },
    ],
  },
  {
    id: 8,
    questionId: "QidsA17_Q8",
    name: "8. Appetite (Increased):",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= There is no change from your normal appetite.", value: "0" },
      { label: "1= You feel a need to eat more often than normal.", value: "1" },
      { label: "2= You regularly eat more often and/or larger amounts of food than normal.", value: "2" },
      { label: "3= You feel like you want to eat a lot more than normal during or between meals.", value: "3" },
    ],
  },
  {
    id: 9,
    questionId: "QidsA17_Q9",
    name: "9. Weight (Decrease) Within the Last Two Weeks:",
    parentHeader: "COMPLETE EITHER 9 OR 10 (NOT BOTH)",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Your weight has not changed.", value: "0" },
      { label: "1= You have lost a little weight.", value: "1" },
      { label: "2= You have lost 2 pounds or more in the past 2 weeks. Your clothes are a little more loose than normal.", value: "2" },
      { label: "3= You have lost 5 pounds or more in the past 2 weeks. Your clothes are a lot more loose than normal.", value: "3" },
    ],
  },
  {
    id: 10,
    questionId: "QidsA17_Q10",
    name: "10. Weight (Increase) Within the Last Two Weeks:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Your weight has not changed.", value: "0" },
      { label: "1= You have gained a little weight.", value: "1" },
      { label: "2= You have gained 2 pounds or more in the past 2 weeks. Your clothes are a little tighter than normal.", value: "2" },
      { label: "3= You have gained 5 pounds or more in the past 2 weeks. Your clothes are a lot tighter than normal.", value: "3" },
    ],
  },
  {
    id: 11,
    questionId: "QidsA17_Q11",
    name: "11. Concentration/Decision Making:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= There is no change in your normal ability to pay attention or make up your mind.", value: "0" },
      { label: "1= You have some problems paying attention or making up your mind.", value: "1" },
      { label: "2= Most of the time you have a lot of problems paying attention or making up your mind.", value: "2" },
      { label: "3= Your mind has wandered so much during the past week that you haven't been able to read or follow a TV show or make even little decisions.", value: "3" },
    ],
  },
  {
    id: 12,
    questionId: "QidsA17_Q12",
    name: "12. Outlook (Self):",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= You feel as worthwhile or good about yourself as the people around feel about themselves.", value: "0" },
      { label: "1= You are harder on yourself or more down on yourself than normal.", value: "1" },
      { label: "2= You blame yourself for everything around that goes wrong.", value: "2" },
      { label: "3= You think a lot about your faults, both big and little.", value: "3" },
    ],
  },
  {
    id: 13,
    questionId: "QidsA17_Q13",
    name: "13. Suicidal Ideation:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= You do not think of suicide or your own death.", value: "0" },
      { label: "1= You feel that life is empty or wonder if it's worth living.", value: "1" },
      { label: "2= You think of suicide or your own death several times a week for several minutes.", value: "2" },
      { label: "3= You think of suicide or your own death several times a day, or have made plans or tried to commit suicide.", value: "3" },
    ],
  },
  {
    id: 14,
    questionId: "QidsA17_Q14",
    name: "14. Involvement:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= There is no change from normal in how interested you are in other people or activities.", value: "0" },
      { label: "1= You are less interested in things that used to be fun for you, like meeting with friends, hobbies, or sports.", value: "1" },
      { label: "2= You have interest in only one or two of your usual interests or activities.", value: "2" },
      { label: "3= You have no interest in any of the things that used to be fun.", value: "3" },
    ],
  },
  {
    id: 15,
    questionId: "QidsA17_Q15",
    name: "15. Energy/Fatigability:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= You have as much energy as usual for getting things done.", value: "0" },
      { label: "1= You get tired more easily than normal.", value: "1" },
      { label: "2= You have to push yourself more than usual, or it takes more effort than usual to start and finish your normal activities.", value: "2" },
      { label: "3= You are so tired or worn out that you just have not been able to do most of your usual activities.", value: "3" },
    ],
  },
  {
    id: 16,
    questionId: "QidsA17_Q16",
    name: "16. Psychomotor Slowing: (RATING BASED ON OBSERVATION DURING INTERVIEW AND PATIENT SELF-REPORT.)",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= You think, speak, and move at your normal pace.", value: "0" },
      { label: "1= Your thinking is slowed down or your voice sounds dull and flat.", value: "1" },
      { label: "2= Your thoughts or speech are slowed down so that it sometimes takes you several seconds to answer when someone talks to you.", value: "2" },
      { label: "3= Your thoughts and speech are so slow at times that you haven't been able to answer without a lot of encouragement from someone.", value: "3" },
    ],
  },
  {
    id: 17,
    questionId: "QidsA17_Q17",
    name: "17. Psychomotor Agitation: (RATING BASED ON OBSERVATION DURING INTERVIEW AND PATIENT SELF-REPORT.)",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= You do not feel squirmy, antsy, or restless.", value: "0" },
      { label: "1= You are a little squirmy, antsy, or restless so that sometimes you can't stay still easily.", value: "1" },
      { label: "2= You are often squirmy, antsy, or restless so that you often can't stay still easily.", value: "2" },
      { label: "3= You are so squirmy, antsy, or restless that you can't sit still at all.", value: "3" },
    ],
  },




];
const QIDS_A17_HEADERS = [
  {
    id: 1,
    questionId: 'QidsA17_H1_Q1',
    name: 'INTERVIEWER:',
    value: '',
    type: 'question'
  },
  {
    id: 2,
    questionId: "QidsA17_H1_Q2",
    name: "SCORE:",
    value: '',
    type: 'question'
  },

];
const QIDS_A17_SCORING = [
  {
    id: 1,
    questionId: 'QidsA17Scoring_H1_Q1',
    name: 'Enter the highest score on any 1 of the 4 sleep items (1 - 4)',
    value: '',
    type: 'question'
  },
  {
    id: 2,
    questionId: 'QidsA17Scoring_H1_Q2',
    name: 'Enter the highest score on either the mood sad or mood irritable item (5 or 6)',
    value: '',
    type: 'question'
  },
  {
    id: 3,
    questionId: 'QidsA17Scoring_H1_Q3',
    name: 'Enter the highest score on any 1 appetite / weight item (7 - 10)',
    value: '',
    type: 'question'
  },
  {
    id: 4,
    questionId: 'QidsA17Scoring_H1_Q4',
    name: 'Item 11',
    value: '',
    type: 'question'
  },
  {
    id: 5,
    questionId: 'QidsA17Scoring_H1_Q5',
    name: 'Item 12',
    value: '',
    type: 'question'
  },
  {
    id: 6,
    questionId: 'QidsA17Scoring_H1_Q6',
    name: 'Item 13',
    value: '',
    type: 'question'
  },
  {
    id: 7,
    questionId: 'QidsA17Scoring_H1_Q7',
    name: 'Item 14',
    value: '',
    type: 'question'
  },
  {
    id: 8,
    questionId: 'QidsA17Scoring_H1_Q8',
    name: 'Item 15',
    value: '',
    type: 'question'
  },
  {
    id: 9,
    questionId: 'QidsA17Scoring_H1_Q9',
    name: 'Enter the highest score on either of the 2 psychomotor items (16 or 17)',
    value: '',
    type: 'question'
  },
  {
    id: 10,
    questionId: 'QidsA17Scoring_H1_Q10',
    name: 'TOTAL SCORE (RANGE 0 - 27)',
    value: '',
    type: 'question'
  },
];


const QIDS_SR = [


  {
    id: 1,
    questionId: "QidsSr_Q1",
    name: "1. Falling Asleep:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= I never take longer than 30 minutes to fall asleep.", value: "0" },
      { label: "1= I take at least 30 minutes to fall asleep, less than half the time.", value: "1" },
      { label: "2= I take at least 30 minutes to fall asleep, more than half the time.", value: "2" },
      { label: "3= I take more than 60 minutes to fall asleep, more than half the time.", value: "3" },
    ],
  },
  {
    id: 2,
    questionId: "QidsSr_Q2",
    name: "2. Sleep During the Night:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= I do not wake up at night.", value: "0" },
      { label: "1= I have a restless, light sleep with a few brief awakenings each night.", value: "1" },
      { label: "2= I wake up at least once a night, but I go back to sleep easily.", value: "2" },
      { label: "3= I awaken more than once a night and stay awake for 20 minutes or more, more than half the time.", value: "3" },
    ],
  },
  {
    id: 3,
    questionId: "QidsSr_Q3",
    name: "3. Waking Up Too Early:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Most of the time, I awaken no more than 30 minutes before I need to get up.", value: "0" },
      { label: "1= More than half the time, I awaken more than 30 minutes before I need to get up.", value: "1" },
      { label: "2= I almost always awaken at least one hour or so before I need to, but I go back to sleep eventually.", value: "2" },
      { label: "3= I awaken at least one hour before I need to, and can't go back to sleep.", value: "3" },
    ],
  },
  {
    id: 4,
    questionId: "QidsSr_Q4",
    name: "4. Sleeping Too Much:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= I sleep no longer than 7-8 hours/night, without napping during the day.", value: "0" },
      { label: "1= I sleep no longer than 10 hours in a 24-hour period including naps.", value: "1" },
      { label: "2= sleep no longer than 12 hours in a 24-hour period including naps.", value: "2" },
      { label: "3= I sleep longer than 12 hours in a 24-hour period including naps.", value: "3" },
    ],
  },
  {
    id: 5,
    questionId: "QidsSr_Q5",
    name: "5. Feeling Sad:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= I do not feel sad", value: "0" },
      { label: "1= I feel sad less than half the time.", value: "1" },
      { label: "2= I feel sad more than half the time.", value: "2" },
      { label: "3= I feel sad nearly all of the time.", value: "3" },
    ],
  },
  {
    id: 6,
    questionId: "QidsSr_Q6",
    name: "6. Decreased Appetite:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= There is no change in my usual appetite.", value: "0" },
      { label: "1= I eat somewhat less often or lesser amounts of food than usual.", value: "1" },
      { label: "2= I eat much less than usual and only with personal effort.", value: "2" },
      { label: "3= I rarely eat within a 24-hour period, and only with extreme personal effort or when others persuade me to eat. ", value: "3" },
    ],
  },
  {
    id: 7,
    questionId: "QidsSr_Q7",
    name: "7. Increased Appetite:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= There is no change from my usual appetite.", value: "0" },
      { label: "1= I feel a need to eat more frequently than usual.", value: "1" },
      { label: "2= I regularly eat more often and/or greater amounts of food than usual.", value: "2" },
      { label: "3= I feel driven to overeat both at mealtime and between meals.", value: "3" },
    ],
  },
  {
    id: 8,
    questionId: "QidsSr_Q8",
    name: "8. Decreased Weight (Within the Last Two Weeks):",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= I have not had a change in my weight.", value: "0" },
      { label: "1= I feel as if I've had a slight weight loss.", value: "1" },
      { label: "2= I have lost 2 pounds or more.", value: "2" },
      { label: "3= I have lost 5 pounds or more.", value: "3" },
    ],
  },
  {
    id: 9,
    questionId: "QidsSr_Q9",
    name: "9. Increased Weight (Within the Last Two Weeks):",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= I have not had a change in my weight.", value: "0" },
      { label: "1= I feel as if I've had a slight weight gain.", value: "1" },
      { label: "2= I have gained 2 pounds or more.", value: "2" },
      { label: "3= I have gained 5 pounds or more.", value: "3" },
    ],
  },
  {
    id: 10,
    questionId: "QidsSr_Q10",
    name: "10. Concentration/Decision Making:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= There is no change in my usual capacity to concentrate or make decisions.", value: "0" },
      { label: "1= I occasionally feel indecisive or find that my attention wanders.", value: "1" },
      { label: "2= Most of the time, I struggle to focus my attention or to make decisions.", value: "2" },
      { label: "3= I cannot concentrate well enough to read or cannot make even minor decisions.", value: "3" },
    ],
  },
  {
    id: 11,
    questionId: "QidsSr_Q11",
    name: "11. View of Myself:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= I see myself as equally worthwhile and deserving as other people.", value: "0" },
      { label: "1= I am more self-blaming than usual.", value: "1" },
      { label: "2= I largely believe that I cause problems for others.", value: "2" },
      { label: "3= I think almost constantly about major and minor defects in myself.", value: "3" },
    ],
  },
  {
    id: 12,
    questionId: "QidsSr_Q12",
    name: "12. Thoughts of Death or Suicide:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= I do not think of suicide or death.", value: "0" },
      { label: "1= I feel that life is empty or wonder if it's worth living.", value: "1" },
      { label: "2= I think of suicide or death several times a week for several minutes", value: "2" },
      { label: "3= I think of suicide or death several times a day in some detail, or I have made specific plans for suicide or have actually tried totake my life.", value: "3" },
    ],
  },
  {
    id: 13,
    questionId: "QidsSr_Q13",
    name: "13. General Interest:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= There is no change from usual in how interested I am in other people or activities.", value: "0" },
      { label: "1= I notice that I am less interested in people or activities.", value: "1" },
      { label: "2= I find I have interest in only one or two of my formerly pursued activities.", value: "2" },
      { label: "3= I have virtually no interest in formerly pursued activities.", value: "3" },
    ],
  },
  {
    id: 14,
    questionId: "QidsSr_Q14",
    name: "14. Energy Level:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= There is no change in my usual level of energy.", value: "0" },
      { label: "1= I get tired more easily than usual.", value: "1" },
      { label: "2= I have to make a big effort to start or finish my usual daily activities (for example, shopping, homework, cooking or going to work).", value: "2" },
      { label: "3= I really cannot carry out most of my usual daily activities because I just don't havethe energy.", value: "3" },
    ],
  },
  {
    id: 15,
    questionId: "QidsSr_Q15",
    name: "15. Feeling slowed down:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= I think, speak, and move at my usual rate of speed.", value: "0" },
      { label: "1= I find that my thinking is slowed down or my voice sounds dull or flat.", value: "1" },
      { label: "2= It takes me several seconds to respond to most questions and I'm sure my thinking is slowed.", value: "2" },
      { label: "3= I am often unable to respond to questions without extreme effort.", value: "3" },
    ],
  },
  {
    id: 16,
    questionId: "QidsSr_Q16",
    name: "16. Feeling restless:",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= I do not feel restless.", value: "0" },
      { label: "1= I'm often fidgety, wringing my hands, or need to shift how I am sitting.", value: "1" },
      { label: "2= I have impulses to move about and am quite restless.", value: "2" },
      { label: "3= At times, I am unable to stay seated and need to pace around.", value: "3" },
    ],
  },

];
const QIDS_HEADERS = [
  {
    id: 1,
    questionId: 'QidsSr_H1_Q1',
    name: 'Name:',
    value: '',
    type: 'question'
  },
  {
    id: 2,
    questionId: "QidsSr_H1_Q2",
    name: "Today's Date:",
    value: '',
    type: 'date'
  },
];
const QIDS_SR_SCORING = [
  {
    id: 1,
    questionId: 'QidsSrScoring_H1_Q1',
    name: 'Enter the highest score on any 1 of the 4 sleep items (1 - 4)',
    value: '',
    type: 'question'
  },
  {
    id: 2,
    questionId: 'QidsSrScoring_H1_Q2',
    name: 'Item 5',
    value: '',
    type: 'question'
  },
  {
    id: 3,
    questionId: 'QidsSrScoring_H1_Q3',
    name: 'Enter the highest score on any 1 appetite/weight item (6-9)',
    value: '',
    type: 'question'
  },
  {
    id: 4,
    questionId: 'QidsSrScoring_H1_Q4',
    name: 'Item 10',
    value: '',
    type: 'question'
  },
  {
    id: 5,
    questionId: 'QidsSrScoring_H1_Q5',
    name: 'Item 11',
    value: '',
    type: 'question'
  },
  {
    id: 6,
    questionId: 'QidsSrScoring_H1_Q6',
    name: 'Item 12',
    value: '',
    type: 'question'
  },
  {
    id: 7,
    questionId: 'QidsSrScoring_H1_Q7',
    name: 'Item 13',
    value: '',
    type: 'question'
  },
  {
    id: 8,
    questionId: 'QidsSrScoring_H1_Q8',
    name: 'Item 14',
    value: '',
    type: 'question'
  },
  {
    id: 9,
    questionId: 'QidsSrScoring_H1_Q9',
    name: 'Enter the highest score on either of the 2 psychomotor items (15 and 16)',
    value: '',
    type: 'question'
  },
  {
    id: 10,
    questionId: 'QidsSrScoring_H1_Q10',
    name: 'TOTAL SCORE (RANGE 0 - 27)',
    value: '',
    type: 'question'
  },
];


const BSL_23 = [


  {
    id: 1,
    questionId: "Bsl_23_Q1",
    name: "1. It was hard for me to concentrate",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 2,
    questionId: "Bsl_23_Q2",
    name: "2. I felt helpless",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },

  {
    id: 3,
    questionId: "Bsl_23_Q3",
    name: "3. I was absent-minded and unable to remember what I was actually doing",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 4,
    questionId: "Bsl_23_Q4",
    name: "4. I felt disgust",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 5,
    questionId: "Bsl_23_Q5",
    name: "5. I thought of hurting myself",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 6,
    questionId: "Bsl_23_Q6",
    name: "6. didn't trust other people",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 7,
    questionId: "Bsl_23_Q7",
    name: "7. I didn't believe in my right to live",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 8,
    questionId: "Bsl_23_Q8",
    name: "8. I was lonely",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 9,
    questionId: "Bsl_23_Q9",
    name: "9. I experienced stressful inner tension",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 10,
    questionId: "Bsl_23_Q10",
    name: "10. I had images that I was very much afraid of",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 11,
    questionId: "Bsl_23_Q11",
    name: "11. I hated myself",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 12,
    questionId: "Bsl_23_Q12",
    name: "12. I wanted to punish myself",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 13,
    questionId: "Bsl_23_Q13",
    name: "13. I suffered from shame",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 14,
    questionId: "Bsl_23_Q14",
    name: "14. My mood rapidly cycled in terms of anxiety, anger, and depression",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 15,
    questionId: "Bsl_23_Q15",
    name: "15. I suffered from voices and noises from inside or outside my head",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 16,
    questionId: "Bsl_23_Q16",
    name: "16. Criticism had a devastating effect on me",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 17,
    questionId: "Bsl_23_Q17",
    name: "17. I felt vulnerable ",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 18,
    questionId: "Bsl_23_Q18",
    name: "18. The idea of death had a certain fascination for me",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 19,
    questionId: "Bsl_23_Q19",
    name: "19. Everything seemed senseless to me",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 20,
    questionId: "Bsl_23_Q20",
    name: "20. I was afraid of losing control",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 21,
    questionId: "Bsl_23_Q21",
    name: "21. I felt disgusted by myself",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 22,
    questionId: "Bsl_23_Q22",
    name: "22. I felt as if I was far away from myself",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },
  {
    id: 23,
    questionId: "Bsl_23_Q23",
    name: "23. I felt worthless",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= a little", value: "1" },
      { label: "2= rather", value: "2" },
      { label: "3= much", value: "3" },
      { label: "4= very strong", value: "4" },
    ],
  },

];
const BSL_23_Header = [
  {
    id: 1,
    questionId: 'BSL_23_Header_Q1',
    name: 'Code:',
    value: '',
    type: 'question'
  },
  {
    id: 2,
    questionId: "BSL_23_Header_Q2",
    name: "Today's Date:",
    value: '',
    type: 'date'
  },
];
const BSL_23_Supplement = [


  {
    id: 1,
    questionId: "Bsl_23Sup_Q1",
    name: "1. I hurt myself by cutting, burning, strangling, headbanging etc.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= once", value: "1" },
      { label: "2= 2-3 times", value: "2" },
      { label: "3= 4-6 times", value: "3" },
      { label: "4= Daily or more often", value: "4" },
    ],
  },
  {
    id: 2,
    questionId: "Bsl_23Sup_Q2",
    name: "2. I told other people that I was going to kill myself",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= once", value: "1" },
      { label: "2= 2-3 times", value: "2" },
      { label: "3= 4-6 times", value: "3" },
      { label: "4= Daily or more often", value: "4" },
    ],
  },

  {
    id: 3,
    questionId: "Bsl_23Sup_Q3",
    name: "3. I tried to commit suicide",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= once", value: "1" },
      { label: "2= 2-3 times", value: "2" },
      { label: "3= 4-6 times", value: "3" },
      { label: "4= Daily or more often", value: "4" },
    ],
  },
  {
    id: 4,
    questionId: "Bsl_23Sup_Q4",
    name: "4. I had episodes of binge eating",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= once", value: "1" },
      { label: "2= 2-3 times", value: "2" },
      { label: "3= 4-6 times", value: "3" },
      { label: "4= Daily or more often", value: "4" },
    ],
  },
  {
    id: 5,
    questionId: "Bsl_23Sup_Q5",
    name: "5. I induced vomiting",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= once", value: "1" },
      { label: "2= 2-3 times", value: "2" },
      { label: "3= 4-6 times", value: "3" },
      { label: "4= Daily or more often", value: "4" },
    ],
  },
  {
    id: 6,
    questionId: "Bsl_23Sup_Q6",
    name: "6. I displayed high-risk behavior by knowingly driving too fast, running around on the roofs of high buildings, balancing on bridges, etc.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= once", value: "1" },
      { label: "2= 2-3 times", value: "2" },
      { label: "3= 4-6 times", value: "3" },
      { label: "4= Daily or more often", value: "4" },
    ],
  },
  {
    id: 7,
    questionId: "Bsl_23Sup_Q7",
    name: "7. I got drunk",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= once", value: "1" },
      { label: "2= 2-3 times", value: "2" },
      { label: "3= 4-6 times", value: "3" },
      { label: "4= Daily or more often", value: "4" },
    ],
  },
  {
    id: 8,
    questionId: "Bsl_23Sup_Q8",
    name: "8. I took drugs",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= once", value: "1" },
      { label: "2= 2-3 times", value: "2" },
      { label: "3= 4-6 times", value: "3" },
      { label: "4= Daily or more often", value: "4" },
    ],
  },
  {
    id: 9,
    questionId: "Bsl_23Sup_Q9",
    name: "9. I took medication that had not been prescribed or if had been prescribed, I took more than the prescribed dose",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= once", value: "1" },
      { label: "2= 2-3 times", value: "2" },
      { label: "3= 4-6 times", value: "3" },
      { label: "4= Daily or more often", value: "4" },
    ],
  },
  {
    id: 10,
    questionId: "Bsl_23Sup_Q10",
    name: "10. I had outbreaks of uncontrolled anger or physically attacked others",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= once", value: "1" },
      { label: "2= 2-3 times", value: "2" },
      { label: "3= 4-6 times", value: "3" },
      { label: "4= Daily or more often", value: "4" },
    ],
  },
  {
    id: 11,
    questionId: "Bsl_23Sup_Q11",
    name: "11. I had uncontrollable sexual encounters of which I was later ashamed or which made me angry.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= not at all", value: "0" },
      { label: "1= once", value: "1" },
      { label: "2= 2-3 times", value: "2" },
      { label: "3= 4-6 times", value: "3" },
      { label: "4= Daily or more often", value: "4" },
    ],
  },

]; 
const BSL_23_PersonalState = [


  {
    id: 1,
    questionId: "Bsl_23PersonalState_Q1",
    name: " ",
    value: false,
    type: "radio",
    buttons: [
      { label: "0%", value: "0" },
      { label: "10%", value: "10" },
      { label: "20%", value: "20" },
      { label: "30%", value: "30" },
      { label: "40%", value: "40" },
      { label: "50%", value: "50" },
      { label: "60%", value: "60" },
      { label: "70%", value: "70" },
      { label: "80%", value: "80" },
      { label: "90%", value: "90" },
      { label: "100%", value: "100" }, 
    ],
  },  

];


const WCC = [
  

  {
    id: 1,
    questionId: "WCC_Q1",
    name: "1. Just concentrated on what I had to do next – the next step.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 2,
    questionId: "WCC_Q2",
    name: "2. I tried to analyze the problem in order to understand it better.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },

  {
    id: 3,
    questionId: "WCC_Q3",
    name: "3. Turned to work or substitute activity to take my mind off things.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 4,
    questionId: "WCC_Q4",
    name: "4. I felt that time would make a difference – the only thing to do was to wait.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 5,
    questionId: "WCC_Q5",
    name: "5. Bargained or compromised to get something positive from the situation.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 6,
    questionId: "WCC_Q6",
    name: "6. I did something which I didn't think would work, but at least I was doing something.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 7,
    questionId: "WCC_Q7",
    name: "7. Tried to get the person responsible to change his or her mind.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 8,
    questionId: "WCC_Q8",
    name: "8. Talked to someone to find out more about the situation.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 9,
    questionId: "WCC_Q9",
    name: "9. Criticized or lectured myself.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 10,
    questionId: "WCC_Q10",
    name: "10. Tried not to burn my bridges, but leave things open somewhat.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 11,
    questionId: "WCC_Q11",
    name: "11. Hoped a miracle would happen.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 12,
    questionId: "WCC_Q12",
    name: "12. Went along with fate; sometimes I just have bad luck.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 13,
    questionId: "WCC_Q13",
    name: "13. Went on as if nothing had happened.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 14,
    questionId: "WCC_Q14",
    name: "14. I tried to keep my feelings to myself.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 15,
    questionId: "WCC_Q15",
    name: "15. Looked for the silver lining, so to speak; tried to look on the bright side of things.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 16,
    questionId: "WCC_Q16",
    name: "16. Slept more than usual.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 17,
    questionId: "WCC_Q17",
    name: "17. I expressed anger to the person(s) who caused the problem.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 18,
    questionId: "WCC_Q18",
    name: "18. Accepted sympathy and understanding from someone.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 19,
    questionId: "WCC_Q19",
    name: "19. I told myself things that helped me to feel better.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 20,
    questionId: "WCC_Q20",
    name: "20. I was inspired to do something creative.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 21,
    questionId: "WCC_Q21",
    name: "21. Tried to forget the whole thing.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 22,
    questionId: "WCC_Q22",
    name: "22. I got professional help.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 23,
    questionId: "WCC_Q23",
    name: "23. Changed or grew as a person in a good way.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },

  
  {
    id: 24,
    questionId: "WCC_Q24",
    name: "24. I waited to see what would happen before doing anything.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 25,
    questionId: "WCC_Q25",
    name: "25. I apologized or did something to make up.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 26,
    questionId: "WCC_Q26",
    name: "26. I made a plan of action and followed it.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 27,
    questionId: "WCC_Q27",
    name: "27. I accepted the next best thing to what I wanted.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 28,
    questionId: "WCC_Q28",
    name: "28. I let my feelings out somehow.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 29,
    questionId: "WCC_Q29",
    name: "29. Realized I brought the problem on myself.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },

  {
    id: 30,
    questionId: "WCC_Q30",
    name: "30. I came out of the experience better than when I went in.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },

  {
    id: 31,
    questionId: "WCC_Q31",
    name: "31. Talked to someone who could do something concrete about the problem.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 32,
    questionId: "WCC_Q32",
    name: "32. Got away from it for a while; tried to rest or take a vacation.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 33,
    questionId: "WCC_Q33",
    name: "33. Tried to make myself feel better by eating, drinking, smoking, using drugs or medication, etc.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 34,
    questionId: "WCC_Q34",
    name: "34. Took a big chance or did something very risky.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 35,
    questionId: "WCC_Q35",
    name: "35. I tried not to act too hastily or follow my first hunch.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 36,
    questionId: "WCC_Q36",
    name: "36. Found new faith.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 37,
    questionId: "WCC_Q37",
    name: "37. Maintained my pride and kept a stiff upper lip.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 38,
    questionId: "WCC_Q38",
    name: "38. Rediscovered what is important in life.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 39,
    questionId: "WCC_Q39",
    name: "39. Changed something so things would turn out all right.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 40,
    questionId: "WCC_Q40",
    name: "40. Avoided being with people in general.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  
  {
    id: 41,
    questionId: "WCC_Q41",
    name: "41. Didn't let it get to me; refused to think too much about it.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 42,
    questionId: "WCC_Q42",
    name: "42. I asked a relative or friend I respected for advice.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 43,
    questionId: "WCC_Q43",
    name: "43. Kept others from knowing how bad things were.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 44,
    questionId: "WCC_Q44",
    name: "44. Made light of the situation; refused to get too serious about it.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 45,
    questionId: "WCC_Q45",
    name: "45. Talked to someone about how I was feeling.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 46,
    questionId: "WCC_Q46",
    name: "46. Stood my ground and fought for what I wanted.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 47,
    questionId: "WCC_Q47",
    name: "47. Took it out on other people.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 48,
    questionId: "WCC_Q48",
    name: "48. Drew on my past experiences; I was in a similar situation before.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 49,
    questionId: "WCC_Q49",
    name: "49. I knew what had to be done, so I doubled my efforts to make things work.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 50,
    questionId: "WCC_Q50",
    name: "50. Refused to believe that it had happened.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  
  {
    id: 51,
    questionId: "WCC_Q51",
    name: "51. I made a promise to myself that things would be different next time.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 52,
    questionId: "WCC_Q52",
    name: "52. Came up with a couple of different solutions to the problem.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 53,
    questionId: "WCC_Q53",
    name: "53. Accepted it, since nothing could be done.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 54,
    questionId: "WCC_Q54",
    name: "54. I tried to keep my feelings from interfering with other things too much.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 55,
    questionId: "WCC_Q55",
    name: "55. Wished that I could change what had happened or how I felt.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 56,
    questionId: "WCC_Q56",
    name: "56. I changed something about myself.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 57,
    questionId: "WCC_Q57",
    name: "57. I daydreamed or imagined a better time or place than the one I was in.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 58,
    questionId: "WCC_Q58",
    name: "58. Wished that the situation would go away or somehow be over with.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 59,
    questionId: "WCC_Q59",
    name: "59. Had fantasies or wishes about how things might turn out.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 60,
    questionId: "WCC_Q60",
    name: "60. I prayed.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  
  {
    id: 61,
    questionId: "WCC_Q61",
    name: "61. I prepared myself for the worst.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 62,
    questionId: "WCC_Q62",
    name: "62. I went over in my mind what I would say or do.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 63,
    questionId: "WCC_Q63",
    name: "63. I thought about how a person I admire would handle this situation and used that as a model.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 64,
    questionId: "WCC_Q64",
    name: "64. I tried to see things from the other person's point of view.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 65,
    questionId: "WCC_Q65",
    name: "65. I reminded myself how much worse things could be.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  },
  {
    id: 66,
    questionId: "WCC_Q66",
    name: "66. I jogged or exercised.",
    value: false,
    type: "radio",
    buttons: [
      { label: "0= Not Used", value: "0" },
      { label: "1= Used Somewhat", value: "1" },
      { label: "2= Used Quite A Bit", value: "2" },
      { label: "3= Used A great deal", value: "3" },
    ],
  }, 

]; 

const DERS = [ 
  

  {
    id: 1,
    questionId: "Ders_Q1",
    name: "1. I am clear about my feeling",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 2,
    questionId: "Ders_Q2",
    name: "2. I pay attention to how I feel",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },

  {
    id: 3,
    questionId: "Ders_Q3",
    name: "3. I experience my emotions as overwhelming and out of control",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 4,
    questionId: "Ders_Q4",
    name: "4. have no idea how I am feeling",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 5,
    questionId: "Ders_Q5",
    name: "5. I have difficulty making sense out of my feelings",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 6,
    questionId: "Ders_Q6",
    name: "6. I am attentive to my feelings",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 7,
    questionId: "Ders_Q7",
    name: "7. I know exactly how I am feeling",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 8,
    questionId: "Ders_Q8",
    name: "8. I care about what I am feeling",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 9,
    questionId: "Ders_Q9",
    name: "9. I am confused about how I feel",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 10,
    questionId: "Ders_Q10",
    name: "10. When I'm upset, I acknowledge my emotions",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 11,
    questionId: "Ders_Q11",
    name: "11. When I'm upset, I become angry with myself for feeling that way",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 12,
    questionId: "Ders_Q12",
    name: "12. When I'm upset, I become embarrassed for feeling that way",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 13,
    questionId: "Ders_Q13",
    name: "13. When I'm upset, I have difficulty getting work done",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 14,
    questionId: "Ders_Q14",
    name: "14. When I'm upset, I become out of control",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 15,
    questionId: "Ders_Q15",
    name: "15. When I'm upset, I believe that I will remain that way for a long time",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 16,
    questionId: "Ders_Q16",
    name: "16. When I'm upset, I believe that I'll end up feeling very depressed",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 17,
    questionId: "Ders_Q17",
    name: "17. When I'm upset, I believe that my feelings are valid and important",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 18,
    questionId: "Ders_Q18",
    name: "18. When I'm upset, I have difficulty focusing on other things",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 19,
    questionId: "Ders_Q19",
    name: "19. When I'm upset, I feel out of control",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 20,
    questionId: "Ders_Q20",
    name: "20. When I'm upset, I can still get things done",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 21,
    questionId: "Ders_Q21",
    name: "21. When I'm upset, I feel ashamed with myself for feeling that way",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 22,
    questionId: "Ders_Q22",
    name: "22. When I'm upset, I know that I can find a way to eventually feel better",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 23,
    questionId: "Ders_Q23",
    name: "23. When I'm upset, I feel like I am weak",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  
  {
    id: 24,
    questionId: "Ders_Q24",
    name: "24. When I'm upset, I feel like I can remain in control of my behaviours",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 25,
    questionId: "Ders_Q25",
    name: "25. When I'm upset, I feel guilty for feeling that way",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 26,
    questionId: "Ders_Q26",
    name: "26. When I'm upset, I have difficulty concentrating",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 27,
    questionId: "Ders_Q27",
    name: "27. When I'm upset, I have difficulty controlling my behaviours",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 28,
    questionId: "Ders_Q28",
    name: "28. When I'm upset, I believe that there is nothing I can do to make myself feel better",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 29,
    questionId: "Ders_Q29",
    name: "29. When I'm upset, I become irritated with myself for feeling that way",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 30,
    questionId: "Ders_Q30",
    name: "30. When I'm upset, I start to feel very bad about myself",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 31,
    questionId: "Ders_Q31",
    name: "31. When I'm upset, I believe that wallowing in it is all I can do",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 32,
    questionId: "Ders_Q32",
    name: "32. When I'm upset, I lose control over my behaviours",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 33,
    questionId: "Ders_Q33",
    name: "33. When I'm upset, I have difficulty thinking about anything else",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 34,
    questionId: "Ders_Q34",
    name: "34. When I'm upset I take time to figure out what I'm really feeling.",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 35,
    questionId: "Ders_Q35",
    name: "35. When I'm upset, it takes me a long time to feel better",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },
  {
    id: 36,
    questionId: "Ders_Q36",
    name: "36. When I'm upset, my emotions feel overwhelming",
    value: false,
    type: "radio",
     buttons: [
      { label: "1= Almost Never", value: "1" },
      { label: "2= Sometimes", value: "2" },
      { label: "3= About half the time", value: "3" },
      { label: "4= Most of the time", value: "4" },
      { label: "5= Almost always", value: "5" },
    ],
  },

];



export {
  GAD,
  Connor,
  PHQ,
  PrimeScreenQuestionName,
  ACE,
  CATS,
  RecentValues,
  Interference,
  MoodDisorder,
  NICHQ,
  NICHQ_PARENT_PERFORMANCE,
  NICHQ_TEACHER_ASSESSMENT,
  NICHQ_TEACHER_PERFORMANCE,
  NICHQ_TEACHER_BEH_PERFORMANCE,
  NICHQ_FOLLOWUP_PARENT,
  NICHQ_FOLLOWUP_PARENT_PERFORMANCE,
  NICHQ_FOLLOWUP_PARENT_SIDE_EFFECT,
  NICHQ_FOLLOWUP_TEACHER,
  NICHQ_FOLLOWUP_TEACHER_PERFORMANCE,
  NICHQ_FOLLOWUP_TEACHER_SIDE_EFFECT,
  NICHQ_SET1_HEADERS,
  NICHQ_SET2_HEADERS,
  NICHQ_SET3_HEADERS,
  NICHQ_SET4_HEADERS,
  NICHQ_S1_COMMENT,
  NICHQ_S2_COMMENT,
  NICHQ_S3_COMMENT,
  NICHQ_S4_COMMENT,

  Gad7Ad,
  Gad7Ad_HEADERS,

  BDSS,
  BDSS_HEADERS,
  BDSS_ADDON,

  SPSRSBNS_PS,
  SPSRSBNS_PS_ADDON,
  SPSRSBNS_NS,
  SPSRSBNS_NS_ADDON,

  PHQ9MFT,
  PHQ9MFTAddON,
  PHQ9MFT_HEADERS,

  QIDS_A17,
  QIDS_A17_SCORING,
  QIDS_A17_HEADERS,


  QIDS_SR,
  QIDS_SR_SCORING,
  QIDS_HEADERS,

  BSL_23,
  BSL_23_Header,
  BSL_23_Supplement,
  BSL_23_PersonalState,

  WCC,
  DERS,


};
