import { API_ENDPOINTS } from "./api-endpoints";
import { customAxios } from "./useExPressApi";

export const clinicReminderServices = {
    getReminderSettings: () => {
        return customAxios.get<any, any>(`${API_ENDPOINTS.GET_CLINIC_REMINDER_SETTINGS}`);
    },
    insertSettings: (data) => {
        return customAxios.post<any, any>(`${API_ENDPOINTS.INSERT_REMINDER_SETTINGS}`,data);
    },

    insertEmailSettings:(data) => {
        return customAxios.post<any, any>(`${API_ENDPOINTS.INSERT_EMAIL_REMINDER_SETTINGS}`,data);
    },

    insertTextSettings:(data) => {
        return customAxios.post<any, any>(`${API_ENDPOINTS.INSERT_TEXT_REMINDER_SETTINGS}`,data);
    },
    
    insertPhoneSettings:(data) => {
        return customAxios.post<any, any>(`${API_ENDPOINTS.INSERT_PHONE_REMINDER_SETTINGS}`,data);
    },

    getAvailablePhoneNumber:(data) => {
        return customAxios.get<any, any>(`${API_ENDPOINTS.GET_REMINDER_AVAILABLE_NUMBER}${data}`);
    },

    getAuditLog:(data) => {
        return customAxios.get<any, any>(`${API_ENDPOINTS.GET_AUDIT_LOGS}${data}`);
    },
}