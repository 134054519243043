import { SAVE_SERVICE_RULE_DATA_LOADING, SAVE_SERVICE_RULE_LIST } from "../actions/types";




const initialState = {
    serviceRulesList: [],
    isDataLoading:false
  };


export const ServiceRuleReducer = (state = initialState, action: any) => {
    const oldState = { ...state };
    const { type, payload } = action;
  
    switch (type) {
      case SAVE_SERVICE_RULE_LIST:
        return {
          ...oldState,
          serviceRulesList: payload,
        };

        case SAVE_SERVICE_RULE_DATA_LOADING:
            return {
              ...oldState,
              isDataLoading: payload,
            };
    }
    return oldState;
  };